import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import { Box } from '@mui/system'

interface DialogProps { handleClose: () => void, open: boolean, title: string, width?: string, buttonCaption?: string, children: React.ReactNode }

function SimpleCloseDialog({ children, handleClose, open, title, width, buttonCaption }: DialogProps) {

  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      maxWidth="xl"
    >
      <DialogTitle id="responsive-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent>
        {children}
        {width && <Box sx={{ width: width }}></Box>}
      </DialogContent>
      {buttonCaption &&
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            {buttonCaption}
          </Button>
        </DialogActions>
      }
    </Dialog>
  )
}

export default SimpleCloseDialog