import { useEffect, useState } from "react"
import { useTheme } from '@mui/material/styles'
import { Rnd, RndResizeCallback, RndDragCallback, RndResizeStartCallback } from "react-rnd"
import { ResizeDirection } from "re-resizable"
import { DraggableData, DraggableEvent } from "react-draggable"
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Box from '@mui/material/Box'
import { hexToRgba } from '../../lib/colors'
import { dragDistance } from '../../lib/sizes'
import ElementSettingPanel from '../elements/ElementSettingsPanel'
import Fade from '@mui/material/Fade'
import { sleep } from "../../lib/misc"
import { noDragInitClassName } from "../../lib/constants"
import _ from "lodash"

interface FullRelRndProps {
  el: LogicalElement
  elementSelected: boolean
  elementActive: boolean
  children: React.ReactNode
  staticElement?: boolean
  disableResize?: boolean
  elementSpecificPanel?: React.ReactNode
  showElementPanel?: boolean
  relRndProps: BasicRelRndProps
}

function RelRnd({ children, el, elementSelected, elementActive, elementSpecificPanel, showElementPanel = true,
  staticElement, disableResize, relRndProps }: Readonly<FullRelRndProps>) {
  const theme = useTheme()

  //local state
  const [mouseDownDraggablePosition, setMouseDownDraggablePosition] = useState({ x: 0, y: 0 }) //the current position of the draggable element
  const [draggingOrResizing, setDraggingOrResizing] = useState(false)
  const [wasActiveOnRndStart, setWasActiveOnRndStart] = useState(false)
  const [localPosition, setLocalPosition] = useState({
    width: relRndProps.parentSize.width * el.box.width,
    height: relRndProps.parentSize.height * el.box.height,
    top: relRndProps.parentSize.height * el.box.top,
    left: relRndProps.parentSize.width * el.box.left
  })


  // onMount hook 
  useEffect(() => {
    //initialize local position for backdrop effects box
    setLocalPosition({
      width: relRndProps.parentSize.width * el.box.width,
      height: relRndProps.parentSize.height * el.box.height,
      top: relRndProps.parentSize.height * el.box.top,
      left: relRndProps.parentSize.width * el.box.left
    })
  }, [el.box, relRndProps.parentSize])


  /**
   * Mouse handlers
   */

  // handle mouse in and out
  const handleMouseMovement = (mouseOnTarget: boolean) => {
    if (mouseOnTarget) {
      relRndProps.handleDisplayControlChange(el.elementId, "ADD", "SELECTION")
    } else {
      relRndProps.handleDisplayControlChange(el.elementId, "REMOVE", "SELECTION")
    }
  }

  // handle click activation  --- for static RelRnds without drag ----
  const handleMouseDown = (e: MouseEvent) => {
    setMouseDownDraggablePosition({ x: e.clientX, y: e.clientY })
  }
  const handleMouseUp = (e: MouseEvent) => {
    // if click and not drag
    if (!dragDistance(e.clientX, e.clientY, mouseDownDraggablePosition.x, mouseDownDraggablePosition.y)) {
      relRndProps.handleDisplayControlChange(el.elementId, "ADD", "ACTIVATION")
    }
  }

  // handle click away
  const handleClickAway = (e: MouseEvent | TouchEvent) => {
    relRndProps.handleDisplayControlChange(el.elementId, "REMOVE", "ACTIVATION")
    relRndProps.handleDisplayControlChange(el.elementId, "REMOVE", "SELECTION")
  }

  /**
   * Drag & Resize handlers
   */

  const handleDragStart: RndDragCallback = (e: DraggableEvent, data: DraggableData) => {
    // remember starting position of dragged element
    setMouseDownDraggablePosition({ x: data.x, y: data.y })
    // set selected state
    relRndProps.handleDisplayControlChange(el.elementId, "ADD", "SELECTION")
  }

  const handleResizeStart: RndResizeStartCallback = (e: React.MouseEvent<HTMLElement> | React.TouchEvent<HTMLElement>, dir: ResizeDirection, elementRef: HTMLElement) => {
    // set selected and resizing state
    relRndProps.handleDisplayControlChange(el.elementId, "ADD", "SELECTION")
    if (!draggingOrResizing) setWasActiveOnRndStart(elementActive)
    setDraggingOrResizing(true)
  }


  const handleDrag = (e: DraggableEvent, d: DraggableData) => {
    // if movement was far enough, set dragging state
    if (dragDistance(d.x, d.y, mouseDownDraggablePosition.x, mouseDownDraggablePosition.y)) {
      if (!draggingOrResizing) setWasActiveOnRndStart(elementActive)
      setDraggingOrResizing(true)
      relRndProps.handleDisplayControlChange(el.elementId, "REMOVE", "ACTIVATION")
    }
    // set new local dimensions on drag
    setLocalPosition({
      left: d.x,
      top: d.y,
      width: el.box.width,
      height: el.box.height
    })
  }

  const handleResize: RndResizeCallback = (e, direction, ref, delta, position) => {
    // set local dimensions on resize
    setLocalPosition({
      left: position.x,
      top: position.y,
      width: parseFloat(ref.style.width),
      height: parseFloat(ref.style.height)
    })
    // remove activation on resize
    relRndProps.handleDisplayControlChange(el.elementId, "REMOVE", "ACTIVATION")
  }

  const handleDragStop = async (e: DraggableEvent, d: DraggableData) => {
    // handle the final box position change
    const newElementBox = {
      left: d.x / relRndProps.parentSize.width,
      top: d.y / relRndProps.parentSize.height,
      width: el.box.width,
      height: el.box.height
    }
    if (!_.isEqual(newElementBox, el.box)) {
      relRndProps.handleBoxChange(relRndProps.currentSlideId, el.elementId, newElementBox, "ELEMENT_DRAG")
    }

    // for clicks/tabs on draggable RelRnds
    if (!dragDistance(d.x, d.y, mouseDownDraggablePosition.x, mouseDownDraggablePosition.y)) {
      relRndProps.handleDisplayControlChange(el.elementId, "ADD", "ACTIVATION")
    }

    // update local position at the end
    handleDrag(e, d)
    // end dragging state
    setDraggingOrResizing(false)
    if (wasActiveOnRndStart) {
      await sleep(100)
      relRndProps.handleDisplayControlChange(el.elementId, "ADD", "ACTIVATION")
    }
  }

  const handleResizeStop: RndResizeCallback = async (e, direction, ref, delta, position) => {
    // handle the final box size change
    const newElementBox = {
      left: position.x / relRndProps.parentSize.width,
      top: position.y / relRndProps.parentSize.height,
      width: parseFloat(ref.style.width) / relRndProps.parentSize.width,
      height: parseFloat(ref.style.height) / relRndProps.parentSize.height
    }
    if (!_.isEqual(newElementBox, el.box)) {
      relRndProps.handleBoxChange(relRndProps.currentSlideId, el.elementId, newElementBox, "ELEMENT_RESIZE")
    }

    // update local position at the end
    handleResize(e, direction, ref, delta, position)
    // end resize state
    setDraggingOrResizing(false)
    if (wasActiveOnRndStart) {
      await sleep(100)
      relRndProps.handleDisplayControlChange(el.elementId, "ADD", "ACTIVATION")
    }
  }

  return (
    <ClickAwayListener onClickAway={handleClickAway} mouseEvent={"onMouseUp"} touchEvent={"onTouchEnd"}>
      <Box>

        {/* The Box for general drag, resize, select and activation effects - it follows the Rnd via localPositionState */}
        <Fade in={(elementSelected || elementActive || draggingOrResizing)} >
          <Box
            onMouseEnter={() => handleMouseMovement(true)}
            onMouseLeave={() => handleMouseMovement(false)}
            sx={{
              position: "absolute",
              backgroundColor: hexToRgba(theme.palette.background.paper, theme.customStyling.componentActiveOpacity),
              WebkitBackdropFilter: (draggingOrResizing) ? theme.customStyling.blur : "",
              backdropFilter: (draggingOrResizing) ? theme.customStyling.blur : "",
              borderRadius: theme.customStyling.relRndBoarderRadius,
              borderStyle: (draggingOrResizing) ? "solid" : "solid", borderWidth: "1px",
              borderColor: theme.palette.text.disabled, ...localPosition
            }}>
          </Box>
        </Fade>

        {/* The Box holding the top control elements */}
        {showElementPanel &&
          <Box
            onMouseEnter={() => handleMouseMovement(true)}
            onMouseLeave={() => handleMouseMovement(false)}
            sx={{ position: "absolute", ...localPosition }}>
            <Box sx={{ position: "relative", width: 1, height: 1, top: 0, left: 0 }}>
              <ElementSettingPanel elementActive={elementActive} el={el} elementSpecificPanel={elementSpecificPanel} relRndProps={relRndProps} />
            </Box>
          </Box>}

        {el.box &&
          < Rnd
            bounds='#stage'
            size={{
              width: relRndProps.parentSize.width * el.box.width,
              height: relRndProps.parentSize.height * el.box.height
            }
            }
            position={{
              x: relRndProps.parentSize.width * el.box.left,
              y: relRndProps.parentSize.height * el.box.top
            }}

            onMouseDown={(e: MouseEvent) => handleMouseDown(e)}
            onClick={(e: MouseEvent) => handleMouseUp(e)}
            onDragStart={handleDragStart}
            onDrag={handleDrag}
            onDragStop={(e: DraggableEvent, d: DraggableData) => { handleDragStop(e, d) }}
            onResizeStart={handleResizeStart}
            onResize={handleResize}
            onResizeStop={handleResizeStop}
            onMouseEnter={() => handleMouseMovement(true)}
            onMouseLeave={() => handleMouseMovement(false)}
            style={{
              //cursor: "pointer"
            }}
            disableDragging={staticElement}// || elementActive}
            enableResizing={!staticElement && !disableResize}// && !elementActive}
            cancel={"." + noDragInitClassName} // this selector denies dragging and thus allows touch events when components are used within react-rnd 
            className={el.elementType !== 'TITLE' ? 'non_title_element' : 'title_element'}
          >

            {/* The Box holding the elements/children */}
            <Box sx={{ position: "relative", width: 1, height: 1, top: 0, left: 0, display: (elementActive) ? "flex" : "none" }}>
              {/* The actual element */}
              {children}
            </Box>
          </Rnd >}

      </Box>
    </ClickAwayListener >
  )
}

export default RelRnd
