
export const timeline1Presets: Record<string, ElementPreset> = {
    TIMELINE1_5:
    {
        displayName: 'Timeline',
        type: 'TIMELINE1',
        "box": {
            "height": 0.40,
            "left": 0.080,
            "top": 0.28,
            "width": 0.64
        },
        "content": {
            "items": [
                {
                    "richTextBox": {
                        "richText": [
                            {
                                "children": [
                                    {
                                        "bold": true,
                                        "italic": false,
                                        "smallCaps": false,
                                        "text": "January",
                                        "underline": false
                                    }
                                ],
                                "type": "paragraph"
                            },
                            {
                                "children": [
                                    {
                                        "bold": false,
                                        "italic": false,
                                        "smallCaps": false,
                                        "text": "Kick-Off & team onboarding",
                                        "underline": false
                                    }
                                ],
                                "type": "paragraph"
                            }
                        ],
                        "box": {
                            "height": 0.18715376456604513,
                            "left": 0.06144834359410194,
                            "top": 0.08640575706593363,
                            "width": 0.22969151575522587
                        }
                    }
                },
                {
                    "richTextBox": {
                        "richText": [
                            {
                                "children": [
                                    {
                                        "bold": true,
                                        "italic": false,
                                        "smallCaps": false,
                                        "text": "March",
                                        "underline": false
                                    }
                                ],
                                "type": "paragraph"
                            },
                            {
                                "children": [
                                    {
                                        "bold": false,
                                        "italic": false,
                                        "smallCaps": false,
                                        "text": "Start of development",
                                        "underline": false
                                    }
                                ],
                                "type": "paragraph"
                            }
                        ],
                        "box": {
                            "height": 0.18715376456604513,
                            "left": 0.32539381585864835,
                            "top": 0.08105181242999347,
                            "width": 0.22545445733027078
                        }
                    }
                },
                {
                    "richTextBox": {
                        "richText": [
                            {
                                "children": [
                                    {
                                        "bold": true,
                                        "italic": false,
                                        "smallCaps": false,
                                        "text": "February",
                                        "underline": false
                                    }
                                ],
                                "type": "paragraph"
                            },
                            {
                                "children": [
                                    {
                                        "bold": false,
                                        "italic": false,
                                        "smallCaps": false,
                                        "text": "Prestudy & tender",
                                        "underline": false
                                    }
                                ],
                                "type": "paragraph"
                            }
                        ],
                        "box": {
                            "height": 0.18715376456604513,
                            "left": 0.12684881894815553,
                            "top": 0.7658399782313207,
                            "width": 0.19327665524672002
                        }
                    }
                },
                {
                    "richTextBox": {
                        "richText": [
                            {
                                "children": [
                                    {
                                        "bold": true,
                                        "italic": false,
                                        "smallCaps": false,
                                        "text": "December",
                                        "underline": false
                                    }
                                ],
                                "type": "paragraph"
                            },
                            {
                                "children": [
                                    {
                                        "bold": false,
                                        "italic": false,
                                        "smallCaps": false,
                                        "text": "Go-live & hypercare",
                                        "underline": false
                                    }
                                ],
                                "type": "paragraph"
                            }
                        ],
                        "box": {
                            "height": 0.18715376456604513,
                            "left": 0.7208484931175642,
                            "top": 0.08938537736794175,
                            "width": 0.2315151024440236
                        }
                    }
                },
                {
                    "richTextBox": {
                        "richText": [
                            {
                                "children": [
                                    {
                                        "bold": true,
                                        "italic": false,
                                        "smallCaps": false,
                                        "text": "August",
                                        "underline": false
                                    }
                                ],
                                "type": "paragraph"
                            },
                            {
                                "children": [
                                    {
                                        "bold": false,
                                        "italic": false,
                                        "smallCaps": false,
                                        "text": "Market alignment & advertisement start",
                                        "underline": false
                                    }
                                ],
                                "type": "paragraph"
                            }
                        ],
                        "box": {
                            "height": 0.18715376456604513,
                            "left": 0.6287272477162792,
                            "top": 0.7387335127138007,
                            "width": 0.2618179077669692
                        }
                    }
                }
            ],
            "itemType": "LETTERED_UPPER"
        }
    },
}