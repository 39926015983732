export const securePagesPath = 'secure'
export const editorPath = 'editor'
export const profilePath = 'profile'
export const paymentPath = 'payment'

export const framePaddingNarrow = 0.02
export const framePaddingMedium = 0.05
export const framePaddingWide = 0.2

export const globalHotKeyScope = "global"
export const noDragInitClassName = "noDragInit"
export const noDragInitInnerTextBoxClassName = "noDragInitInnerTextBox"

export const promptingEnabledForAll = true