import { Box } from '@mui/material'
import { useState } from "react"
import InElementBox from './InElementBox'
import NoDragPopover from './NoDragPopover'
import { tableCellDefaultIconSettings } from '../pptElements/ElementDefinitions'
import IconSettings from '../elements/IconSettings'

type IconBoxWithSettingsProps = {
    el: LogicalElement,
    relRndProps: BasicRelRndProps,
    cell: IconGridCell,
    rIdx: number,
    cIdx: number,
    handleSingleIconStateChange: (newIconSettings: TableCellIconSettings, idx: { rIdx: number, cIdx: number }) => void
}

function IconBoxWithSettings({ el, relRndProps, cell, rIdx, cIdx, handleSingleIconStateChange }: Readonly<IconBoxWithSettingsProps>) {
    const [singleIconSettingsPopoverAnchorEl, setSingleIconSettingsPopoverAnchorEl] = useState<EventTarget & HTMLElement | null>(null)

    return (
        <>
            {/* the clickable icon box to open the single icon search settings */}
            <InElementBox el={el} relRndProps={relRndProps} box={cell.iconSettings.box}>
                <Box sx={{ width: 1, height: 1, cursor: 'pointer' }} onClick={(event) => { setSingleIconSettingsPopoverAnchorEl(event.currentTarget) }}></Box>
            </InElementBox>

            {/* The popover with the single icon settings */}
            <NoDragPopover open={!!singleIconSettingsPopoverAnchorEl} anchorEl={singleIconSettingsPopoverAnchorEl} onClose={() => setSingleIconSettingsPopoverAnchorEl(null)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} transformOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Box sx={{ overflow: 'hidden' }}>
                    <IconSettings
                        currentIconState={{ ...tableCellDefaultIconSettings, iconSource: cell.iconSettings.iconSource }}
                        handleIconStateChange={(newIconState: TableCellIconSettings) => handleSingleIconStateChange(newIconState, { rIdx, cIdx })} />
                </Box>
            </NoDragPopover>
        </>
    )
}

export default IconBoxWithSettings
