import React from 'react'
import { Box, IconButton, Tooltip } from '@mui/material'
import { SxProps } from '@mui/system'
import InElementBox from './InElementBox'
import NeutralCircleButton from './NeutralCircleButton'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { NCButtonSize } from '../../lib/style'
import NestedMenu from './NestedMenu'
import { useTheme } from '@mui/material/styles'
import { hexToRgba } from '../../lib/colors'

// Define a type for icon settings with tooltip title
type IconSetting = {
    position: PositionKey
    icon: React.ReactNode
    onClick: (event: React.MouseEvent) => void
    title: string // Tooltip title for the button
}

type ControlOverlayProps = {
    el: LogicalElement
    relRndProps: BasicRelRndProps
    box: ElementBox
    iconArray?: IconSetting[]
    menu?: { position: PositionKey, nestedMenuProps: NestedMenuProps }
    opacity?: number
}

const positionStyles: Record<PositionKey, SxProps> = {
    TOPLEFT: { top: 0, left: 0, transform: 'translate(-50%, -50%)' },
    TOPCENTER: { top: 0, left: '50%', transform: 'translate(-50%, -66%)' },
    TOPRIGHT: { top: 0, right: 0, transform: 'translate(50%, -50%)' },
    CENTERLEFT: { top: '50%', left: 0, transform: 'translate(-66%, -50%)' },
    CENTERCENTER: { top: '50%', left: '50%', transform: 'translate(-50%, -50%)' },
    CENTERRIGHT: { top: '50%', right: 0, transform: 'translate(66%, -50%)' },
    BOTTOMLEFT: { bottom: 0, left: 0, transform: 'translate(-50%, 50%)' },
    BOTTOMCENTER: { bottom: 0, left: '50%', transform: 'translate(-50%, 66%)' },
    BOTTOMRIGHT: { bottom: 0, right: 0, transform: 'translate(50%, 50%)' },
}

const ControlOverlay: React.FC<ControlOverlayProps> = ({ el, relRndProps, box, iconArray, menu, opacity }) => {
    const theme = useTheme()

    return (
        <InElementBox el={el} relRndProps={relRndProps} box={box} transparent={true}>
            <Box
                sx={{
                    position: 'absolute', top: 0, left: 0, right: 0, bottom: 0,
                    pointerEvents: 'none',
                    backgroundColor: hexToRgba(theme.palette.background.paper, opacity || 0.1),
                    borderRadius: theme.customStyling.relRndBoarderRadius,
                    borderStyle: "solid", borderWidth: "0px",
                    borderColor: theme.palette.text.disabled
                }}
            />
            {iconArray && iconArray.map(({ position, icon, onClick, title }, index) => (
                <Box
                    key={index}
                    sx={{
                        position: 'absolute', ...positionStyles[position], pointerEvents: 'auto',
                        opacity: 0.4, transition: 'opacity 0.3s ease',
                        '&:hover': { opacity: 1 }
                    }}>
                    <NeutralCircleButton size={NCButtonSize.standard.size}>
                        <Tooltip title={title}>
                            <IconButton onPointerUp={(event) => { event.stopPropagation(); onClick(event) }}
                                size={NCButtonSize.standard.iconButtonSize}>
                                {icon}
                            </IconButton>
                        </Tooltip>
                    </NeutralCircleButton>
                </Box>
            ))}

            {menu && <Box
                sx={{
                    position: 'absolute', ...positionStyles[menu.position], pointerEvents: 'auto',
                    opacity: 0.4, transition: 'opacity 0.3s ease',
                    '&:hover': { opacity: 1 }
                }}>
                <NestedMenu options={menu.nestedMenuProps.options} menuLevels={menu.nestedMenuProps.menuLevels}
                    tooltip='Cell Options' icon={menu.nestedMenuProps.icon || <MoreVertIcon fontSize={NCButtonSize.standard.iconFontSize} />} />
            </Box>}
        </InElementBox>
    )
}

export default ControlOverlay
