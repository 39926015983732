import { useEffect, useState, useRef } from 'react'
import Numeration1 from '../pptElements/numeration1/Numeration1'
import TextBox1 from '../pptElements/textbox1/TextBox1'
import Title from '../pptElements/title1/Title1'
import Table1 from '../pptElements/table1/Table1'
import Box from '@mui/material/Box'
import {
  getLogicalSlide, getSlideRenderDetail, setSlideElement, setSlideElementBox,
  setSlideTitle, setSlideElementContent, removeSlideElement, setSlideElementColCon, setSlideElementTitle, setSlideElementBasicStyle,
  setSlideElementFramePadding,
  getSlides,
  setElementFontSizeFactor,
  setSlideElementUserPrompt
} from '../../store/slidesSlice'
import { addSelectedElement, removeSelectedElement, addActiveElement, removeActiveElement, getActiveElements, getSelectedElements } from '../../store/displayControlsSlice'
import { getRenderingOptions } from '../../store/renderingOptionsSlice'
import { useAppSelector, useAppDispatch } from '../../store/hooks'
import ResizeObserver from 'resize-observer-polyfill'
import { getCurrentSlideId } from '../../store/presentationSelectionSlice'
import Paper from '@mui/material/Paper'
import { handleLogicalSlideChange } from '../../lib/slideHandling'
import { ElementDropTarget } from '../elements/ElementDropTarget'
import { noHyphenUuid, titleInitRichText } from '../../lib/init'
import { useTheme } from '@mui/material/styles'
import { setClipboard, getClipboard } from '../../store/clipboardSlice'
import { paragraphFromText } from '../../lib/text'
import { Descendant } from 'slate'
import { useHistContext } from '../helpers/UseHistory'
import { handleElementPaste } from '../../lib/slideHandling'
import { useHotkeys, useHotkeysContext } from 'react-hotkeys-hook'
import { ClickAwayListener } from '@mui/material'
import { globalHotKeyScope } from '../../lib/constants'
import Timeline1 from '../pptElements/timeline1/Timeline1'
import IconGrid1 from '../pptElements/icongrid1/IconGrid1'
import Image1 from '../pptElements/image1/Image1'
import Frame1 from '../pptElements/frame1/Frame1'


function Stage() {
  const stageKeyboardScope = 'stage'
  const { disableScope, enableScope } = useHotkeysContext()
  const { histActions } = useHistContext()
  // state access  
  const dispatch = useAppDispatch()
  const slides = useAppSelector(getSlides)
  const currentSlideId = useAppSelector(getCurrentSlideId)
  const renderingOptions = useAppSelector(getRenderingOptions)
  const logicalSlide = useAppSelector((state) => getLogicalSlide(state, { slideId: currentSlideId }))
  const renderDetail = useAppSelector((state) => getSlideRenderDetail(state, { slideId: currentSlideId }))
  const activeElements = useAppSelector(getActiveElements)
  const selectedElements = useAppSelector(getSelectedElements)
  // Sort elements by zIndex but move elements that are active to the top
  const childElementArray = logicalSlide ? Object.values(logicalSlide.rootElement.childElements).sort((a: LogicalElement, b: LogicalElement) => {
    if (a.elementId === activeElements[0] && !(b.elementId === activeElements[0]) && activeElements[0] !== undefined) {
      return 1
    } else if (!(a.elementId === activeElements[0]) && b.elementId === activeElements[0] && activeElements[0] !== undefined) {
      return -1
    }
    return (a.zIndex || 0) - (b.zIndex || 0)
  }) : undefined
  const clipboard = useAppSelector(getClipboard)
  const [stageSize, setStageSize] = useState({ width: 16, height: 9 })
  const currentElement = logicalSlide?.rootElement.childElements[activeElements[0]]
  const maxZIndex: number = logicalSlide ? Math.max(...Object.values(logicalSlide?.rootElement.childElements).map((el) => (el.zIndex || 0))) : 0
  const minZIndex: number = logicalSlide ? Math.min(...Object.values(logicalSlide?.rootElement.childElements).map((el) => (el.zIndex || 0))) : 0
  const logicalSlideVersionById: Record<string, number> = Object.keys(slides).reduce((res, slideId) => { res[slideId] = slides[slideId].logicalSlide.version; return res }, {} as Record<string, number>)
  const renderVersionById: Record<string, number | undefined> = Object.keys(slides).reduce((res, slideId) => { res[slideId] = slides[slideId].renderDetail.version; return res }, {} as Record<string, number | undefined>)
  // references
  const logicalSlideVersionRef = useRef<Record<string, number>>(logicalSlideVersionById)
  logicalSlideVersionRef.current = logicalSlideVersionById
  const renderVersionRef = useRef<Record<string, number | undefined>>(renderVersionById)
  renderVersionRef.current = renderVersionById
  const currentSlideIdRef = useRef<string | undefined>()
  currentSlideIdRef.current = currentSlideId
  const activeElementsRef = useRef<string[]>()
  activeElementsRef.current = activeElements
  const selectedElementsRef = useRef<string[]>()
  selectedElementsRef.current = selectedElements
  const maxZIndexRef = useRef<number>(maxZIndex)
  maxZIndexRef.current = maxZIndex
  const minZIndexRef = useRef<number>(minZIndex)
  minZIndexRef.current = minZIndex

  const theme = useTheme()

  // onMount hook
  useEffect(() => {
    // React on resize of the slide container
    let stageElement = document.getElementById("stage") as Element
    const resizeObserver = new ResizeObserver((event: ResizeObserverEntry[]) => {
      setStageSize({
        // width: event[0].contentBoxSize[0].inlineSize,
        // height: event[0].contentBoxSize[0].blockSize
        width: event[0].contentRect.width,
        height: event[0].contentRect.height
      })
    })
    resizeObserver.observe(stageElement)

    // prevent touch scrolling on stage
    //const container = document.getElementById("stage") as Element
    //container.addEventListener('touchmove', event => event.preventDefault(), , {passive: false})

    return () => {
      // cleanup
      resizeObserver.unobserve(stageElement)
      //container.removeEventListener('touchmove', event => event.preventDefault())
    }
  }, [])

  // React on slide change
  useEffect(() => {
    handleLogicalSlideChange(dispatch, logicalSlide, currentSlideId, logicalSlideVersionRef, renderVersionRef, renderingOptions, histActions)
  }, [logicalSlide]) // eslint-disable-line react-hooks/exhaustive-deps


  const createNewElement = (el: ElementDefinition, x?: number, y?: number) => {
    if (currentSlideIdRef.current) {
      const desiredLeft = x !== undefined ? x - (el.preset.box.width / 2) : el.preset.box.left
      const desiredTop = y !== undefined ? y - (el.preset.box.height / 2) : el.preset.box.top
      // calc the box coordinates from the drop coordinates 
      const maxLeft = 1 - el.preset.box.width
      const maxTop = 1 - el.preset.box.height
      const boxLeft = Math.max(Math.min(maxLeft, desiredLeft), 0)
      const boxTop = Math.max(Math.min(maxTop, desiredTop), 0)

      const elId = noHyphenUuid()
      const newElement: LogicalElement = {
        slideId: currentSlideIdRef.current, childElements: {}, box: { ...el.preset.box, left: boxLeft, top: boxTop },
        elementType: el.preset.type, elementId: elId, content: el.preset.content, zIndex: maxZIndexRef.current + 1,
        framePadding: el.preset.framePadding ? el.preset.framePadding : null, presetGroup: el.preset.presetGroup
      }
      if (el.preset.titleText) newElement.title = { showTitle: true, richTextBox: { richText: paragraphFromText(el.preset.titleText) } }
      else newElement.title = { showTitle: false, richTextBox: { richText: paragraphFromText('Title...') } }
      if (el.preset.appearance) newElement.appearance = el.preset.appearance
      else newElement.appearance = undefined
      if (el.preset.userFontFactors) newElement.userFontFactors = el.preset.userFontFactors

      histActions.set({
        do: () => dispatch(setSlideElement({ slideId: currentSlideIdRef.current, elementId: elId, logicalElement: newElement })),
        undo: () => dispatch(removeSlideElement({ slideId: currentSlideIdRef.current, elementId: elId })),
        stepTitle: 'Create element'
      })
      window.gtag("event", "select_content", { content_type: "add_element" })
    }
  }

  const handlePresetChange: ElementPresetChangeFunction = (el, newPreset) => {
    // calc the box coordinates from the drop coordinates 
    const maxLeft = 1 - newPreset.box.width
    const maxTop = 1 - newPreset.box.height
    const boxLeft = Math.max(Math.min(maxLeft, el.box.left), 0)
    const boxTop = Math.max(Math.min(maxTop, el.box.top), 0)

    const newElement = {
      ...el, box: { ...newPreset.box, left: boxLeft, top: boxTop }, presetGroup: newPreset.presetGroup,
      elementType: newPreset.type, content: newPreset.content
    }
    if (newPreset.titleText) newElement.title = { showTitle: true, richTextBox: { richText: paragraphFromText(newPreset.titleText) } }
    else newElement.title = { showTitle: false, richTextBox: { richText: paragraphFromText('Title...') } }
    if (newPreset.appearance) newElement.appearance = newPreset.appearance
    newElement.userFontFactors = newPreset.userFontFactors

    const currentElement = logicalSlide?.rootElement.childElements[el.elementId]
    if (currentElement) {
      histActions.set({
        do: () => dispatch(setSlideElement({ slideId: el.slideId, elementId: el.elementId, logicalElement: newElement })),
        undo: () => dispatch(setSlideElement({ slideId: el.slideId, elementId: el.elementId, logicalElement: currentElement })),
        stepTitle: 'Change element preset'
      })
      window.gtag("event", "select_content", { content_type: "select_element_preset" })
    }
  }

  const removeElement: IdClickFunction = (elementId) => {
    if (currentSlideIdRef.current && elementId) {
      const currentElement = logicalSlide?.rootElement.childElements[elementId]
      if (currentElement) {
        histActions.set({
          do: () => {
            dispatch(removeSlideElement({ slideId: currentSlideIdRef.current, elementId: elementId }))
            handleDisplayControlChange(elementId, "REMOVE", "SELECTION")
            handleDisplayControlChange(elementId, "REMOVE", "ACTIVATION")
          },
          undo: () => dispatch(setSlideElement({ slideId: currentSlideIdRef.current, elementId: elementId, logicalElement: currentElement })),
          stepTitle: 'Remove element'
        })
      }
    }
  }

  const handleBoxChange: BoxChangeFunction = (slideId, elementId, box, changeType) => {
    const currentElement = logicalSlide?.rootElement.childElements[elementId]
    if (currentElement) {
      histActions.set({
        do: () => dispatch(setSlideElementBox({ slideId: slideId, elementId: elementId, box: box, changeType: changeType })),
        undo: () => dispatch(setSlideElementBox({ slideId: slideId, elementId: elementId, box: currentElement.box, changeType: changeType })),
        stepTitle: 'Move/resize element'
      })
    }
  }

  const handleElementContentChange: ElementContentChangeFunction = (slideId, elementId, content, changeType) => {
    const currentElement = logicalSlide?.rootElement.childElements[elementId]
    if (currentElement) {
      const currentContent = currentElement.content
      histActions.set({
        do: () => dispatch(setSlideElementContent({ slideId: slideId, elementId, content: content, changeType: changeType })),
        undo: currentContent ? () => dispatch(setSlideElementContent({ slideId: slideId, elementId, content: currentContent, changeType: changeType })) : undefined,
        stepTitle: 'Change element content'
      })
    }
  }

  const handleElementUserFontFactorChange: ElementUserFontFactorChangeFunction = (el, textRole, fontFactor) => {
    if (el.userFontFactors === undefined || el.userFontFactors[textRole] !== fontFactor) {
      const newFontFactors: UserFontFactors = el.userFontFactors ? { ...el.userFontFactors, [textRole]: fontFactor } : { [textRole]: fontFactor }
      histActions.set({
        do: () => dispatch(setElementFontSizeFactor({ slideId: el.slideId, elementId: el.elementId, userFontFactors: newFontFactors })),
        undo: () => dispatch(setElementFontSizeFactor({ slideId: el.slideId, elementId: el.elementId, userFontFactors: el.userFontFactors })),
        stepTitle: 'Change font size'
      })
    }
  }

  const handleElementCopy: ElementCopyFunction = (el) => {
    if (el) {
      histActions.set({
        do: () => dispatch(setClipboard({ logicalElement: el })),
        undo: () => dispatch(setClipboard({ ...clipboard })),
        stepTitle: 'Copy element'
      })
    }
  }

  // key press
  useHotkeys('mod+c', () => handleElementCopy(currentElement), { scopes: stageKeyboardScope }, [logicalSlide, dispatch, currentSlideId, clipboard, histActions, activeElements])
  useHotkeys('mod+x', () => { handleElementCopy(currentElement); removeElement(currentElement?.elementId) }, { scopes: stageKeyboardScope }, [logicalSlide, dispatch, currentSlideId, clipboard, histActions, activeElements])
  useHotkeys('mod+v', () => handleElementPaste(dispatch, currentSlideId, clipboard, histActions), { scopes: globalHotKeyScope }, [dispatch, currentSlideId, clipboard, histActions])

  const handleElementFramePaddingChange: ElementFramePaddingChangeFunction = (el, newFramePadding) => {
    const currentElement = logicalSlide?.rootElement.childElements[el.elementId]
    if (currentElement) {
      const currentFramePadding = currentElement.framePadding
      histActions.set({
        do: () => dispatch(setSlideElementFramePadding({ slideId: el.slideId, elementId: el.elementId, framePadding: newFramePadding })),
        undo: currentFramePadding !== undefined ? () => dispatch(setSlideElementFramePadding({ slideId: el.slideId, elementId: el.elementId, framePadding: currentFramePadding })) : () => dispatch(setSlideElementFramePadding({ slideId: el.slideId, elementId: el.elementId, framePadding: null })),
        stepTitle: 'Change element frame'
      })
    }
  }

  const handleElementBasicStyleChange: ElementBasicStyleChangeFunction = (slideId, elementId, newStyle) => {
    const currentElement = logicalSlide?.rootElement.childElements[elementId]
    if (currentElement) {
      const currentStyle = currentElement.appearance?.basicStyle
      histActions.set({
        do: () => dispatch(setSlideElementBasicStyle({ slideId: slideId, elementId, basicStyle: newStyle })),
        undo: currentStyle !== undefined ? () => dispatch(setSlideElementBasicStyle({ slideId: slideId, elementId, basicStyle: currentStyle })) : () => dispatch(setSlideElementBasicStyle({ slideId: slideId, elementId, basicStyle: null })),
        stepTitle: 'Change element style'
      })
    }
  }

  const handleElementTitleVisibilityChange: ElementTitleVisibilityChangeFunction = (el) => {
    const currentElement = logicalSlide?.rootElement.childElements[el.elementId]
    if (currentElement) {
      const newTitle = { ...el.title, showTitle: !el.title?.showTitle }
      if (newTitle.richTextBox?.richText === undefined || newTitle.richTextBox.richText === null) newTitle.richTextBox = { richText: paragraphFromText("Title...") }
      histActions.set({
        do: () => dispatch(setSlideElementTitle({ slideId: el.slideId, elementId: el.elementId, title: newTitle, changeType: "IMMEDIATE_ELEMENT_CONTENT_TYPING" })),
        undo: () => dispatch(setSlideElementTitle({ slideId: el.slideId, elementId: el.elementId, title: !currentElement.title ? {} : currentElement.title, changeType: "IMMEDIATE_ELEMENT_CONTENT_TYPING" })),
        stepTitle: 'Change element title'
      })
    }
  }

  const handleElementTitleTextChange: ElementTitleTextChangeFunction = (el, newText) => {
    const currentElement = logicalSlide?.rootElement.childElements[el.elementId]
    if (currentElement) {
      const newTitle: ElementTitle = { richTextBox: { ...el.title?.richTextBox, richText: newText }, showTitle: !!el.title?.showTitle }
      histActions.set({
        do: () => dispatch(setSlideElementTitle({ slideId: el.slideId, elementId: el.elementId, title: newTitle, changeType: "IMMEDIATE_ELEMENT_CONTENT_TYPING" })),
        undo: () => dispatch(setSlideElementTitle({ slideId: el.slideId, elementId: el.elementId, title: !currentElement.title ? {} : currentElement.title, changeType: "IMMEDIATE_ELEMENT_CONTENT_TYPING" })),
        stepTitle: 'Change element title'
      })
    }
  }



  const handleSlideTitleChange = (newTitle: Descendant[]) => {
    if (logicalSlide && currentSlideIdRef.current) {
      histActions.set({
        do: () => dispatch(setSlideTitle({ slideId: currentSlideIdRef.current, titleRichText: newTitle })),
        undo: () => dispatch(setSlideTitle({ slideId: currentSlideIdRef.current, titleRichText: logicalSlide.titleElement?.title?.richTextBox?.richText || titleInitRichText })),
        stepTitle: 'Change slide title'
      })
    }
  }

  const handleDisplayControlChange: DisplayControlFunction = (elementId, mode, target) => {
    if (elementId) {
      if (mode === "ADD" && target === "ACTIVATION") dispatch(addActiveElement({ elementId: elementId }))
      if (mode === "REMOVE" && target === "ACTIVATION") dispatch(removeActiveElement({ elementId: elementId }))
      if (mode === "ADD" && target === "SELECTION") dispatch(addSelectedElement({ elementId: elementId }))
      if (mode === "REMOVE" && target === "SELECTION") dispatch(removeSelectedElement({ elementId: elementId }))
    }
  }

  const setElementColCon: ElementColConFunction = (el, col, con) => {
    const currentElement = logicalSlide?.rootElement.childElements[el.elementId]
    if (currentElement) {
      if (currentSlideIdRef.current && el.elementId && col !== undefined && con !== undefined)
        histActions.set({
          do: () => dispatch(setSlideElementColCon({ slideId: currentSlideIdRef.current, elementId: el.elementId, col: col, con: con })),
          undo: () => dispatch(setSlideElementColCon({
            slideId: currentSlideIdRef.current, elementId: el.elementId,
            col: currentElement.appearance?.color !== undefined ? currentElement.appearance?.color : 2,
            con: currentElement.appearance?.contrast !== undefined ? currentElement.appearance?.contrast : 1
          })),
          stepTitle: 'Change element color/contrast'
        })

    }
  }

  const handleElementZIndexChange: ElementZIndexFunction = (el, direction) => {
    const newZIndex = direction === 'FRONT' ? maxZIndexRef.current + 1 : minZIndexRef.current - 1
    histActions.set({
      do: () => dispatch(setSlideElement({ slideId: currentSlideIdRef.current, elementId: el.elementId, logicalElement: { ...el, zIndex: newZIndex } })),
      undo: () => dispatch(setSlideElement({ slideId: currentSlideIdRef.current, elementId: el.elementId, logicalElement: el })),
      stepTitle: 'Move to front/back'
    })
  }

  const handleUserPromptChange = (el: LogicalElement, userPrompt: string | null) => {
    const currentElement = logicalSlide?.rootElement.childElements[el.elementId]
    if (currentElement) {
      window.gtag("event", "select_content", { content_type: "element_ai_prompt_use" })
      histActions.set({
        do: () => dispatch(setSlideElementUserPrompt({ slideId: currentSlideIdRef.current, elementId: el.elementId, userPrompt: userPrompt || null })),
        undo: () => dispatch(setSlideElement({ slideId: currentSlideIdRef.current, elementId: el.elementId, logicalElement: el })),
        stepTitle: 'Protoslides AI request'
      })
    }
  }

  // check element selection & activation
  const elementIsActive = (elId: string) => {
    return activeElementsRef.current ? activeElementsRef.current.includes(elId) : false
  }
  const elementIsSelected = (elId: string) => {
    return selectedElementsRef.current ? selectedElementsRef.current.includes(elId) : false
  }

  // static properties of relRnd
  const relRndProps: BasicRelRndProps = {
    parentSize: stageSize,
    currentSlideId: currentSlideId,
    handleDisplayControlChange: handleDisplayControlChange,
    handleBoxChange: handleBoxChange,
    handleRemoveElement: removeElement,
    handleSetElementColCon: setElementColCon,
    handleElementTitleVisibilityChange: handleElementTitleVisibilityChange,
    handleElementTitleTextChange: handleElementTitleTextChange,
    handleElementBasicStyleChange: handleElementBasicStyleChange,
    handleElementPresetChange: handlePresetChange,
    handleElementCopy: handleElementCopy,
    handleElementFramePaddingChange: handleElementFramePaddingChange,
    handleElementZIndexChange: handleElementZIndexChange,
    handleElementUserFontFactorChange: handleElementUserFontFactorChange,
    handleUserPromptChange: handleUserPromptChange,
  }


  return (
    <ClickAwayListener onClickAway={() => { disableScope(stageKeyboardScope) }}>
      <Box sx={{ width: 1, height: 1, position: "relative", ...(renderDetail?.aspectRatio && { aspectRatio: renderDetail.aspectRatio }) }} id='stage'
        onClick={() => { enableScope(stageKeyboardScope) }}>
        {logicalSlide && childElementArray && renderDetail &&
          <Box>

            {/* All elements */}
            <Box sx={{ position: "absolute", width: 1, height: 1, top: 0, left: 0, zIndex: 1 }}>

              {logicalSlide.titleElement?.title?.showTitle &&
                <Title key={"TitleElement"} el={logicalSlide.titleElement} relRndProps={relRndProps} handleTitleChange={handleSlideTitleChange}
                  elementActive={elementIsActive(logicalSlide.titleElement.elementId)} elementSelected={elementIsSelected(logicalSlide.titleElement.elementId)} />
              }

              {childElementArray.map((el: LogicalElement, i: number) => (
                <>
                  {el.elementType === "NUMERATION1" &&
                    <Numeration1 key={el.elementId} el={el} relRndProps={relRndProps} updateElementContent={handleElementContentChange}
                      elementActive={elementIsActive(el.elementId)} elementSelected={elementIsSelected(el.elementId)} />}

                  {el.elementType === "TEXTBOX1" &&
                    <TextBox1 key={el.elementId} el={el} relRndProps={relRndProps} updateElementContent={handleElementContentChange}
                      elementActive={elementIsActive(el.elementId)} elementSelected={elementIsSelected(el.elementId)} />}

                  {el.elementType === "TABLE1" &&
                    <Table1 key={el.elementId} el={el} relRndProps={relRndProps} updateElementContent={handleElementContentChange}
                      elementActive={elementIsActive(el.elementId)} elementSelected={elementIsSelected(el.elementId)} />}

                  {el.elementType === "TIMELINE1" &&
                    <Timeline1 key={el.elementId} el={el} relRndProps={relRndProps} updateElementContent={handleElementContentChange}
                      elementActive={elementIsActive(el.elementId)} elementSelected={elementIsSelected(el.elementId)} />}

                  {el.elementType === "ICONGRID1" &&
                    <IconGrid1 key={el.elementId} el={el} relRndProps={relRndProps} updateElementContent={handleElementContentChange}
                      elementActive={elementIsActive(el.elementId)} elementSelected={elementIsSelected(el.elementId)} />}

                  {el.elementType === "IMAGE1" &&
                    <Image1 key={el.elementId} el={el} relRndProps={relRndProps} updateElementContent={handleElementContentChange}
                      elementActive={elementIsActive(el.elementId)} elementSelected={elementIsSelected(el.elementId)} />}

                  {el.elementType === "FRAME1" &&
                    <Frame1 key={el.elementId} el={el} relRndProps={relRndProps} updateElementContent={handleElementContentChange}
                      elementActive={elementIsActive(el.elementId)} elementSelected={elementIsSelected(el.elementId)} />}

                </>))}
            </Box>

            {/* Prevent the image from beeing clickable & Element DropZone */}
            <ElementDropTarget handleElementDrop={createNewElement} />

            <Paper elevation={0} sx={{
              width: 1, height: 1, top: 0, left: 0, overflow: "hidden",
              zIndex: -1, border: '1px solid', borderColor: theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[800]
            }}>
              <img src={`data:image/${renderDetail?.renderFormat};base64,${renderDetail?.slideImgB64}`} width="100%" height="100%" alt="" style={{ display: "block" }} />
            </Paper>
          </Box>}
      </Box>
    </ClickAwayListener>
  )
}

export default Stage
