import React from 'react'
import { Descendant } from 'slate'
import RichTextPopover from '../text/RichTextPopover'

type ElementTitleProps = {
    el: LogicalElement
    relRndProps: BasicRelRndProps
}

const ElementTitle: React.FC<ElementTitleProps> = ({ el, relRndProps }) => {
    const handleElementTitleTextChange = (newText: Descendant[]) => {
        if (relRndProps.handleElementTitleTextChange) {
            relRndProps.handleElementTitleTextChange(el, newText)
        }
    }

    return (
        <>
            {el.title?.showTitle && el.title?.richTextBox && (
                <RichTextPopover
                    el={el}
                    relRndProps={relRndProps}
                    richTextBox={el.title?.richTextBox}
                    handleBlur={handleElementTitleTextChange}
                    textRole="TITLE"
                />
            )}
        </>
    )
}

export default ElementTitle
