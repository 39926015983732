import { Theme } from "@mui/material"
import { StylesOptions } from "react-joyride"
import { blendColors } from "../../lib/colors"

export function getUserGuideStyleOptions(theme: Theme): StylesOptions {
    return {
        arrowColor: theme.palette.mode === 'dark' ? blendColors(theme.palette.background.default, theme.palette.text.primary, 0.3) : theme.palette.background.default,
        backgroundColor: theme.palette.mode === 'dark' ? blendColors(theme.palette.background.default, theme.palette.text.primary, 0.3) : theme.palette.background.default,
        beaconSize: 36,
        overlayColor: 'rgba(0, 0, 0, 0.5)',
        primaryColor: theme.palette.primary.main,
        spotlightShadow: '0 0 15px rgba(255, 0, 0, 0.5)',
        textColor: theme.palette.text.primary,
        width: undefined,
        zIndex: 1250,
    }
}