import React, { useState } from 'react'
import { Box, Typography, Button, TextField, Tooltip, IconButton, useTheme } from '@mui/material'
import { HexColorPicker } from 'react-colorful'
import { isValidHexColor } from '../../lib/colors'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import InfoText from '../helpers/InfoText'

type NewThemeCreateScreenProps = {
    onBackToOverview: () => void,
    handleNewMasterFromTemplate: NewMasterFromTemplateFunction
}

const NewThemeCreateScreen: React.FC<NewThemeCreateScreenProps> = ({ onBackToOverview, handleNewMasterFromTemplate }) => {
    const theme = useTheme()

    const colorPickerStyle = {
        "& .react-colorful__saturation": {
            width: '100%',
            height: '50px',
        },
        "& .react-colorful__hue": {
            width: '100%',
        },
        "& .react-colorful": {
            width: '100%',
            minWidht: '50px',
        }
    }

    // state
    const [color, setColor] = useState("#1976d2")
    const [themeName, setThemeName] = useState("")


    const handleCreateTheme = () => {
        if (isValidHexColor(color, false)) {
            handleNewMasterFromTemplate(themeName ? themeName : 'New Theme', 'MODERN_WIDE', color)
            onBackToOverview()
        }
    }


    const handleColorEditChange = (e: { target: { value: string } }) => {
        const newValue = e.target.value

        // Allow the input if it's empty or only a '#' followed by valid hex characters
        if (/^#?([0-9a-fA-F]{0,6})$/.test(newValue) && color !== newValue) {
            setColor(newValue)
        }
    }

    return (
        <Box sx={{ width: 1, height: 1 }}>
            {/* Top controls */}
            <Box sx={{ width: 1, display: 'grid', gridTemplateColumns: '1fr', justifyContent: 'center', alignItems: 'center', mt: 0 }}>
                <Typography variant="h5" align="center" sx={{ gridColumnStart: 1, gridRowStart: 1 }}>
                    New Theme
                </Typography>
                <Box sx={{ width: 1, display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', alignContent: 'flex-start' }}>
                    <Tooltip title="Back to theme overview">
                        <IconButton onClick={(event) => { onBackToOverview() }} sx={{ gridColumnStart: 1, gridRowStart: 1, mt: -4 }} size="medium" >
                            <ArrowBackIcon fontSize="medium" sx={{ color: theme.palette.primary.main }} />
                        </IconButton>
                    </Tooltip>
                </Box>
            </Box>
            <Box sx={{ width: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 0 }}>
                <Typography variant="subtitle1" align="center" color="text.disabled">
                    Choose name and primary color
                </Typography>
            </Box>

            <Box sx={{ width: '100%', mt: 0 }}>

                <Box sx={{ height: 1, display: 'flex', flexWrap: 'wrap', alignContent: 'space-between', alignItems: 'stretch' }}>
                    <Box sx={{ width: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 4, mb: 4 }}>
                        <TextField
                            label="Theme name"
                            defaultValue={'My new theme'}
                            variant="standard"
                            sx={{ width: 0.6, minWidth: '300px' }}
                            value={themeName}
                            onChange={(e) => setThemeName(e.target.value)}
                        />
                    </Box>
                    <Box sx={{ width: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap' }}>
                        <Box sx={{ ...colorPickerStyle, width: 0.6, minWidth: '300px', display: 'flex', justifyContent: 'center', mb: 2 }}>
                            <HexColorPicker color={color} onChange={setColor} />
                        </Box>
                        <Box sx={{ width: 1, display: 'flex', justifyContent: 'center' }}>
                            <TextField
                                error={!isValidHexColor(color, false)}
                                helperText={!isValidHexColor(color, false) ? "Enter a valid hex color" : undefined}
                                variant="standard"
                                sx={{
                                    width: '200px', textTransform: 'uppercase', '& .MuiInputBase-input': { textAlign: 'center' }
                                }}
                                value={color}
                                onChange={handleColorEditChange}
                            />
                        </Box>

                    </Box>
                </Box>
            </Box>

            <Box sx={{ width: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 6 }}>
                <Button variant="contained" onClick={handleCreateTheme} disabled={(!isValidHexColor(color, false))}
                    sx={{ width: 200 }}>
                    Create Theme
                </Button>
            </Box>
            <Box sx={{ width: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 6 }}>
                <InfoText text="Your theme comes with different slide backgrounds which can be switched in the slide settings." />
            </Box>
        </Box>
    )
}

export default NewThemeCreateScreen
