import { Placement } from "react-joyride"
import { useState, useEffect } from 'react'
import Joyride, { ACTIONS, EVENTS, STATUS, Step, CallBackProps } from 'react-joyride'
import { getUserGuideStyleOptions } from "./style"
import { Typography, useTheme } from "@mui/material"
import { contentStyle, contentVariant, slidePanelTutorialCompletedStorageKey } from "./misc"
import { AddCircle, ArrowUpward, AutoAwesome, MoreVert } from "@mui/icons-material"
import { useLocation } from "react-router-dom"

export const steps: Step[] = [
    {
        title: 'Slide Overview',
        target: '.left_drawer_handle',
        placement: 'right' as Placement,
        content: <Typography variant={contentVariant} style={contentStyle}>
            After selecting a presentation, you'll see all the slides in that presentation displayed in the slide panel on the left.
            <br /><br />
            To return to the presentation overview, click the <ArrowUpward sx={{ mb: -0.5, pt: 0.5 }} /> icon.
        </Typography>,
        disableBeacon: true
    },
    // {
    //     title: 'Slide overview',
    //     target: '.presentation_actions_button',
    //     placement: 'right' as Placement,
    //     content: <Typography variant={contentVariant} style={contentStyle}>
    //         The <MoreVert sx={{ mb: -0.5, pt: 0.5, mr: -0.5 }} />- icon at the top gives you access to presentation settings:
    //         <ul>
    //             <li><b>Master:</b> Change the master of the current presentation.</li>
    //             <li><b>Style:</b> Change the style of the presentation. (fill style, rounded edges, ...)</li>
    //             <li><b>Typography:</b> Set the desired font size of normal text for the whole presentation.</li>
    //             <li><b>Download:</b> Download the presentation.</li>
    //             <li><b>Duplicate, delete</b></li>
    //         </ul>
    //     </Typography>,
    //     disableBeacon: true
    // },
    {
        title: 'Settings',
        target: '.presentation_actions_button',
        placement: 'right' as Placement,
        content: <Typography variant={contentVariant} style={contentStyle}>
            Access settings for both presentations and slides using the <b>settings buttons</b>:
            <br /><br />
            <ol>
                <li>
                    <b>Presentation Settings: </b>
                    Use the <MoreVert sx={{ mb: -0.5, pt: 0.5, mr: -0.5, ml: -0.5 }} /> icon in the toolbar to access:
                    <ul>
                        <li><b>Theme Selection: </b> Change the presentation's theme.</li>
                        <li><b>Style:</b> Adjust the overall style (e.g., fill style, rounded edges).</li>
                        <li><b>Typography:</b> Set the default font size for normal text.</li>
                        <li><b>Download:</b> Save your presentation as a file.</li>
                        <li><b>Duplicate or Delete: </b> Manage your presentations.</li>
                    </ul>
                </li>
                <br />
                <li>
                    <b>Slide Settings: </b>
                    Use the <MoreVert sx={{ mb: -0.5, pt: 0.5, mr: -0.5, ml: -0.5 }} /> icon on a slide thumbnail to adjust individual slides (e.g. change the slide background).
                </li>
            </ol>
            <br />To select a slide, click its thumbnail.
        </Typography>,
        disableBeacon: true
    },
    // {
    //     title: 'Select slide',
    //     target: '.slide_image',
    //     placement: 'right-start' as Placement,
    //     content: <Typography variant={contentVariant} style={contentStyle}>
    //         To select a slide and show it in the central main editor window, just click the desired thumbnail image.
    //     </Typography>,
    //     disableBeacon: true
    // },
    // {
    //     title: 'Slide settings',
    //     target: '.slide_actions_button',
    //     placement: 'right' as Placement,
    //     content: <Typography variant={contentVariant} style={contentStyle}>
    //         The <MoreVert sx={{ mb: -0.5, pt: 0.5, mr: -0.5 }} />- icon on the thumbnail image gives you access to slide settings:
    //         <ul>
    //             <li><b>Layout:</b> Select the slide layout to use from the current master of the presentation.</li>
    //             <li><b>Download:</b> Download the slide.</li>
    //             <li><b>Copy, paste, duplicate, delete</b></li>
    //         </ul>
    //     </Typography>,
    //     disableBeacon: true
    // },
    {
        title: 'Create Slides',
        content: <Typography variant={contentVariant} style={contentStyle}>
            Add a blank slide by clicking the <AddCircle sx={{ mb: -0.5, pt: 0.5 }} /> icon.
            <br /><br />
            Want something more advanced? Use <AutoAwesome sx={{ mb: -0.5, pt: 0.5 }} /> to <b>let our AI generate a slide</b> for you from your text input.
        </Typography>,
        target: '.create_slide_button',
        placement: 'right' as Placement,
        disableBeacon: true
    },
]


function SlidePanelTutorial() {
    const theme = useTheme()
    const location = useLocation()
    const [showJoyride, setShowJoyride] = useState<boolean>(false)
    const [stepIndex, setStepIndex] = useState<number>(0)

    const handleJoyrideCallback = async (data: CallBackProps) => {
        const { status, type, action, index } = data

        if (action === ACTIONS.NEXT && type === EVENTS.STEP_AFTER) {
            if (index === 0) gtag("event", "tutorial_begin")
            setStepIndex(stepIndex + 1)
        }

        if (action === ACTIONS.PREV && type === EVENTS.STEP_AFTER) {
            setStepIndex(stepIndex - 1)
        }

        if (action === ACTIONS.SKIP) {
            //Set a flag in local storage to indicate that the tutorial has been completed
            localStorage.setItem(slidePanelTutorialCompletedStorageKey, 'true')
            //Hide Joyride
            setShowJoyride(false)
        }
        if (status === STATUS.FINISHED) {
            gtag("event", "tutorial_complete")
            //Set a flag in local storage to indicate that the tutorial has been completed
            localStorage.setItem(slidePanelTutorialCompletedStorageKey, 'true')
            //Hide Joyride
            setShowJoyride(false)
        }
    }

    useEffect(() => {
        // Check local storage for the flag
        const joyrideCompleted = localStorage.getItem(slidePanelTutorialCompletedStorageKey)

        // check if presi ai open dialog shall be opened
        const openAIPresiCreationDialog = location.state?.openAIPresiCreationDialog || false

        // Show Joyride only if the tutorial has not been completed
        setShowJoyride(!joyrideCompleted && !openAIPresiCreationDialog)
    }, [location.state?.openAIPresiCreationDialog])

    return (
        <Typography variant={contentVariant} style={contentStyle}>
            {showJoyride && (
                <Joyride
                    steps={steps}
                    styles={{ options: getUserGuideStyleOptions(theme) }}
                    continuous
                    showProgress
                    showSkipButton
                    scrollToFirstStep
                    disableOverlay
                    hideCloseButton
                    disableCloseOnEsc
                    run={true}
                    callback={handleJoyrideCallback}
                    stepIndex={stepIndex}
                    disableScrolling
                />
            )}
            {/* Your main component content here */}
        </Typography>
    )
}

export default SlidePanelTutorial
