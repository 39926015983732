import React, { useEffect, useRef, useState } from 'react'
import { TextField, ImageList, ImageListItem, Box, useTheme, ImageListItemBar, IconButton, InputAdornment, CircularProgress } from '@mui/material'
import { searchImagesCall } from '../../lib/restCalls'
import InfoIcon from '@mui/icons-material/Info'
import unsplashLogo from '../../img/Unsplash_Logo_Full_Stacked.svg'
import SearchIcon from '@mui/icons-material/Search'
import { centeredFullWidthWrappingFlex } from '../../lib/style'


interface ImageSearchProps {
    height: string
    handleImageClick: (id: string, provider: ImageProvider, providerCustomValue: string) => void
}

const ImageSearch: React.FC<ImageSearchProps> = ({ height, handleImageClick }) => {
    const searchHeaderHeight = '90px'
    const theme = useTheme()

    const [searchTerm, setSearchTerm] = useState<string>('')
    const [results, setResults] = useState<UnsplashSearchResponseItem[]>([])
    const [loading, setLoading] = useState<boolean>(false)
    const currentSearchTermRef = useRef<string>()
    currentSearchTermRef.current = searchTerm

    const handleSearch = async () => {
        if (currentSearchTermRef.current && currentSearchTermRef.current.trim()) {
            setLoading(true)
            const response = await searchImagesCall(currentSearchTermRef.current, 1, 30)
            if (response.status === 200 && response.data) {
                setResults(response.data.results)
            }
            setLoading(false)
        }
    }

    // hooks
    useEffect(() => {
        // Add event listener when component mounts
        window.addEventListener('keydown', handleKeyPress)

        // Remove event listener when component unmounts
        return () => {
            window.removeEventListener('keydown', handleKeyPress)
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps


    //functions
    const handleKeyPress = (event: KeyboardEvent) => {
        if (event.key === 'Enter') {
            handleSearch()
        }
    }

    return (
        <Box sx={{ pt: 2, overflow: 'hidden', height: height }}>
            {/* Fixed Search Input and Button */}
            <Box sx={{ height: searchHeaderHeight }} >
                <Box sx={{ display: 'flex', gap: 2 }}>
                    <TextField
                        label="Search Unsplash Images"
                        variant="outlined"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        fullWidth
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon onClick={handleSearch} sx={{ cursor: 'pointer' }} />
                                </InputAdornment>
                            ),
                        }}
                    />
                    {/* <img src={unsplashLogo} alt="Unsplash Logo"
                        style={{
                            width: '50px', height: '50px', marginLeft: '0px', marginTop: '0px', marginBottom: '0px', marginRight: '0px',
                            filter: `invert(1) sepia(1) saturate(5) hue-rotate(${theme.palette.text.primary === '#000' ? '0deg' : '180deg'})` // Optional CSS filter for black/white logos
                        }}

                    /> */}
                </Box>
            </Box>

            {/* Scrollable Image List */}
            <Box sx={{ overflowY: 'auto', height: `calc(${height} - ${searchHeaderHeight})` }} >
                {loading ?
                    <Box sx={centeredFullWidthWrappingFlex}>
                        <CircularProgress />
                    </Box>
                    :
                    <ImageList cols={5} gap={8} sx={{}} variant="masonry">
                        {results.map((item) => (
                            <ImageListItem key={item.id} sx={{ position: 'relative', '&:hover .MuiImageListItemBar-root': { opacity: 1 }, cursor: 'pointer' }}
                                onClick={() => handleImageClick(item.id, 'UNSPLASH', item.links.downloadLocation)}>
                                <img
                                    src={item.urls.small} // Use 'small' image variant
                                    alt={item.description || 'Image'}
                                    loading="lazy"
                                    style={{ borderRadius: '8px' }}
                                />
                                <ImageListItemBar
                                    title={<a href={item.user.link + '?utm_source=Protoslides&utm_medium=referral'} target="_blank" rel="noopener noreferrer"
                                        onClick={(e) => e.stopPropagation()} style={{ color: 'white', textDecoration: 'none' }}>{'by ' + item.user.name}</a>}
                                    actionIcon={
                                        <IconButton component="a" href={item.links.html + '?utm_source=Protoslides&utm_medium=referral'} target="_blank" rel="noopener noreferrer"
                                            onClick={(e) => e.stopPropagation()} >
                                            <InfoIcon sx={{ color: 'white' }} />
                                        </IconButton>
                                    }
                                    sx={{ opacity: 0, transition: 'opacity 0.5s ease', background: 'rgba(0, 0, 0, 0.5)' }}
                                />
                            </ImageListItem>
                        ))}
                    </ImageList>
                }
            </Box>
        </Box>
    )
}

export default ImageSearch
