import { getJwtTokens } from "./auth"
import { compareVersions, devlog } from "./misc"
import { checkSession } from "./noLoginSessions"

//TODO: Typify all responses

//////////////////////////////////////////
// HELPERFUNCTIONS
//////////////////////////////////////////

const retries = 2

// fetch with retries and token refresh
export const fetchPlus = async (url: string, retries: number, options = {}): Promise<Response | void> => {
  try {
    const res = await fetch(url, options)

    const noRetryCodes = [400, 401, 403, 404, 409]

    if (res.ok || noRetryCodes.includes(res.status)) {
      return res
    }

    if (retries > 0) {
      return await fetchPlus(url, retries - 1, options)
    }

    return res
  } catch (error: any) {
    console.error(error.message)
  }
}




const makeRestCall = async (url: URL, method: "GET" | "POST" | "PUT" | "DELETE" | "PATCH", headers: object, body: object | null) => {

  let data = null
  let status = 500

  let options
  if (body !== null) options = { method: method, headers: headers, body: JSON.stringify(body) }
  else options = { method: method, headers: headers }

  try {
    const res = await fetchPlus(url.toString(), retries, options)
    if (res) {
      status = res.status
      try { data = await res.json() } catch (e) { console.log('Response has no content') }

      // check if we want a refresh
      const newestFeVersionAccordingToBe = res.headers.get('x-current-fe-version-known-to-api')
      if (newestFeVersionAccordingToBe && process.env.REACT_APP_VERSION) {
        devlog('BE says' + newestFeVersionAccordingToBe + ', FE says' + process.env.REACT_APP_VERSION)
        // 1=new version available, 0=up to date, -1=Fe has newer version than known by BE
        const compareResult = compareVersions(newestFeVersionAccordingToBe, process.env.REACT_APP_VERSION)
        if (compareResult === 1) window.location.reload()
      }

    }
  } catch (e) {
    console.log(e)
    return { status: 500 }
  }

  return { status: status, data: data }
}




const createHeaderAndURL = async () => {
  // create base url
  let urlPrefix = process.env.REACT_APP_STAGE === process.env.REACT_APP_NAME_OF_PROD_STAGE ? process.env.REACT_APP_API_DOMAIN : process.env.REACT_APP_API_HOST

  // get token
  const { idToken } = await getJwtTokens()

  // case user is logged in
  let auth = ""
  if (idToken !== undefined) {
    auth = "Bearer " + idToken
    urlPrefix += '/'
  }
  // if not we use a no login session
  else {
    const sessionId = await checkSession()
    if (sessionId !== undefined) {
      urlPrefix += '/sessions/' + sessionId + '/'
    }
    // or nothing if we don't have a session
    else {
      return { urlPrefix: undefined, headers: undefined }
    }
  }

  const headers = { 'content-type': 'application/json', 'x-api-key': process.env.REACT_APP_API_KEY, 'Authorization': auth, 'x-current-in-browser-fe-version': process.env.REACT_APP_VERSION }
  return { urlPrefix, headers }
}

export async function uploadFileUsingPresignedURL(presignedURL: string, file: File) {
  try {
    const res = await fetchPlus(presignedURL, retries, {
      method: 'PUT',
      body: file,
      headers: { 'Content-Type': file.type }
    })

    if (res) {
      return { status: res.status }
    } else {
      return { status: 500 }
    }
  } catch (error) {
    return { status: 500 }
  }
}

//////////////////////////////////////////
// SLIDES
//////////////////////////////////////////

// get slide
export const getSlideCall = async (presentationId: string, slideId: string, renderingOptions: RenderOptions | null) => {

  const { urlPrefix, headers } = await createHeaderAndURL()
  if (urlPrefix === undefined || headers === undefined) return { status: 500 }

  const url = new URL(urlPrefix + 'presentations/' + presentationId + '/slides/' + slideId)

  if (renderingOptions != null) {
    url.searchParams.append("render", "true")
    url.searchParams.append("format", renderingOptions.format)
    url.searchParams.append("speed", String(renderingOptions.speed))
    url.searchParams.append("quality", String(renderingOptions.quality))
    url.searchParams.append("lossless", String(renderingOptions.lossless))
  }

  const { data, status } = await makeRestCall(url, "GET", headers, null)

  if (status === 200 && data) {
    return { ...data, status: status }
  } else return { status: status }
}



// get slides
export const getSlidesCall = async (presentationId: string) => {

  const { urlPrefix, headers } = await createHeaderAndURL()
  if (urlPrefix === undefined || headers === undefined) return { status: 500 }

  const url = new URL(urlPrefix + 'presentations/' + presentationId + '/slides')

  const { data, status } = await makeRestCall(url, "GET", headers, null)

  if (status === 200 && data) {
    return { slideArray: data, status: status }
  } else return { status: status }
}


// get slide pptx
export const getSlideFileCall = async (presentationId: string, slideId: string) => {

  const { urlPrefix, headers } = await createHeaderAndURL()
  if (urlPrefix === undefined || headers === undefined) return { status: 500 }

  const url = new URL(urlPrefix + 'presentations/' + presentationId + '/slides/' + slideId + '/file')

  const { data, status } = await makeRestCall(url, "GET", headers, null)

  if (status === 200 && data && data.pptxBase64) {
    return { pptxBase64: data.pptxBase64, status: status }
  } else return { status: status }
}

// get presentation pptx
export const getPresentationFileCall = async (presentationId: string) => {

  const { urlPrefix, headers } = await createHeaderAndURL()
  if (urlPrefix === undefined || headers === undefined) return { status: 500 }

  const url = new URL(urlPrefix + 'presentations/' + presentationId + '/slides/file')

  const { data, status } = await makeRestCall(url, "GET", headers, null)

  if (status === 200 && data && data.pptxBase64) {
    return { pptxBase64: data.pptxBase64, status: status }
  } else return { status: status }
}


// post/put slide
type WriteSlideResponse = { status: number, logicalSlide?: LogicalSlide, slideRenderDetail?: SlideRenderDetail }
export const writeSlideCall = async (logicalSlide: LogicalSlide | LogicalSlideInit, renderingOptions: RenderOptions | null, method: "PUT" | "POST", position: number | undefined = undefined, autoAlign: boolean | undefined = undefined): Promise<WriteSlideResponse> => {

  const { urlPrefix, headers } = await createHeaderAndURL()
  if (urlPrefix === undefined || headers === undefined) return { status: 500 }

  let url
  if (method === "PUT") url = new URL(urlPrefix + 'presentations/' + logicalSlide.presentationId + '/slides/' + logicalSlide.slideId)
  else url = new URL(urlPrefix + 'presentations/' + logicalSlide.presentationId + '/slides')
  if (position !== undefined) url.searchParams.append("position", String(position))

  if (renderingOptions != null) {
    url.searchParams.append("render", "true")
    url.searchParams.append("format", renderingOptions.format)
    url.searchParams.append("speed", String(renderingOptions.speed))
    url.searchParams.append("quality", String(renderingOptions.quality))
    url.searchParams.append("lossless", String(renderingOptions.lossless))
  }
  if (autoAlign) {
    url.searchParams.append("auto_align", "true")
  }

  const { data, status } = await makeRestCall(url, method, headers, logicalSlide)

  if (status === 200 && data) {
    return { ...data, status: status }
  } else return { status: status }
}

// post slide aiAssist
type CreateSlideAiAssistResponse = { status: number, logicalSlide?: LogicalSlide }
export const createSlideAiAssistCall = async (presentationId: string, userPrompt: string, position: number | undefined = undefined, autoAlign: boolean | undefined = undefined): Promise<CreateSlideAiAssistResponse> => {

  const { urlPrefix, headers } = await createHeaderAndURL()
  if (urlPrefix === undefined || headers === undefined) return { status: 500 }

  let url
  url = new URL(urlPrefix + 'presentations/' + presentationId + '/slides/aiassist')
  if (position !== undefined) url.searchParams.append("position", String(position))

  if (autoAlign) {
    url.searchParams.append("auto_align", "true")
  }

  const { data, status } = await makeRestCall(url, "POST", headers, { userPrompt: userPrompt })

  if (status === 200 && data) {
    return { ...data, status: status }
  } else return { status: status }
}


// delete slide
export const deleteSlideCall = async (presentationId: string, slideId: string, permanent: boolean = false) => {

  const { urlPrefix, headers } = await createHeaderAndURL()
  if (urlPrefix === undefined || headers === undefined) return { status: 500 }

  const url = new URL(urlPrefix + 'presentations/' + presentationId + '/slides/' + slideId)
  if (permanent) url.searchParams.append("permanent", "true")

  const { data, status } = await makeRestCall(url, "DELETE", headers, null)

  if (status === 200 && data) {
    return { ...data, status: status }
  } else return { status: status }
}

// Patch slide
type PatchSlideResponse = { status: number, logicalSlide?: LogicalSlide }
export const patchSlideCall = async (presentationId: string, slideId: string, patchPayload: LogicalSlidePatchPayload, position: number | undefined): Promise<PatchSlideResponse> => {
  const { urlPrefix, headers } = await createHeaderAndURL()
  if (urlPrefix === undefined || headers === undefined) return { status: 500 }

  const url = new URL(urlPrefix + 'presentations/' + presentationId + '/slides/' + slideId)
  if (position !== undefined) url.searchParams.append("position", String(position))

  const { data, status } = await makeRestCall(url, "PATCH", headers, patchPayload)

  if (status === 200 && data != null) {
    const logicalSlide: LogicalSlide = data
    return { logicalSlide, status: status }
  } else return { status: status }
}


//////////////////////////////////////////
// PRESENTATIONS
//////////////////////////////////////////


// get Presentations
export const getPresentationsCall = async () => {
  const { urlPrefix, headers } = await createHeaderAndURL()
  if (urlPrefix === undefined || headers === undefined) return { status: 500 }

  const url = new URL(urlPrefix + "presentations")

  const { data, status } = await makeRestCall(url, "GET", headers, null)

  if (status === 200 && data != null) {
    const presentations: Presentations = data.reduce(function (recurring: Presentations, value: SinglePresentation) { recurring[value.presentationId!] = value; return recurring }, {})
    return { presentations, status: status }
  } else return { status: status }
}




// get Presentation
export const getPresentationCall = async (presentationId: string) => {
  const { urlPrefix, headers } = await createHeaderAndURL()
  if (urlPrefix === undefined || headers === undefined) return { status: 500 }

  const url = new URL(urlPrefix + "presentations/" + presentationId)

  const { data, status } = await makeRestCall(url, "GET", headers, null)

  if (status === 200 && data) {
    return { singlePresentation: data, status: status }
  } else return { status: status }
}

// get Presentation ai outline
type GetPresentationAiOutlineResponse = { status: number, presentationOutline?: { slideTitle: string, slideContentRequest: string }[] }
export const postPresentationAiOutlineCall = async (userPrompt: string): Promise<GetPresentationAiOutlineResponse> => {
  const { urlPrefix, headers } = await createHeaderAndURL()
  if (urlPrefix === undefined || headers === undefined) return { status: 500 }

  const url = new URL(urlPrefix + "presentations/outline/aiassist")

  const { data, status } = await makeRestCall(url, "POST", headers, { userPrompt: userPrompt })

  if (status === 200 && data) {
    return { presentationOutline: data, status: status }
  } else return { status: status }
}

// post Presentation
export const postPresentationCall = async (presentation: SinglePresentationInit | SinglePresentation) => {
  const { urlPrefix, headers } = await createHeaderAndURL()
  if (urlPrefix === undefined || headers === undefined) return { status: 500 }

  let url
  url = new URL(urlPrefix + "presentations")

  const { data, status } = await makeRestCall(url, "POST", headers, presentation)

  if (status === 200 && data) {
    return { singlePresentation: data as SinglePresentation, status: status }
  } else return { status: status }
}

// put Presentation
export const putPresentationCall = async (presentation: SinglePresentation, skipVersionCheck: boolean = false) => {
  const { urlPrefix, headers } = await createHeaderAndURL()
  if (urlPrefix === undefined || headers === undefined) return { status: 500 }

  let url
  url = new URL(urlPrefix + "presentations/" + presentation.presentationId)
  if (skipVersionCheck) url.searchParams.append("skipversioncheck", "true")

  const { data, status } = await makeRestCall(url, "PUT", headers, presentation)

  if (status === 200 && data) {
    return { singlePresentation: data as SinglePresentation, status: status }
  } else return { status: status }
}

// delete presentation
export const deletePresentationCall = async (presentationId: string, permanent: boolean = false) => {

  const { urlPrefix, headers } = await createHeaderAndURL()
  if (urlPrefix === undefined || headers === undefined) return { status: 500 }

  let url = new URL(urlPrefix + 'presentations/' + presentationId)
  if (permanent) url.searchParams.append("permanent", "true")

  const { data, status } = await makeRestCall(url, "DELETE", headers, null)

  if (status === 200 && data) {
    return { ...data, status: status }
  } else return { status: status }
}

// Patch presentation
type PatchPresentationResponse = { status: number, singlePresentation?: SinglePresentation }
export const patchPresentationCall = async (presentationId: string, patchPayload: LogicalPresentationPatchPayload): Promise<PatchPresentationResponse> => {
  const { urlPrefix, headers } = await createHeaderAndURL()
  if (urlPrefix === undefined || headers === undefined) return { status: 500 }

  const url = new URL(urlPrefix + 'presentations/' + presentationId)

  const { data, status } = await makeRestCall(url, "PATCH", headers, patchPayload)

  if (status === 200 && data != null) {
    const singlePresentation: SinglePresentation = data
    return { singlePresentation, status: status }
  } else return { status: status }
}

// init from common
export const initFromCommonCall = async () => {
  const { urlPrefix, headers } = await createHeaderAndURL()
  if (urlPrefix === undefined || headers === undefined) return { status: 500 }

  let url
  url = new URL(urlPrefix + "presentations/initfromcommon")

  const { status } = await makeRestCall(url, "POST", headers, {})

  return { status: status }
}


//////////////////////////////////////////
// MASTERS
//////////////////////////////////////////


// get Masters
type getMastersCallResponse = { status: number, masters?: Record<string, SingleMaster> }
export const getMastersCall = async (): Promise<getMastersCallResponse> => {
  const { urlPrefix, headers } = await createHeaderAndURL()
  if (urlPrefix === undefined || headers === undefined) return { status: 500 }

  let url = new URL(urlPrefix + "masters")
  url.searchParams.append("get-renderings", "true")

  const { data, status } = await makeRestCall(url, "GET", headers, null)

  if (status === 200 && data != null) {
    const masters: Masters = data.reduce(function (recurring: Masters, value: SingleMaster) { recurring[value.logicalMaster.masterId] = value; return recurring }, {})
    return { masters, status: status }
  } else return { status: status }
}


// get Master
export const getMasterCall = async (masterId: string) => {
  const { urlPrefix, headers } = await createHeaderAndURL()
  if (urlPrefix === undefined || headers === undefined) return { status: 500 }

  let url = new URL(urlPrefix + "masters/" + masterId)
  url.searchParams.append("get-renderings", "true")

  const { data, status } = await makeRestCall(url, "GET", headers, null)

  if (status === 200 && data != null) {
    return { data, status: status }
  } else return { status: status }
}


// post new Master from file
type PostMasterFileCallResponse = { status: number, masters?: SingleMaster[] }
export const postMasterFileCall = async (master: LogicalMasterInit, getRenderings: boolean): Promise<PostMasterFileCallResponse> => {
  const { urlPrefix, headers } = await createHeaderAndURL()
  if (urlPrefix === undefined || headers === undefined) return { status: 500 }

  let url = new URL(urlPrefix + "masters")
  if (getRenderings) url.searchParams.append("get-renderings", "true")

  const { data, status } = await makeRestCall(url, "POST", headers, master)

  if (status === 200 && data != null) {
    const masters: SingleMaster[] = data
    return { masters, status: status }
  } else return { status: status }
}

// get upload url for new master
type PostMasterCallResponse = { status: number, data?: { uploadUrl: string, masterId: string } }
export const postMasterCall = async (filename: string): Promise<PostMasterCallResponse> => {
  const { urlPrefix, headers } = await createHeaderAndURL()
  if (urlPrefix === undefined || headers === undefined) return { status: 500 }

  let url = new URL(urlPrefix + "masters")

  const { data, status } = await makeRestCall(url, "POST", headers, { filename: filename })

  if (status === 200 && data != null) {
    return { data, status: status }
  } else return { status: status }
}

// create master from template
type PostMasterFromTemplateCallResponse = { status: number, data?: { masterId: string } }
export const postMasterFromTemplateCall = async (masterName: string, masterTemplateType: MasterTemplateType, primaryColorHex: string): Promise<PostMasterFromTemplateCallResponse> => {
  const { urlPrefix, headers } = await createHeaderAndURL()
  if (urlPrefix === undefined || headers === undefined) return { status: 500 }

  let url = new URL(urlPrefix + "masters/fromtemplate")

  const body = { masterName: masterName, masterTemplateType: masterTemplateType, primaryColorHex: primaryColorHex }
  const { data, status } = await makeRestCall(url, "POST", headers, body)

  if (status === 200 && data != null) {
    return { data, status: status }
  } else return { status: status }
}


// delete master
export const deleteMasterCall = async (masterId: string, permanent: boolean = false) => {

  const { urlPrefix, headers } = await createHeaderAndURL()
  if (urlPrefix === undefined || headers === undefined) return { status: 500 }

  let url = new URL(urlPrefix + 'masters/' + masterId)
  if (permanent) url.searchParams.append("permanent", "true")

  const { data, status } = await makeRestCall(url, "DELETE", headers, null)

  if (status === 200 && data) {
    return { ...data, status: status }
  } else return { status: status }
}

// Patch master
type PatchMasterResponse = { status: number, singleMaster?: SingleMaster }
export const patchMasterCall = async (masterId: string, patchPayload: MasterPatchPayload): Promise<PatchMasterResponse> => {
  const { urlPrefix, headers } = await createHeaderAndURL()
  if (urlPrefix === undefined || headers === undefined) return { status: 500 }

  let url = new URL(urlPrefix + "masters/" + masterId)
  url.searchParams.append("get-renderings", "true")

  const { data, status } = await makeRestCall(url, "PATCH", headers, patchPayload)

  if (status === 200 && data != null) {
    const singleMaster: SingleMaster = data
    return { singleMaster, status: status }
  } else return { status: status }
}

//////////////////////////////////////////
// ICONS
//////////////////////////////////////////

// search icons
export const searchIconsCall = async (query: string): Promise<{ data?: null | IconSourceType[], status: number }> => {

  const { urlPrefix, headers } = await createHeaderAndURL()
  if (urlPrefix === undefined || headers === undefined) return { status: 500 }

  const url = new URL(urlPrefix + 'icons')
  url.searchParams.append("query", query)

  const { data, status } = await makeRestCall(url, "GET", headers, null)

  if (status === 200 && data) {
    return { data, status: status }
  } else return { status: status }
}

//////////////////////////////////////////
// IMAGES
//////////////////////////////////////////

// search images
export const searchImagesCall = async (query: string, page: number = 1, per_page: number = 10): Promise<{ data?: null | UnsplashSearchResponse, status: number }> => {

  const { urlPrefix, headers } = await createHeaderAndURL()
  if (urlPrefix === undefined || headers === undefined) return { status: 500 }

  const url = new URL(urlPrefix + 'images')
  url.searchParams.append("query", query)
  url.searchParams.append("page", String(page))
  url.searchParams.append("per_page", String(per_page))

  const { data, status } = await makeRestCall(url, "GET", headers, null)

  if (status === 200 && data) {
    return { data, status: status }
  } else return { status: status }
}

// get upload url for new image
type ImageUploadUrlCallResponse = { status: number, data?: { uploadUrl: string, imageId: string } }
export const getImageUploadUrlCall = async (): Promise<ImageUploadUrlCallResponse> => {
  const { urlPrefix, headers } = await createHeaderAndURL()
  if (urlPrefix === undefined || headers === undefined) return { status: 500 }

  let url = new URL(urlPrefix + "images/upload-url")

  const { data, status } = await makeRestCall(url, "GET", headers, null)

  if (status === 200 && data != null) {
    return { data, status: status }
  } else return { status: status }
}

//////////////////////////////////////////
// SUBSCRIPTIONS
//////////////////////////////////////////


// get Billing plan
export const getBillingPlanCall = async (planId: string) => {
  const { urlPrefix, headers } = await createHeaderAndURL()
  if (urlPrefix === undefined || headers === undefined || headers.Authorization === '') return { status: 500 }

  const url = new URL(urlPrefix + "payment/paypal/plans/" + planId)

  const { data, status } = await makeRestCall(url, "GET", headers, null)

  if (status === 200 && data) {
    return { billingPlan: data as SubscriptionOptionSpec, status: status }
  } else return { status: status }
}

// cancel user's subscription
export const cancelSubscriptionCall = async () => {
  const { urlPrefix, headers } = await createHeaderAndURL()
  if (urlPrefix === undefined || headers === undefined || headers.Authorization === '') return { status: 500 }

  const url = new URL(urlPrefix + "payment/paypal/subscriptions/cancel")

  const { status } = await makeRestCall(url, "POST", headers, {})

  return { status: status }
}

//////////////////////////////////////////
// SESSIONS
//////////////////////////////////////////

// post Session
export const postSessionCall = async () => {
  const headers = { 'content-type': 'application/json', 'x-api-key': process.env.REACT_APP_API_KEY }

  const url = new URL(process.env.REACT_APP_API_HOST + "/sessions")

  const { data, status } = await makeRestCall(url, "POST", headers, {})

  if (status === 200 && data) {
    return { sessionData: data as SessionDataType, status: status }
  } else return { status: status }
}