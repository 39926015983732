import RelRnd from '.././RelRnd'
import { useState } from "react"
import { Box, Tooltip, ToggleButton, ClickAwayListener } from '@mui/material'
import { Descendant } from 'slate'
import _ from 'lodash'
import { menuIconStyle, StyledToggleButtonGroup } from '../../../lib/style'
import { RiInsertColumnLeft, RiInsertColumnRight, RiInsertRowTop, RiInsertRowBottom, RiDeleteColumn, RiDeleteRow } from 'react-icons/ri'
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon'
import IconSettings from '../../elements/IconSettings'
import RichTextPopover from '../../text/RichTextPopover'
import NoDragPopover from '../../helpers/NoDragPopover'
import { tableCellDefaultIconSettings } from '../ElementDefinitions'
import IconBoxWithSettings from '../../helpers/IconBoxWithSettings'
import { MdShortText } from 'react-icons/md'
import ElementTitle from '../ElementTitle'
import { BsBodyText } from 'react-icons/bs'
import ControlOverlay from '../../helpers/ControlOverlay'

type IconGrid1Props = {
  el: LogicalElement, relRndProps: BasicRelRndProps, elementActive: boolean, elementSelected: boolean,
  updateElementContent: ElementContentChangeFunction
}
type CellIndex = { rIdx: number, cIdx: number } | undefined

function IconGrid1({ el, relRndProps, elementActive, elementSelected, updateElementContent }: Readonly<IconGrid1Props>) {

  // localState
  const [globalIconSettingsPopoverAnchorEl, setGlobalIconSettingsPopoverAnchorEl] = useState<EventTarget & HTMLElement | null>(null)
  const [activeItem, setActiveItem] = useState<CellIndex>({ rIdx: 0, cIdx: 0 })

  // props
  const elementContent = el.content! as IconGrid1Content
  const rows = elementContent.rows
  const dimensions = { rIdx: elementContent.rows.length - 1, cIdx: rows[0].length - 1 }

  // functions

  const handleHeaderTextChange = (newText: Descendant[], idx: CellIndex) => {
    if (elementContent && relRndProps.currentSlideId && idx && !_.isEqual(elementContent.rows[idx.rIdx][idx.cIdx].header.richText, newText)) {
      let newContent = _.cloneDeep(elementContent)
      newContent.rows[idx.rIdx][idx.cIdx].header = { ...newContent.rows[idx.rIdx][idx.cIdx].header, richText: newText }
      updateElementContent(relRndProps.currentSlideId, el.elementId, newContent, "IMMEDIATE_ELEMENT_CONTENT_TYPING")
    }
  }

  const handleBodyTextChange = (newText: Descendant[], idx: CellIndex) => {
    if (elementContent && relRndProps.currentSlideId && idx && !_.isEqual(elementContent.rows[idx.rIdx][idx.cIdx].body.richText, newText)) {
      let newContent = _.cloneDeep(elementContent)
      newContent.rows[idx.rIdx][idx.cIdx].body = { ...newContent.rows[idx.rIdx][idx.cIdx].body, richText: newText }
      updateElementContent(relRndProps.currentSlideId, el.elementId, newContent, "IMMEDIATE_ELEMENT_CONTENT_TYPING")
    }
  }

  const handleGlobalIconStateChange = (newIconSettings: TableCellIconSettings) => {
    if (elementContent && relRndProps.currentSlideId && (elementContent.iconPosition !== newIconSettings.position || elementContent.iconSize !== newIconSettings.size)) {
      let newContent = _.cloneDeep(elementContent)
      newContent.iconPosition = newIconSettings.position
      newContent.iconSize = newIconSettings.size
      if (elementContent.iconPosition !== newIconSettings.position) newContent.realignText = true
      updateElementContent(relRndProps.currentSlideId, el.elementId, newContent, "APPEARANCE_CHANGE")
    }
  }

  const handleSingleIconStateChange = (newIconSettings: TableCellIconSettings, idx: CellIndex) => {
    if (elementContent && relRndProps.currentSlideId && idx && !_.isEqual(elementContent.rows[idx.rIdx][idx.cIdx].iconSettings.iconSource, newIconSettings.iconSource)) {
      let newContent = _.cloneDeep(elementContent)
      newContent.rows[idx.rIdx][idx.cIdx].iconSettings.iconSource = newIconSettings.iconSource
      updateElementContent(relRndProps.currentSlideId, el.elementId, newContent, "APPEARANCE_CHANGE")
    }
  }

  function addRow(after: boolean) {
    if (relRndProps.currentSlideId && elementContent) {
      const newRowIndex = after ? dimensions?.rIdx + 1 : dimensions?.rIdx
      const newRow: IconGridCell[] = Array(rows[dimensions?.rIdx].length)
        .fill(null)
        .map((cell, cIdx) => cell = { ...rows[dimensions.rIdx][cIdx] })

      const newTable = [...rows]
      newTable.splice(newRowIndex, 0, newRow)
      let newContent = { ...elementContent }
      newContent.rows = newTable
      updateElementContent(relRndProps.currentSlideId, el.elementId, newContent, "APPEARANCE_CHANGE")
    }
  }

  function deleteCurrentRow() {
    if (activeItem && relRndProps.currentSlideId && elementContent) {
      if (activeItem?.rIdx >= 0 && activeItem?.rIdx < rows.length && rows.length > 1) {
        const newTable = [...rows]
        newTable.splice(activeItem?.rIdx, 1) // Remove the current row
        let newContent = { ...elementContent }
        newContent.rows = newTable
        updateElementContent(relRndProps.currentSlideId, el.elementId, newContent, "APPEARANCE_CHANGE")
        if (activeItem?.rIdx >= newTable.length) {
          // Adjust the current active row index if needed
          setActiveItem({ rIdx: newTable.length - 1, cIdx: activeItem?.cIdx })
        }
      }
    }
  }

  function addColumn(after: boolean) {
    if (relRndProps.currentSlideId && elementContent) {
      const newTable = rows.map((row, rowIndex) => {
        const newRow = [...row]
        const newCell = { ...rows[rowIndex][dimensions.cIdx] }

        if (after) {
          newRow.splice(dimensions?.cIdx + 1, 0, newCell)
        } else {
          newRow.splice(dimensions?.cIdx, 0, newCell)
        }
        return newRow
      })
      let newContent = { ...elementContent }
      newContent.rows = newTable
      updateElementContent(relRndProps.currentSlideId, el.elementId, newContent, "APPEARANCE_CHANGE")
    }
  }

  function deleteCurrentColumn() {
    if (activeItem && relRndProps.currentSlideId && elementContent) {
      if (activeItem?.cIdx >= 0 && rows.length > 0 && rows[0].length > 1) {
        const newTable = rows.map((row) => {
          if (activeItem?.cIdx < row.length) {
            const newRow = [...row]
            newRow.splice(activeItem?.cIdx, 1) // Remove the current column
            return newRow
          } else {
            return row
          }
        })
        let newContent = { ...elementContent }
        newContent.rows = newTable
        updateElementContent(relRndProps.currentSlideId, el.elementId, newContent, "APPEARANCE_CHANGE")
      }
    }
  }

  function handleIconHeaderToggle() {
    if (relRndProps.currentSlideId && elementContent) {
      let newContent = { ...elementContent, showHeader: !elementContent.showHeader }
      updateElementContent(relRndProps.currentSlideId, el.elementId, newContent, "APPEARANCE_CHANGE")
    }
  }

  function handleIconBodyToggle() {
    if (relRndProps.currentSlideId && elementContent) {
      let newContent = { ...elementContent, showBody: !elementContent.showBody }
      updateElementContent(relRndProps.currentSlideId, el.elementId, newContent, "APPEARANCE_CHANGE")
    }
  }

  const elementSpecificPanel = (
    <StyledToggleButtonGroup size="small" arial-label="element specific options">
      <Tooltip title="Toggle text header">
        <ToggleButton selected={!!elementContent.showHeader} onClick={() => handleIconHeaderToggle()} color="primary" size="small" value="">
          <MdShortText fontSize="medium" />
        </ToggleButton>
      </Tooltip>
      <Tooltip title="Toggle text body">
        <ToggleButton selected={!!elementContent.showBody} onClick={() => handleIconBodyToggle()} color="primary" size="small" value="">
          <BsBodyText fontSize="medium" />
        </ToggleButton>
      </Tooltip>
      {/* <DropdownButton mainButtonIcon={<RiInsertColumnRight />} mainButtonTooltip='Add a row or a column' buttonHeight='25px'
        secondaryButtons={[
          { icon: <RiInsertColumnRight />, onClick: () => { addColumn(true) }, tooltip: 'Add column' },
          { icon: <RiInsertRowBottom />, onClick: () => { addRow(true) }, tooltip: 'Add row ' },
        ]} />
      <DropdownButton mainButtonIcon={<RiDeleteColumn />} mainButtonTooltip='Remove a row or a column' buttonHeight='25px'
        secondaryButtons={[
          { icon: <RiDeleteColumn />, onClick: () => { deleteCurrentColumn() }, tooltip: 'Remove current column' },
          { icon: <RiDeleteRow />, onClick: () => { deleteCurrentRow() }, tooltip: 'Remove row' },]} /> */}
      <Tooltip title="Icon settings">
        <ToggleButton selected={false} color="primary" onClick={(event) => setGlobalIconSettingsPopoverAnchorEl(event.currentTarget)} value="" id='text_box1_icon_button'>
          <InsertEmoticonIcon />
        </ToggleButton>
      </Tooltip>
    </StyledToggleButtonGroup>
  )


  return (
    <RelRnd relRndProps={relRndProps} el={el} elementSelected={elementSelected} elementActive={elementActive} elementSpecificPanel={elementSpecificPanel}>

      {/* the element title */}
      <ElementTitle el={el} relRndProps={relRndProps} />

      {elementContent.rows.map((row, rIdx: number) =>
        row.map((cell, cIdx: number) => (
          <Box key={String(rIdx) + String(cIdx) + JSON.stringify(cell.header.richText) + JSON.stringify(cell.body.richText)}
            sx={{ zIndex: activeItem?.cIdx === cIdx && activeItem.rIdx === rIdx ? 1 : 0 }}>
            <ClickAwayListener onClickAway={() => { }}>
              <Box onMouseEnter={() => setActiveItem({ rIdx: rIdx, cIdx: cIdx })} onClick={() => setActiveItem({ rIdx: rIdx, cIdx: cIdx })}>

                {/* the header text popover */}
                {cell.header.box && elementContent.showHeader &&
                  < RichTextPopover el={el} relRndProps={relRndProps} richTextBox={cell.header}
                    handleBlur={(newText: Descendant[]) => { handleHeaderTextChange(newText, { rIdx: rIdx, cIdx: cIdx }) }}
                    textRole={'HEADER1'}
                  />}

                {/* the body text popover */}
                {cell.body.box &&
                  < RichTextPopover el={el} relRndProps={relRndProps} richTextBox={cell.body}
                    handleBlur={(newText: Descendant[]) => { handleBodyTextChange(newText, { rIdx: rIdx, cIdx: cIdx }) }}
                    textRole={'BODY1'}
                  />}

                {/* the clickable icon box to open the single icon search settings */}
                <IconBoxWithSettings el={el} relRndProps={relRndProps} cell={cell} rIdx={rIdx} cIdx={cIdx}
                  handleSingleIconStateChange={handleSingleIconStateChange} />

                {/* Control Overlay */}
                {elementActive && _.isEqual(activeItem, { rIdx: rIdx, cIdx: cIdx }) && (
                  <ControlOverlay
                    el={el}
                    relRndProps={relRndProps}
                    box={{
                      top: Math.min(cell.header.box?.top || 1, cell.body.box?.top || 1, cell.iconSettings.box?.top || 1),
                      left: Math.min(cell.header.box?.left || 1, cell.body.box?.left || 1, cell.iconSettings.box?.left || 1),
                      height: Math.max((cell.header.box?.top || 0) + (cell.header.box?.height || 0), (cell.body.box?.top || 0) + (cell.body.box?.height || 0), (cell.iconSettings.box?.top || 0) + (cell.iconSettings.box?.height || 0)) - Math.min(cell.header.box?.top || 1, cell.body.box?.top || 1, cell.iconSettings.box?.top || 1),
                      width: Math.max((cell.header.box?.left || 0) + (cell.header.box?.width || 0), (cell.body.box?.left || 0) + (cell.body.box?.width || 0), (cell.iconSettings.box?.left || 0) + (cell.iconSettings.box?.width || 0)) - Math.min(cell.header.box?.left || 1, cell.body.box?.left || 1, cell.iconSettings.box?.left || 1),

                    }}
                    menu={{
                      position: 'TOPRIGHT' as PositionKey,
                      nestedMenuProps: {
                        tooltip: 'Item settings',
                        menuLevels: 2,
                        options: [
                          {
                            label: 'Insert row/column',
                            menuLevel: 0,
                            nestedOptions: [
                              { menuLevel: 1, label: 'Insert row above', onClick: () => addRow(false), icon: <RiInsertRowTop style={menuIconStyle} /> },
                              { menuLevel: 1, label: 'Insert row below', onClick: () => addRow(true), divider: true, icon: <RiInsertRowBottom style={menuIconStyle} /> },
                              { menuLevel: 1, label: 'Insert column left', onClick: () => addColumn(false), icon: <RiInsertColumnLeft style={menuIconStyle} /> },
                              { menuLevel: 1, label: 'Insert column right', onClick: () => addColumn(true), icon: <RiInsertColumnRight style={menuIconStyle} /> },
                            ]
                          },
                          {
                            label: 'Delete row/column',
                            menuLevel: 0,
                            nestedOptions: [
                              { menuLevel: 1, label: 'Delete row', onClick: () => deleteCurrentRow(), icon: <RiDeleteRow style={menuIconStyle} /> },
                              { menuLevel: 1, label: 'Delete column', onClick: () => deleteCurrentColumn(), icon: <RiDeleteColumn style={menuIconStyle} /> },
                            ]
                          },
                        ]
                      }
                    }}
                  />
                )}

              </Box>
            </ClickAwayListener>
          </Box>
        )))}

      {/* The popover with the global icon settings */}
      <NoDragPopover open={!!globalIconSettingsPopoverAnchorEl} anchorEl={globalIconSettingsPopoverAnchorEl} onClose={() => setGlobalIconSettingsPopoverAnchorEl(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} transformOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Box sx={{ overflow: 'hidden' }}>
          <IconSettings
            allowedPositions={['TOPCENTER', 'CENTERLEFT', 'BOTTOMCENTER', 'CENTERRIGHT']}
            allowedSizes={[{ displayName: 'tiny', size: 1 }, { displayName: 'very small', size: 1.5 }, { displayName: 'small', size: 2 },
            { displayName: 'medium', size: 3 }, { displayName: 'large', size: 4 }, { displayName: 'very large', size: 6 }]}
            currentIconState={{ ...tableCellDefaultIconSettings, size: elementContent.iconSize, position: elementContent.iconPosition, visible: true }}
            handleIconStateChange={handleGlobalIconStateChange} showSearch={false} />
        </Box>
      </NoDragPopover>

    </RelRnd>
  )
}

export default IconGrid1
