import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import HeaderToolbar from '../../containers/HeaderToolbar'
import { AmplifyUser } from '@aws-amplify/ui'
import ProductFeatures from '../ProductFeatures'
import { Palette, BlurOn, AutoAwesome, Apps } from '@mui/icons-material'
import CollectionsIcon from '@mui/icons-material/Collections'
import HeroText from './HeroText'
import { useEffect, useState } from 'react'
import SectionBackground from '../SectionBackground'
import { centeredFullWidthWrappingFlex } from '../../../lib/style'
import { hexToRgba } from '../../../lib/colors'
import AllInclusiveIcon from '@mui/icons-material/AllInclusive'
import { IconButton, TextField, Tooltip } from '@mui/material'
import { editorPath, securePagesPath } from '../../../lib/constants'
import { useNavigate } from 'react-router-dom'

type LandingProps = { currentUser: AmplifyUser | undefined }

//https://thefront.maccarianagency.com/
function Landing({ currentUser }: LandingProps) {
  const theme = useTheme()
  let navigate = useNavigate()
  const [heroHeight, setHeroHeight] = useState(window.innerHeight)
  const loggedIn = currentUser !== undefined

  //state
  const [promptContent, setPromptContent] = useState("")

  useEffect(() => {
    const handleResize = () => {
      setHeroHeight(window.innerHeight)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  function handlyHeroPromptClick() {
    if (loggedIn) {
      window.gtag("event", "select_content", { content_type: "cta_click_landing_aiprompt_logged_in" })
      navigate("/" + securePagesPath + '/' + editorPath, { state: { openAIPresiCreationDialog: true, promptFromLanding: promptContent } })
    }
    else {
      window.gtag("event", "select_content", { content_type: "cta_click_landing_aiprompt_trial" })
      navigate("/" + editorPath, { state: { openAIPresiCreationDialog: true, promptFromLanding: promptContent } })
    }
  }

  const sectionHeaderFontSize = 1.5
  const sectionSubtitleFontSize = 1.2
  const maxWidth = 1500
  const maxWidthHeader = 2000

  return (
    <Box sx={{ ...centeredFullWidthWrappingFlex, alignItems: 'flex-start', overflow: 'hidden', position: 'relative' }}>

      {/* Header */}
      <HeaderToolbar currentUser={currentUser} maxWidth={maxWidthHeader} extraHoriPadding={true}>{ }</HeaderToolbar>

      {/* Hero section */}
      <Box sx={{ ...centeredFullWidthWrappingFlex, display: 'grid', gridTemplateColumns: '1fr', justifyContent: 'center' }}>

        {/* Bg gradient */}
        <Box sx={{ ...centeredFullWidthWrappingFlex, height: 1, gridColumnStart: 1, gridRowStart: 1, zIndex: -2 }}>
          <SectionBackground />
        </Box>

        {/* Herocontent */}
        {/* Hero area shifting the lower countent out of the screen if screen height not too high but keeps a minimum height for the image*/}
        <Box sx={{
          ...centeredFullWidthWrappingFlex, gridColumnStart: 1, gridRowStart: 1, pl: { xs: 4, sm: 4, md: 8 }, pr: { xs: 4, sm: 4, md: 8 }, display: 'flex', maxWidth: maxWidth,
          minHeight: { xs: 'auto', sm: heroHeight < 1000 ? `max(calc(${heroHeight}px - ${theme.customStyling.headerHeight}px), 800px)` : 800 },
          mb: heroHeight < 1000 ? 0 : 16, ml: 'auto', mr: 'auto', position: 'relative'
        }}>

          {/* Herotext on the left */}
          <Box sx={{ ...centeredFullWidthWrappingFlex, mt: -12, width: { xs: '100%', sm: '100%', md: '55%' }, minHeight: 600, pr: { xs: 0, sm: 8 } }}>
            <HeroText currentUser={currentUser} />
          </Box>

          {/* Stuff on the right */}

          {/* The radial bg gradient */}
          <Box
            sx={{
              position: 'absolute', width: 1840, height: 940, borderRadius: '50%', left: 400, top: -100,
              display: { 'xs': 'flex', 'md': 'flex' }, zIndex: -1,
              backgroundImage: `radial-gradient(${hexToRgba(theme.palette.primary.main, 0.5)}, transparent 70%)`,
            }}
          />

          {/* The prompt window */}
          <Box sx={{
            width: { xs: '100%', sm: '100%', md: '45%' }, display: { xs: 'None', sm: 'flex', md: 'flex' }, mt: { xs: -12, sm: -40, md: 0 },
            flexWrap: 'nowrap', pl: { xs: 0, sm: 0, md: 4 }, pr: { xs: 0, sm: 0, md: 4 }, alignItems: 'center', justifyContent: 'center'
          }}>
            <TextField fullWidth value={promptContent} onChange={(e) => setPromptContent(e.target.value)}
              placeholder="Create a presentation with 4 slides about..."
              sx={{ flexGrow: 1, '& .MuiInputBase-root': { backgroundColor: theme.palette.background.default } }}
              disabled={false} />
            <Tooltip title="Create Presentation">
              <IconButton
                color="primary" aria-label="send" sx={{ ml: 0 }} disabled={false} size='large'
                onClick={() => handlyHeroPromptClick()}
              >
                <AutoAwesome fontSize='large' />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      </Box>

      {/* Features section */}
      <Box sx={{ ...centeredFullWidthWrappingFlex, maxWidth: maxWidth, pl: 3, pr: 3, mt: 8 }}>
        {/* Title */}
        <Box sx={{ ...centeredFullWidthWrappingFlex, maxWidth: 1000 }}>
          <Typography variant="h6" sx={{ width: 1, textAlign: 'center', fontSize: { xs: `${sectionHeaderFontSize * 0.8}em`, sm: `${sectionHeaderFontSize}em` } }}>
            Beautiful PowerPoint Slides Made Easy
          </Typography>
          <Typography variant="body1" color={theme.palette.text.secondary} sx={{ width: 1, textAlign: 'center', fontSize: { xs: `${sectionSubtitleFontSize * 0.8}em`, sm: `${sectionSubtitleFontSize}em` } }}>
            Create stunning presentations effortlessly. Let AI handle project overviews, key fact sheets, icon grids, enumerations, and more — with high-quality icons, images and infographics right at your fingertips.
          </Typography>
        </Box>

        {/* Features  */}
        <Box sx={{ width: 1, display: 'flex' }}>
          <ProductFeatures features={featuresList} />
        </Box>
      </Box>

    </Box >
  )
}

const iconSx = { width: '100%', height: '100% ' }

const featuresList = [
  {
    icon: <AutoAwesome sx={iconSx} />,
    subtitle: 'AI Slide Designer',
    text: 'Generate complete presentations or individual slides with our award-winning AI Slide Designer. Simply input your text, and let the AI do the rest.',
  },
  {
    icon: <Palette sx={iconSx} />,
    subtitle: 'Stay on Brand',
    text: "Easily stick to your company's or client's corporate design. Upload your PowerPoint Theme and create with precision and consistency.",
  },
  {
    icon: <Apps sx={iconSx} />,
    subtitle: 'Powerful & Flexible Components',
    text: "Focus on your content while Protoslides takes care of the details. Our flexible components handle alignment, spacing, and design for you.",
  },
  {
    icon: <CollectionsIcon sx={iconSx} />,
    subtitle: 'Extensive Media Library',
    text: "Elevate your slides with access to millions of premium icons and high-quality images, ensuring a professional look every time.",
  },
  {
    icon: <AllInclusiveIcon sx={iconSx} />,
    subtitle: 'Unlimited AI Usage',
    text: "No limits, no hidden fees. Enjoy unrestricted use of our AI features — excluding automated bots and account sharing, of course.",
  },
  {
    icon: <BlurOn sx={iconSx} />,
    subtitle: 'Blurred Backgrounds',
    text: "Add a polished touch to your layouts with iOS-style background blur for slides featuring background images. ",
  }
]

export default Landing
