import RelRnd from '../RelRnd'
import { StyledToggleButtonGroup } from '../../../lib/style'
import { useEffect, useState } from 'react'
import ElementTitle from '../ElementTitle'
import { ToggleButton, Tooltip } from '@mui/material'
import { ImageSearchOutlined } from '@mui/icons-material'
import _ from 'lodash'
import ImageDisplay from '../../helpers/ImageDisplay'
import ImageInputTabs from '../../helpers/ImageInputTabs'
import SimpleCloseDialog from '../../helpers/SimpleCloseDialog'
import FormatColorFillIcon from '@mui/icons-material/FormatColorFill'


type Image1Props = {
  el: LogicalElement, relRndProps: BasicRelRndProps, elementActive: boolean, elementSelected: boolean,
  updateElementContent: ElementContentChangeFunction
}

function Image1({ el, relRndProps, elementActive, elementSelected, updateElementContent }: Readonly<Image1Props>) {

  // local state
  const [imageChangeDialogueOpen, setImageChangeDialogueOpen] = useState<boolean>(false)
  const [disableResize, setDisableResize] = useState(false)

  // shortcuts
  const elementContent = el.content! as Image1Content


  const elementSpecificPanel = (
    <StyledToggleButtonGroup size="small" arial-label="element specific options">
      <Tooltip title="Change image">
        <ToggleButton selected={false} color="primary" onClick={() => setImageChangeDialogueOpen(true)} value="">
          <ImageSearchOutlined />
        </ToggleButton>
      </Tooltip>
      <Tooltip title="Toggle imgage color filter">
        <ToggleButton selected={elementContent.imageSettings?.colorizeType === 'SOFT'} color="primary" onClick={() => handleColorizeToggle()} value="">
          <FormatColorFillIcon />
        </ToggleButton>
      </Tooltip>
    </StyledToggleButtonGroup>
  )

  const handleNewImageSelection = (id: string, provider: ImageProvider, providerCustomValue: string | undefined) => {
    if (elementContent && relRndProps.currentSlideId && !_.isEqual(elementContent.imageSettings?.id, id)) {
      let newContent: Image1Content = { imageSettings: { id: id, provider: provider, providerCustomValue: providerCustomValue } }
      updateElementContent(relRndProps.currentSlideId, el.elementId, newContent, "IMMEDIATE_ELEMENT_CONTENT_TYPING")
    }
    setImageChangeDialogueOpen(false)
  }

  const handleSettingsChange = (newImageSettings: ImageSettings) => {
    if (elementContent && relRndProps.currentSlideId && !_.isEqual(elementContent.imageSettings, newImageSettings)) {
      let newContent: Image1Content = { imageSettings: newImageSettings }
      updateElementContent(relRndProps.currentSlideId, el.elementId, newContent, "IMMEDIATE_ELEMENT_CONTENT_TYPING")
    }
  }

  const handleColorizeToggle = () => {
    if (elementContent.imageSettings && relRndProps.currentSlideId) {
      let newColorize: ColorizeType = 'NONE'
      if (elementContent.imageSettings?.colorizeType === 'NONE') newColorize = 'SOFT'
      if (elementContent.imageSettings?.colorizeType === undefined) newColorize = 'SOFT'
      if (elementContent.imageSettings?.colorizeType === 'SOFT') newColorize = 'NONE'
      let newContent: Image1Content = { imageSettings: { ...elementContent.imageSettings, colorizeType: newColorize } }
      updateElementContent(relRndProps.currentSlideId, el.elementId, newContent, "IMMEDIATE_ELEMENT_CONTENT_TYPING")
    }
  }

  // Start up hook opening the image selection dialogue if no image is selected yet
  useEffect(() => {
    if (!elementContent.imageSettings?.id && elementActive) setImageChangeDialogueOpen(true)
  }, [elementActive, elementContent])

  return (
    <RelRnd relRndProps={relRndProps} el={el} elementSelected={elementSelected} elementActive={elementActive} elementSpecificPanel={elementSpecificPanel} disableResize={disableResize}>

      {/* the element title */}
      <ElementTitle el={el} relRndProps={relRndProps} />

      {elementContent.imageSettings && (
        <ImageDisplay
          el={el}
          relRndProps={relRndProps}
          imageSettings={elementContent.imageSettings}
          handleSettingsChange={handleSettingsChange}
          onCropToggle={(cropVisible) => setDisableResize(cropVisible)}
        />
      )}

      <SimpleCloseDialog open={!!imageChangeDialogueOpen} handleClose={() => setImageChangeDialogueOpen(false)}
        title='Media' buttonCaption='Close'>
        <ImageInputTabs handleNewImageSelection={handleNewImageSelection} />
      </SimpleCloseDialog>

    </RelRnd>
  )
}

export default Image1
