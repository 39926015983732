import RelRnd from '../RelRnd'
import Box from '@mui/material/Box'
import { StyledToggleButtonGroup } from '../../../lib/style'
import { useState } from 'react'
import ElementTitle from '../ElementTitle'


type Frame1Props = {
  el: LogicalElement, relRndProps: BasicRelRndProps, elementActive: boolean, elementSelected: boolean,
  updateElementContent: ElementContentChangeFunction
}

function Frame1({ el, relRndProps, elementActive, elementSelected, updateElementContent }: Readonly<Frame1Props>) {

  // shortcuts
  const elementContent = el.content! as Frame1Content

  const elementSpecificPanel = (
    <StyledToggleButtonGroup size="small" arial-label="element specific options">

    </StyledToggleButtonGroup>
  )

  return (
    <RelRnd relRndProps={{ ...relRndProps, handleUserPromptChange: undefined }} el={el} elementSelected={elementSelected} elementActive={elementActive} elementSpecificPanel={elementSpecificPanel}>

      {/* the element title */}
      <ElementTitle el={el} relRndProps={relRndProps} />

    </RelRnd>
  )
}

export default Frame1
