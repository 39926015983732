import { Stack, Typography } from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

type InfoTextProps = {
    text: string
}

const InfoText = ({ text }: Readonly<InfoTextProps>) => (
    <Stack direction="row" alignItems="center" spacing={1}>
        <InfoOutlinedIcon color="info" />
        <Typography variant="body2" color="textSecondary">
            {text}
        </Typography>
    </Stack>
)

export default InfoText