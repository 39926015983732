import { useState } from 'react'
import Box from '@mui/material/Box'
import MiniSlide from '../helpers/MiniSlide'
import MasterDetail from './MasterDetail'
import FileDropzone from '../helpers/FileDropzone'
import Fade from '@mui/material/Fade'
import { Paper, Typography, useTheme } from '@mui/material'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import NewThemeCreateScreen from './NewThemeCreateScreen'
import InfoText from '../helpers/InfoText'


type MastersProps = {
  handleMasterClick?: IdClickFunction,
  handleNewMasterFile?: (file: File) => void,
  handleMasterDelete?: IdClickFunction,
  masters: Masters, currentMasterId?: string,
  mastersInAnalysis: MasterAnalysisLoadingStateType[],
  handleDefaultLayoutChange?: (masterId: string, defaultLayout: number) => void,
  handleMasterMainColChange?: (masterId: string, primXmlKey: string | null, neuXmlKey: string | null) => void,
  handleNewMasterFromTemplate?: NewMasterFromTemplateFunction
}

function Masters({ handleMasterClick, handleNewMasterFile, handleMasterDelete, masters, currentMasterId, mastersInAnalysis,
  handleDefaultLayoutChange, handleMasterMainColChange, handleNewMasterFromTemplate }: MastersProps) {
  const theme = useTheme()

  // local state
  const [detailsVisible, setDetailsVisible] = useState(false)
  const [newThemeVisible, setNewThemeVisible] = useState(false)
  const [clickedMasterId, setClickedMasterId] = useState<undefined | string>(undefined)
  const tileWidth = '200px'

  // Activating the detail view for a master
  const handleMasterDetailActivation = (masterId: string) => {
    setDetailsVisible(true)
    setClickedMasterId(masterId)
  }

  // Activating the detail view for a master
  const handleMasterDeleteClick = (masterId: string | undefined) => {
    setDetailsVisible(false)
    if (handleMasterDelete) handleMasterDelete(masterId)
  }

  const masterArray = (Object.values(masters) as Array<SingleMaster>)

  const newThemeButtonProps = {
    width: tileWidth,
    m: 1,
    cursor: "pointer",
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    textAlign: 'center',
    padding: '15px',
    color: theme.palette.text.disabled,
    transition: 'all 0.3s ease-in-out',
    opacity: 0.8,
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
      color: theme.palette.primary.main,
      opacity: 1,
    }
  }

  const addIconProps = {
    color: 'inherit'
  }

  return (
    <Box sx={{ width: 1, display: 'grid', gridTemplateColumns: '1fr' }}>

      {/* masters */}
      <Box sx={{ width: 1, gridColumnStart: 1, gridRowStart: 1, pointerEvents: (!detailsVisible && !newThemeVisible) ? "auto" : "none" }}>
        <Fade in={!detailsVisible && !newThemeVisible}>
          <Box sx={{ width: 1, display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap' }}>

            {/* The upload tile */}
            {handleNewMasterFile &&
              <Box sx={{ width: tileWidth, m: 1 }} key={String(-2)} >
                <FileDropzone handleFile={handleNewMasterFile} accept={{ 'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'] }}
                  text="Upload pptx - File" />
              </Box>
            }

            {/* The create from template tile */}
            {handleNewMasterFromTemplate &&
              <Paper elevation={1} sx={newThemeButtonProps} key={String(-1)} onClick={(event) => { setNewThemeVisible(true) }}>
                <Typography variant='h6' color='inherit' sx={{ width: 1, display: 'flex', justifyContent: 'center' }}>
                  Create New Theme
                </Typography>
                <Box sx={{ width: 1, justifyContent: 'center', display: 'flex' }}>
                  <AddCircleIcon fontSize="large" sx={addIconProps} />
                </Box >
              </Paper>
            }

            {masters && masterArray.map((master: SingleMaster, i: number) => (
              <Box sx={{ width: tileWidth, m: 1 }} key={master.logicalMaster.masterId}>
                <MiniSlide
                  onClickFunction={handleMasterClick ? () => handleMasterClick(master.logicalMaster.masterId) : () => handleMasterDetailActivation(master.logicalMaster.masterId)}
                  title={master.logicalMaster.name}
                  thisId={master.logicalMaster.masterId}
                  selectedId={currentMasterId}
                  renderDetail={(master.logicalMaster.defaultLayout !== undefined && master.logicalMaster.defaultLayout < master.layoutRenderDetail.length) ?
                    master.layoutRenderDetail[master.logicalMaster.defaultLayout] : master.layoutRenderDetail[0]}
                />
              </Box>
            ))}

            {mastersInAnalysis.map((masterInAnalysis: MasterAnalysisLoadingStateType, i: number) => (
              <Box sx={{ width: tileWidth, m: 1 }} key={masterInAnalysis.masterId}>
                <MiniSlide
                  title={masterInAnalysis.filename}
                />
              </Box>
            ))}
          </Box>
        </Fade>
      </Box>

      {/* details with layouts & delete */}
      <Box sx={{ width: 1, gridColumnStart: 1, gridRowStart: 1, pointerEvents: (detailsVisible) ? "auto" : "none" }}>
        <Fade in={detailsVisible} >
          <div>
            <MasterDetail masters={masters} handleMasterDetailClose={() => setDetailsVisible(false)} currentMasterId={clickedMasterId}
              handleMasterDelete={handleMasterDeleteClick} handleDefaultLayoutChange={handleDefaultLayoutChange} handleMasterMainColChange={handleMasterMainColChange} />
          </div>
        </Fade>
      </Box>

      {/* Create new Theme */}
      <Box sx={{ width: 1, gridColumnStart: 1, gridRowStart: 1, pointerEvents: (newThemeVisible) ? "auto" : "none" }}>
        <Fade in={newThemeVisible} >
          <div>
            {handleNewMasterFromTemplate &&
              // <NewThemeStepper onBackToOverview={() => setNewThemeVisible(false)} handleNewMasterFromTemplate={handleNewMasterFromTemplate} />
              <NewThemeCreateScreen onBackToOverview={() => setNewThemeVisible(false)} handleNewMasterFromTemplate={handleNewMasterFromTemplate} />
            }
          </div>
        </Fade>
      </Box>

      {handleDefaultLayoutChange && handleMasterMainColChange && !detailsVisible && !newThemeVisible &&
        <Box sx={{ width: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 0 }}>
          <InfoText text="Select a theme to customize settings, including the default background." />
        </Box>
      }
    </Box>
  )
}

export default Masters