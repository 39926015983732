import { useLocation } from 'react-router-dom'
import { AmplifyUser } from '@aws-amplify/ui'
import HeaderToolbar from '../containers/HeaderToolbar'
import { Box, Typography, Tooltip, IconButton, Paper, Divider, CircularProgress } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { profilePath, securePagesPath } from "../../lib/constants"
import { useTheme } from '@mui/material/styles'
import { useNavigate } from "react-router-dom"
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import Feature from './Feature'
import Payment from './Payment'
import { useAppSelector } from '../../store/hooks'
import { getSubscriptionOptions } from '../../store/subscriptionOptionSlice'
import { canSubscribe } from '../../lib/auth'
import { useEffect } from 'react'
import { featureSpecs } from './featureList'
import Background from '../helpers/Background'

type PaymentPageProps = { currentUser: AmplifyUser | undefined }

const getPlanIdFromPath = (path: string) => {
    const pathComponents = path.split('/')
    return pathComponents[pathComponents.length - 1]
}

const PaymentPage = ({ currentUser }: PaymentPageProps) => {
    const location = useLocation()
    const theme = useTheme()
    let navigate = useNavigate()

    const allSubscriptionSpecs = useAppSelector(getSubscriptionOptions)
    const planId = getPlanIdFromPath(location.pathname)

    var subscriptionOption: SubscriptionOptionSpec | undefined = undefined
    if (planId in allSubscriptionSpecs) subscriptionOption = allSubscriptionSpecs[planId]

    // check if the plan is marked for sale 
    if (subscriptionOption !== undefined && !subscriptionOption.offerForSale) {
        navigate(`/${securePagesPath}/${profilePath}`)
    }
    // check if the plan does exist
    if (Object.keys(allSubscriptionSpecs).length > 0 && subscriptionOption === undefined) {
        navigate(`/${securePagesPath}/${profilePath}`)
    }
    // check if user can subscribe
    if (!canSubscribe(currentUser) && currentUser !== undefined) {
        navigate(`/${securePagesPath}/${profilePath}`)
    }

    useEffect(() => {
        window.gtag('event', 'add_to_cart', {
            value: subscriptionOption ? subscriptionOption.paymentPrice : 0,
            currency: subscriptionOption ? subscriptionOption.currencyCode : 'EUR',
            items: subscriptionOption ? [subscriptionOption.id] : []
        })
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const goBackToProfile = () => {
        window.gtag('event', 'remove_from_cart', {
            value: subscriptionOption ? subscriptionOption.paymentPrice : 0,
            currency: subscriptionOption ? subscriptionOption.currencyCode : 'EUR',
            items: subscriptionOption ? [subscriptionOption.id] : []
        })
        navigate(`/${securePagesPath}/${profilePath}`)
    }

    return (
        <Box sx={{ width: 1, display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
            {/* Header */}
            <HeaderToolbar currentUser={currentUser} extraHoriPadding={true}>{ }</HeaderToolbar>
            {/* Background */}
            <Background showLogo={false} />

            {subscriptionOption !== undefined ?
                <Grid container spacing={3} sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', maxWidth: 1000, minWidth: '60vw', pb: 3, pl: 2, pr: 2, height: 1 }}>
                    <Grid xs={12} sx={{ display: 'flex', justifyContent: 'flex-start', pb: 2 }}>
                        <Tooltip title="Back to selection">
                            <IconButton onClick={(e) => { goBackToProfile() }} size="medium" >
                                <ArrowBackIcon fontSize="medium" />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    <Grid xs={12} sm={6} sx={{ display: 'flex', justifyContent: 'center', width: 1 }}>
                        <Box sx={{ width: 1 }}>
                            <Typography variant="h6" color={theme.palette.text.disabled} sx={{ width: 1, pb: 2 }}>
                                Choose payment option:
                            </Typography>
                            <Payment currentUser={currentUser} subscriptionOption={subscriptionOption} />
                        </Box>
                    </Grid>
                    <Grid xs={12} sm={6} sx={{ display: 'flex', justifyContent: 'center', width: 1, height: 1 }}>
                        <Box sx={{ width: 1, height: 1 }}>
                            <Typography variant="h6" color={theme.palette.text.disabled} sx={{ width: 1, pb: 2 }}>
                                Your selected plan:
                            </Typography>
                            <Paper elevation={3} sx={{ p: 2, height: 1, textAlign: 'center' }}>
                                <Box>
                                    <Typography variant="h5" gutterBottom color='primary'>
                                        {subscriptionOption.name}
                                    </Typography>
                                </Box>
                                <Box sx={{ mt: 1 }}>
                                    <Typography variant="h4" color='primary'>
                                        {subscriptionOption.pricePerIntervalUnit} {subscriptionOption.currencySymbol}
                                    </Typography>
                                </Box>
                                <Box sx={{ mt: -1 }}>
                                    <Typography variant="caption" color='primary'>
                                        {subscriptionOption.currencyCode} / {subscriptionOption.intervalUnitDisplayText}
                                    </Typography>
                                </Box>
                                <Box sx={{ mt: 1, mb: 1 }}>
                                    <Typography variant="subtitle2" color={theme.palette.text.disabled} gutterBottom>
                                        {subscriptionOption.paymentPrice} {subscriptionOption.currencySymbol} every {subscriptionOption.intervalCount > 1 && subscriptionOption.intervalCount} {subscriptionOption.intervalUnitDisplayText}{subscriptionOption.intervalCount > 1 && 's'}
                                    </Typography>
                                </Box>
                                <Divider />
                                <Box sx={{ width: 1, display: 'flex', flexWrap: 'wrap', mt: 2, textAlign: 'left' }}>
                                    {featureSpecs.map((text) => (
                                        <Feature text={text} />
                                    ))}
                                </Box>
                            </Paper>
                        </Box>
                    </Grid >

                </Grid >
                :
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: 1, height: 1, flexWrap: 'wrap' }}>
                    <Box sx={{ width: 1, display: 'flex', justifyContent: 'center', mt: 5, mb: 2 }}>
                        <CircularProgress />
                    </Box>
                    <Box sx={{ width: 1, display: 'flex', justifyContent: 'center' }}>
                        <Typography variant="subtitle2" color={theme.palette.text.disabled}>
                            Loading subscription options
                        </Typography>
                    </Box>
                </Box>
            }
        </Box >
    )
}

export default PaymentPage
