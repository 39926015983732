import React from 'react'
import { Box } from '@mui/material'
import { noDragInitClassName } from '../../lib/constants'

interface InElementBoxProps {
    box: ElementBox | undefined | null,
    children: React.ReactNode, relRndProps: BasicRelRndProps,
    el: LogicalElement,
    size?: number // scale centrally with e.g. 0.5 = 50% size
    transparent?: boolean
}

function InElementBox({ box, children, relRndProps, el, size = 1, transparent = false }: InElementBoxProps) {
    if (!box) return null

    const orgWidth = relRndProps.parentSize.width * box.width
    const orgHeight = relRndProps.parentSize.height * box.height
    const orgTop = relRndProps.parentSize.height * (box.top - el.box.top)
    const orgLeft = relRndProps.parentSize.width * (box.left - el.box.left)
    const scaledWidth = orgWidth * size
    const scaledHeight = orgHeight * size
    const scaledLeft = orgLeft + (orgWidth - scaledWidth) * 0.5
    const scaledTop = orgTop + (orgHeight - scaledHeight) * 0.5

    return (
        <Box sx={{
            display: "flex", justifyContent: "flex-start", flexWrap: "nowrap",
            alignContent: "flex-start", alignItems: "flex-start", position: "absolute",
            pointerEvents: transparent ? 'none' : 'auto',
            top: scaledTop,
            left: scaledLeft,
        }}>
            <Box style={{
                position: "relative", width: scaledWidth, height: scaledHeight,
                pointerEvents: transparent ? 'none' : 'auto'
            }}
                className={noDragInitClassName} // this denies dragging and thus allows touch events when used within react-rnd 
            >
                {children}
            </Box>
        </Box>
    )
}

export default InElementBox