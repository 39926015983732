import RichTextField from './RichTextField'
import { Descendant } from 'slate'
import { Box, PopoverOrigin, useTheme } from '@mui/material'
import InElementBox from '../helpers/InElementBox'
import { useState } from 'react'
import NoDragPopover from '../helpers/NoDragPopover'
import { getPopoverDimensions } from '../../lib/sizes'

type RichTextPopoverProps = {
    el: LogicalElement, relRndProps: BasicRelRndProps,
    richTextBox: RichTextBoxType, handleBlur: (newText: Descendant[]) => void,
    handleFocus?: () => void,
    clickTargetSize?: number // scale centrally with e.g. 0.5 = 50% size
    textRole: TextRole
}

const anchorOrigin: PopoverOrigin = { vertical: 'center', horizontal: 'center' }
const transformOrigin: PopoverOrigin = { vertical: 'center', horizontal: 'center' }

function RichTextPopover({ el, relRndProps, richTextBox, textRole, handleBlur, handleFocus, clickTargetSize = 1 }: Readonly<RichTextPopoverProps>) {
    const [textBoxAnchorEl, setTextBoxAnchorEl] = useState<EventTarget & HTMLElement | null>(null)
    const theme = useTheme()

    const { popoverWidth, popoverHeight } = getPopoverDimensions(richTextBox, relRndProps)

    return (
        <div>
            <InElementBox el={el} relRndProps={relRndProps} box={richTextBox.box} size={clickTargetSize}>
                <Box onClick={(event) => setTextBoxAnchorEl(event.currentTarget)}
                    sx={{ width: 1, height: 1, cursor: 'text' }}></Box>
            </InElementBox>
            <NoDragPopover open={!!textBoxAnchorEl} anchorEl={textBoxAnchorEl} onClose={() => setTextBoxAnchorEl(null)}
                anchorOrigin={anchorOrigin} transformOrigin={transformOrigin}>
                <Box sx={{
                    width: popoverWidth, height: popoverHeight, borderRadius: theme.customStyling.relRndBoarderRadius,
                    borderStyle: "solid", borderWidth: "2px", borderColor: theme.palette.primary.main,
                    backgroundColor: theme.palette.background.paper, overflow: 'hidden'
                }}>
                    {richTextBox.richText && richTextBox.box &&
                        <RichTextField richText={richTextBox.richText} alwaysVisible={true} el={el} textRole={textRole}
                            handleElementUserFontFactorChange={relRndProps.handleElementUserFontFactorChange}
                            handleRTBlur={(newText: Descendant[]) => { handleBlur(newText) }} handleRTFocus={handleFocus}
                            textAreaHeight={relRndProps.parentSize.height * richTextBox.box.height} visibilityByFocus={true} />
                    }
                </Box>
            </NoDragPopover>
        </div>
    )
}

export default RichTextPopover
