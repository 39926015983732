import { useState } from "react"
import Box from '@mui/material/Box'
import { Descendant } from 'slate'
import { ClickAwayListener } from '@mui/material'
import RichTextPopover from '../../text/RichTextPopover'
import ElementTitle from '../ElementTitle'
import RelRnd from '../RelRnd'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import { richLoremParagraph } from '../../../lib/text'
import { numeration1InitItemWordCount } from '../ElementDefinitions'
import ControlOverlay from "../../helpers/ControlOverlay"
import _ from 'lodash'
import { NCButtonSize } from "../../../lib/style"

type NumerationProps = {
  el: LogicalElement, relRndProps: BasicRelRndProps, elementActive: boolean, elementSelected: boolean,
  updateElementContent: ElementContentChangeFunction
}

function Numeration1({ el, relRndProps, elementActive, elementSelected, updateElementContent }: Readonly<NumerationProps>) {
  const elementContent = el.content! as Numeration1Content
  const [activeItem, setActiveItem] = useState<number | undefined>(undefined)

  const deleteItem = (i: number) => {
    if (elementContent && relRndProps.currentSlideId && elementContent.items.length > 1) {
      const newItems = elementContent.items.slice()
      newItems.splice(i, 1)
      updateElementContent(relRndProps.currentSlideId, el.elementId, { ...elementContent, items: newItems }, "APPEARANCE_CHANGE")
      setActiveItem(undefined)
    }
  }

  const addItem = (position: number) => {
    if (elementContent && relRndProps.currentSlideId) {
      const newItems = elementContent.items.slice()
      newItems.splice(position, 0, { richText: richLoremParagraph(numeration1InitItemWordCount) })
      updateElementContent(relRndProps.currentSlideId, el.elementId, { ...elementContent, items: newItems }, "APPEARANCE_CHANGE")
      setActiveItem(position)
    }
  }

  const handleItemChange = (newText: Descendant[], i: number) => {
    if (elementContent && relRndProps.currentSlideId && !_.isEqual(elementContent.items[i].richText, newText)) {
      const newItems = elementContent.items.slice()
      newItems[i] = { ...newItems[i], richText: newText }
      updateElementContent(relRndProps.currentSlideId, el.elementId, { ...elementContent, items: newItems }, "IMMEDIATE_ELEMENT_CONTENT_TYPING")
    }
  }

  const handleItemActivation = (myIndex: number | undefined) => {
    setActiveItem(myIndex)
  }

  return (
    <RelRnd relRndProps={relRndProps} el={el} elementSelected={elementSelected} elementActive={elementActive}>
      {/* Element Title */}
      <ElementTitle el={el} relRndProps={relRndProps} />

      {elementContent.items.map((item, i: number) => (
        item.box && (
          <Box key={i} sx={{ zIndex: activeItem === i ? 1 : 0 }}>
            <ClickAwayListener onClickAway={() => handleItemActivation(undefined)}>
              <Box
                onMouseEnter={() => handleItemActivation(i)}
                onClick={() => handleItemActivation(i)}
              >
                {/* Text Content */}
                <RichTextPopover
                  el={el}
                  relRndProps={relRndProps}
                  richTextBox={item}
                  textRole="BODY1"
                  handleBlur={(newText: Descendant[]) => handleItemChange(newText, i)}
                />

                {/* Control Overlay */}
                {elementActive && activeItem === i && (
                  <ControlOverlay
                    el={el}
                    relRndProps={relRndProps}
                    box={item.box}
                    iconArray={[
                      {
                        position: 'CENTERRIGHT',
                        icon: <DeleteForeverIcon fontSize={NCButtonSize.standard.iconFontSize} />,
                        onClick: () => deleteItem(i),
                        title: 'Delete Item',
                      },
                      {
                        position: 'TOPCENTER',
                        icon: <AddCircleIcon fontSize={NCButtonSize.standard.iconFontSize} />,
                        onClick: () => addItem(i),
                        title: 'Add Above',
                      },
                      {
                        position: 'BOTTOMCENTER',
                        icon: <AddCircleIcon fontSize={NCButtonSize.standard.iconFontSize} />,
                        onClick: () => addItem(i + 1),
                        title: 'Add Below',
                      },
                    ]}
                  />
                )}
              </Box>
            </ClickAwayListener>
          </Box>
        )
      ))}
    </RelRnd>
  )
}

export default Numeration1
