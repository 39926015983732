import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ReactCrop, { Crop, PercentCrop } from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'

interface ImageCropProps {
    relCrop: RelCrop
    imgPath: string
    targetAspectRatio?: number
    handleCropComplete: (relCrop: RelCrop) => void
}

const ImageCrop: React.FC<ImageCropProps> = ({ relCrop, imgPath, targetAspectRatio, handleCropComplete }) => {
    // Initialize crop state based on props
    const [crop, setCrop] = useState<Crop>(relCropToCrop(relCrop))


    // Image scaling factors based on crop
    const imageScaleX = (1 / relCrop.cropWidthRel)
    const imageScaleY = (1 / relCrop.cropHeightRel)

    // Calculate full image position and size relative to the parent
    const fullImageLeftPercent = relCrop.cropOffsetXRel * imageScaleX * -100
    const fullImageTopPercent = relCrop.cropOffsetYRel * imageScaleY * -100
    const fullImageWidthPercent = imageScaleX * 100
    const fullImageHeightPercent = imageScaleY * 100

    const onCropComplete = (crop: Crop, percentCrop: PercentCrop) => {
        handleCropComplete({
            cropOffsetXRel: percentCrop.x / 100, cropOffsetYRel: percentCrop.y / 100,
            cropHeightRel: percentCrop.height / 100, cropWidthRel: percentCrop.width / 100
        })
    }

    const onCropChange = (newCrop: Crop) => {
        setCrop(newCrop)
    }

    useEffect(() => {
        // Update crop area on changing input crop
        setCrop(relCropToCrop(relCrop))
    }, [relCrop])

    return (
        <Box sx={{ position: 'relative', left: `${fullImageLeftPercent}%`, top: `${fullImageTopPercent}%`, width: `${fullImageWidthPercent}%`, height: `${fullImageHeightPercent}%` }}>
            <ReactCrop crop={crop} onComplete={onCropComplete} onChange={onCropChange} aspect={targetAspectRatio}
                style={{ width: '100%', height: '100%' }} keepSelection={true}>
                <img src={imgPath} style={{ width: '100%', height: '100%' }} alt='Crop view' />
            </ReactCrop>
        </Box>
    )
}

export default ImageCrop

const relCropToCrop = (relCrop: RelCrop) => {
    return {
        unit: '%' as 'px' | '%', // Set unit to percentage for relative cropping
        x: relCrop.cropOffsetXRel * 100,
        y: relCrop.cropOffsetYRel * 100,
        width: relCrop.cropWidthRel * 100,
        height: relCrop.cropHeightRel * 100,
    }
}