import { framePaddingNarrow } from "../../../lib/constants"

export const icongrid1Presets: Record<string, ElementPreset> = {
    GRID_2x2:
    {
        displayName: 'Grid (2 x 2)',
        titleText: 'Icon Grid',
        type: 'ICONGRID1',
        framePadding: framePaddingNarrow,
        "box": {
            "height": 0.5564308541235814,
            "left": 0.1226203154655879,
            "top": 0.2626393567670545,
            "width": 0.739493510164277
        },
        "content": {
            "iconPosition": "CENTERLEFT",
            "iconSize": 4,
            "realignText": false,
            "rows": [
                [
                    {
                        "body": {
                            "richText": [
                                {
                                    "align": "left",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "A day on Venus is longer than a year on Venus. It takes Venus 243 Earth days to rotate once, but only 225 Earth days to orbit the Sun.",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "box": {
                                "height": 0.16742242636337124,
                                "left": 0.228439468503937,
                                "top": 0.3664262175561388,
                                "width": 0.23865780839895012
                            }
                        },
                        "header": {
                            "richText": [
                                {
                                    "align": "left",
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Venus: The Slow Spinner",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "box": {
                                "height": 0.07336482939632546,
                                "left": 0.228439468503937,
                                "top": 0.2814426946631671,
                                "width": 0.23865780839895012
                            }
                        },
                        "iconSettings": {
                            "box": {
                                "height": 0.08888888888888889,
                                "left": 0.14850943241469816,
                                "top": 0.28588713910761154,
                                "width": 0.05
                            },
                            "iconSource": {
                                "id": "NP_148293",
                                "provider": "NP",
                                "providerId": "148293",
                                "thumbnailUrl": "https://static.thenounproject.com/png/148293-200.png"
                            }
                        }
                    },
                    {
                        "body": {
                            "richText": [
                                {
                                    "align": "left",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "There are more stars in the universe than grains of sand on all of Earth's beaches combined, with estimates ranging up to 1 septillion stars.",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "box": {
                                "height": 0.16742242636337124,
                                "left": 0.6034503772965879,
                                "top": 0.3664262175561388,
                                "width": 0.23865780839895012
                            }
                        },
                        "header": {
                            "richText": [
                                {
                                    "align": "left",
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "The Starry Expanse",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "box": {
                                "height": 0.07336482939632546,
                                "left": 0.6034503772965879,
                                "top": 0.2814426946631671,
                                "width": 0.23865780839895012
                            }
                        },
                        "iconSettings": {
                            "box": {
                                "height": 0.08888888888888889,
                                "left": 0.523520341207349,
                                "top": 0.28588713910761154,
                                "width": 0.05
                            },
                            "iconSource": {
                                "id": "NP_7174828",
                                "provider": "NP",
                                "providerId": "7174828",
                                "thumbnailUrl": "https://static.thenounproject.com/png/7174828-200.png"
                            }
                        }
                    }
                ],
                [
                    {
                        "body": {
                            "richText": [
                                {
                                    "align": "left",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Jupiter has the most powerful storm in the solar system, the Great Red Spot, which has been raging for over 300 years and is larger than Earth.",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "box": {
                                "height": 0.16742242636337124,
                                "left": 0.228439468503937,
                                "top": 0.630450860309128,
                                "width": 0.23865780839895012
                            }
                        },
                        "header": {
                            "richText": [
                                {
                                    "align": "left",
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Jupiter’s Great Red Spot",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "box": {
                                "height": 0.07336482939632546,
                                "left": 0.228439468503937,
                                "top": 0.5454673374161563,
                                "width": 0.23865780839895012
                            }
                        },
                        "iconSettings": {
                            "box": {
                                "height": 0.08888888888888889,
                                "left": 0.14850943241469816,
                                "top": 0.5499117818606007,
                                "width": 0.05
                            },
                            "iconSource": {
                                "id": "NP_7078122",
                                "provider": "NP",
                                "providerId": "7078122",
                                "thumbnailUrl": "https://static.thenounproject.com/png/7078122-200.png"
                            }
                        }
                    },
                    {
                        "body": {
                            "richText": [
                                {
                                    "align": "left",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Neutron stars are so dense that a sugar-cube-sized amount of material from one would weigh about a billion tons on Earth.",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "box": {
                                "height": 0.16742242636337124,
                                "left": 0.6034503772965879,
                                "top": 0.630450860309128,
                                "width": 0.23865780839895012
                            }
                        },
                        "header": {
                            "richText": [
                                {
                                    "align": "left",
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "The Density of Neutron Stars",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "box": {
                                "height": 0.07336482939632546,
                                "left": 0.6034503772965879,
                                "top": 0.5454673374161563,
                                "width": 0.23865780839895012
                            }
                        },
                        "iconSettings": {
                            "box": {
                                "height": 0.08888888888888889,
                                "left": 0.523520341207349,
                                "top": 0.5499117818606007,
                                "width": 0.05
                            },
                            "iconSource": {
                                "id": "NP_6567916",
                                "provider": "NP",
                                "providerId": "6567916",
                                "thumbnailUrl": "https://static.thenounproject.com/png/6567916-200.png"
                            }
                        }
                    }
                ]
            ],
            "showHeader": true,
            "showBody": true
        }

    },
    GRID_3x1:
    {
        displayName: 'Grid (3 x 1)',
        type: 'ICONGRID1',
        framePadding: framePaddingNarrow,
        "box": {
            "height": 0.37334715373453203,
            "left": 0.10910559986030316,
            "top": 0.24949641523605745,
            "width": 0.739493510164277
        },
        "content": {
            "iconPosition": "TOPCENTER",
            "iconSize": 4,
            "realignText": false,
            "rows": [
                [
                    {
                        "body": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "A day on Venus is longer than a year on Venus. It takes Venus 243 Earth days to rotate once, but only 225 Earth days to orbit the Sun.",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "box": {
                                "height": 0.12703484981044036,
                                "left": 0.12035556102362205,
                                "top": 0.47580854476523765,
                                "width": 0.22318069225721784
                            }
                        },
                        "header": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Venus: The Slow Spinner",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "box": {
                                "height": 0.041833187518226886,
                                "left": 0.12035556102362205,
                                "top": 0.42947550306211724,
                                "width": 0.22318069225721784
                            }
                        },
                        "iconSettings": {
                            "box": {
                                "height": 0.08888888888888889,
                                "left": 0.20694586614173227,
                                "top": 0.3271424613589968,
                                "width": 0.05
                            },
                            "iconSource": {
                                "id": "NP_148293",
                                "provider": "NP",
                                "providerId": "148293",
                                "thumbnailUrl": "https://static.thenounproject.com/png/148293-200.png"
                            }
                        }
                    },
                    {
                        "body": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "There are more stars in the universe than grains of sand on all of Earth's beaches combined, with estimates ranging up to 1 septillion stars.",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "box": {
                                "height": 0.12703484981044036,
                                "left": 0.3672618930446194,
                                "top": 0.47580854476523765,
                                "width": 0.22318069225721784
                            }
                        },
                        "header": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "The Starry Expanse",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "box": {
                                "height": 0.041833187518226886,
                                "left": 0.3672618930446194,
                                "top": 0.42947550306211724,
                                "width": 0.22318069225721784
                            }
                        },
                        "iconSettings": {
                            "box": {
                                "height": 0.08888888888888889,
                                "left": 0.45385219816272965,
                                "top": 0.3271424613589968,
                                "width": 0.05
                            },
                            "iconSource": {
                                "id": "NP_7174828",
                                "provider": "NP",
                                "providerId": "7174828",
                                "thumbnailUrl": "https://static.thenounproject.com/png/7174828-200.png"
                            }
                        }
                    },
                    {
                        "body": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Jupiter has the most powerful storm in the solar system, the Great Red Spot, which has been raging for over 300 years and is larger than Earth.",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "box": {
                                "height": 0.12703484981044036,
                                "left": 0.6141682250656167,
                                "top": 0.47580854476523765,
                                "width": 0.22318069225721784
                            }
                        },
                        "header": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Jupiter’s Great Red Spot",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "box": {
                                "height": 0.041833187518226886,
                                "left": 0.6141682250656167,
                                "top": 0.42947550306211724,
                                "width": 0.22318069225721784
                            }
                        },
                        "iconSettings": {
                            "box": {
                                "height": 0.08888888888888889,
                                "left": 0.700758530183727,
                                "top": 0.3271424613589968,
                                "width": 0.05
                            },
                            "iconSource": {
                                "id": "NP_7078122",
                                "provider": "NP",
                                "providerId": "7078122",
                                "thumbnailUrl": "https://static.thenounproject.com/png/7078122-200.png"
                            }
                        }
                    }
                ]
            ],
            "showHeader": true,
            "showBody": true
        }
    },
    GRID_1x3:
    {
        displayName: 'Grid (1 x 3)',
        type: 'ICONGRID1',
        framePadding: framePaddingNarrow,
        "box": {
            "height": 0.6739314343771666,
            "left": 0.06802619827329562,
            "top": 0.2579524571525395,
            "width": 0.26912771658231616
        },
        "content": {
            "iconPosition": "CENTERLEFT",
            "iconSize": 4,
            "realignText": false,
            "rows": [
                [
                    {
                        "body": {
                            "richText": [
                                {
                                    "align": "left",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "A day on Venus is longer than a year on Venus. It takes Venus 243 Earth days to rotate once, but only 225 Earth days to orbit the Sun.",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "box": {
                                "height": 0.1396347331583552,
                                "left": 0.15700164041994752,
                                "top": 0.34662802566345874,
                                "width": 0.17366543635170603
                            }
                        },
                        "header": {
                            "richText": [
                                {
                                    "align": "left",
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Venus: The Slow Spinner",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "box": {
                                "height": 0.0641758530183727,
                                "left": 0.15700164041994752,
                                "top": 0.27795231846019247,
                                "width": 0.17366543635170603
                            }
                        },
                        "iconSettings": {
                            "box": {
                                "height": 0.08148148148148149,
                                "left": 0.09633825459317585,
                                "top": 0.28202639253426653,
                                "width": 0.04583333333333333
                            },
                            "iconSource": {
                                "id": "NP_148293",
                                "provider": "NP",
                                "providerId": "148293",
                                "thumbnailUrl": "https://static.thenounproject.com/png/148293-200.png"
                            }
                        }
                    }
                ],
                [
                    {
                        "body": {
                            "richText": [
                                {
                                    "align": "left",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Jupiter has the most powerful storm in the solar system, the Great Red Spot, which has been raging for over 300 years and is larger than Earth.",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "box": {
                                "height": 0.1396347331583552,
                                "left": 0.15700164041994752,
                                "top": 0.5594383202099737,
                                "width": 0.17366543635170603
                            }
                        },
                        "header": {
                            "richText": [
                                {
                                    "align": "left",
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Jupiter’s Great Red Spot",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "box": {
                                "height": 0.0641758530183727,
                                "left": 0.15700164041994752,
                                "top": 0.4907626130067075,
                                "width": 0.17366543635170603
                            }
                        },
                        "iconSettings": {
                            "box": {
                                "height": 0.08148148148148149,
                                "left": 0.09633825459317585,
                                "top": 0.49483668708078155,
                                "width": 0.04583333333333333
                            },
                            "iconSource": {
                                "id": "NP_7078122",
                                "provider": "NP",
                                "providerId": "7078122",
                                "thumbnailUrl": "https://static.thenounproject.com/png/7078122-200.png"
                            }
                        }
                    }
                ],
                [
                    {
                        "body": {
                            "richText": [
                                {
                                    "align": "left",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "There are more stars in the universe than grains of sand on all of Earth's beaches combined, with estimates ranging up to 1 septillion stars.",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "box": {
                                "height": 0.1396347331583552,
                                "left": 0.15700164041994752,
                                "top": 0.7722486147564888,
                                "width": 0.17366543635170603
                            }
                        },
                        "header": {
                            "richText": [
                                {
                                    "align": "left",
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "The Starry Expanse",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "box": {
                                "height": 0.0641758530183727,
                                "left": 0.15700164041994752,
                                "top": 0.7035729075532225,
                                "width": 0.17366543635170603
                            }
                        },
                        "iconSettings": {
                            "box": {
                                "height": 0.08148148148148149,
                                "left": 0.09633825459317585,
                                "top": 0.7076469816272966,
                                "width": 0.04583333333333333
                            },
                            "iconSource": {
                                "id": "NP_7174828",
                                "provider": "NP",
                                "providerId": "7174828",
                                "thumbnailUrl": "https://static.thenounproject.com/png/7174828-200.png"
                            }
                        }
                    }
                ]
            ],
            "showHeader": true,
            "showBody": true
        }
    },
    SINGLE:
    {
        displayName: 'Single icon',
        type: 'ICONGRID1',
        framePadding: undefined,
        "box": {
            "height": 0.3734659283126214,
            "left": 0.2025900565644537,
            "top": 0.36692410195763864,
            "width": 0.25364691872581124
        },
        "content": {
            "iconPosition": "TOPCENTER",
            "iconSize": 4,
            "realignText": false,
            "rows": [
                [
                    {
                        "body": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "A day on Venus is longer than a year on Venus. It takes Venus 243 Earth days to rotate once, but only 225 Earth days to orbit the Sun.",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "box": {
                                "height": 0.13641601049868765,
                                "left": 0.19961294291338583,
                                "top": 0.5849303003791193,
                                "width": 0.2536468996062992
                            }
                        },
                        "header": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Venus: The Slow Spinner",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "box": {
                                "height": 0.04895202682997959,
                                "left": 0.19961294291338583,
                                "top": 0.5243595800524934,
                                "width": 0.2536468996062992
                            }
                        },
                        "iconSettings": {
                            "box": {
                                "height": 0.08888888888888889,
                                "left": 0.3014363517060367,
                                "top": 0.4077888597258676,
                                "width": 0.05
                            },
                            "iconSource": {
                                "id": "NP_148293",
                                "provider": "NP",
                                "providerId": "148293",
                                "thumbnailUrl": "https://static.thenounproject.com/png/148293-200.png"
                            }
                        }
                    }
                ]
            ],
            "showHeader": true,
            "showBody": true
        }
    }
}