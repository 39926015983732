import { useCallback, useEffect, useMemo, useState } from 'react'
import isHotkey from 'is-hotkey'
import Box from '@mui/material/Box'
import { Editable, withReact, useSlate, Slate, ReactEditor } from 'slate-react'
import { Editor, Transforms, createEditor, Descendant, BaseEditor, Element as SlateElement, Node as SlateNode, Path } from 'slate'
import FormatBoldIcon from '@mui/icons-material/FormatBold'
import FormatItalicIcon from '@mui/icons-material/FormatItalic'
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined'
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft'
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter'
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight'
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify'
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import { withHistory, HistoryEditor } from 'slate-history'
import Divider from '@mui/material/Divider'
import { CgFormatUppercase } from "react-icons/cg"
import ToggleButton from '@mui/material/ToggleButton'
import { StyledToggleButtonGroup } from '../../lib/style'
import { BiFontSize } from "react-icons/bi"
import { Slider, Tooltip, Typography } from '@mui/material'
import NoDragPopover from '../helpers/NoDragPopover'

// const
const LIST_TYPES = ['numbered-list', 'bulleted-list'] as const
const BLOCK_TYPES = ['paragraph', 'list-item', 'numbered-list', 'bulleted-list'] as const
const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify'] as const
const TEXT_STYLE_TYPES = ['bold', 'italic', 'underline', 'smallCaps'] as const
const HOTKEYS: Record<string, Format> = { 'mod+b': 'bold', 'mod+i': 'italic', 'mod+u': 'underline' }
const toolBarHeight = 35
const iconWidth = 24
const toolbarIconBoxSx = { display: 'flex', justifyContent: 'center', alignItems: 'center', height: `${toolBarHeight}px`, width: `${iconWidth}px`, overflow: 'hidden' }

//types
type ListType = typeof LIST_TYPES[number]
type BlockType = typeof BLOCK_TYPES[number]
type TextAlignType = typeof TEXT_ALIGN_TYPES[number]
type TextStyleType = typeof TEXT_STYLE_TYPES[number]
type Format = ListType | BlockType | TextAlignType | TextStyleType
type RenderElementProps = { attributes: object, children: JSX.Element, element: CustomElement }
type RenderLeafProps = { attributes: object, children: JSX.Element, leaf: CustomText }
type ToolbarButtonInput = { icon: JSX.Element, format: Format }
type CustomText = { text: string, bold?: boolean, italic?: boolean, underline?: boolean, smallCaps?: boolean }
type CustomElement = { type: BlockType, align?: TextAlignType, children: CustomText[] | CustomElement[] }
declare module 'slate' {
  interface CustomTypes {
    Editor: BaseEditor & ReactEditor & HistoryEditor
    Element: CustomElement
    Text: CustomText
  }
}

type RichTextProps = {
  textAreaHeight: number,
  richText: Descendant[],
  el: LogicalElement,
  textRole: TextRole,
  handleElementUserFontFactorChange: ElementUserFontFactorChangeFunction
  visibilityByFocus?: boolean,
  alwaysVisible?: boolean,
  handleRTChange?: (newRichText: Descendant[]) => void,
  handleRTFocus?: () => void,
  handleRTBlur?: (newRichText: Descendant[]) => void
}

function RichTextField({ textAreaHeight, richText, el, textRole, handleElementUserFontFactorChange, visibilityByFocus, alwaysVisible, handleRTChange, handleRTFocus, handleRTBlur }: RichTextProps) {

  // editor setup
  const renderElement = useCallback((props: JSX.IntrinsicAttributes & RenderElementProps) => <Element {...props} />, [])
  const renderLeaf = useCallback((props: JSX.IntrinsicAttributes & RenderLeafProps) => <Leaf {...props} />, [])
  const editor = useMemo(() => withHistory(withReact(createEditor())), [])
  if (richText.length === 0) richText = [{ type: 'paragraph', children: [{ text: '' }] }]


  // local state
  const [visibleState, setVisibleState] = useState(false)
  const initialUserFontSize = el.userFontFactors ? el.userFontFactors[textRole] : 1
  const [currentUserFontSize, setcurrentUserFontSize] = useState<number>(initialUserFontSize || 1)
  const [fontSliderAnchorEl, setFontSliderAnchorEl] = useState<null | HTMLElement>(null)

  // Whenever the content is changed, normalize the structure
  useEffect(() => {
    normalizeEditorContent(editor)
  }, [editor, editor.children]) // When children change, normalize

  // functions
  const handleFocus = () => {
    if (visibilityByFocus) setVisibleState(true)
    if (handleRTFocus) handleRTFocus()
  }

  const handleBlur = () => {
    if (visibilityByFocus) setVisibleState(false)
    if (handleRTBlur) {
      normalizeEditorContent(editor)
      handleRTBlur(editor.children)
    }
  }

  const handleChange = (value: Descendant[]) => {
    const isAstChange = editor.operations.some(op => 'set_selection' !== op.type)
    if (isAstChange) {
      normalizeEditorContent(editor)
      if (handleRTChange) {
        handleRTChange(value)
      }
    }
  }

  const toggleFontSlider = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    fontSliderAnchorEl === null ? setFontSliderAnchorEl(event.currentTarget) : setFontSliderAnchorEl(null)
  }

  const handleFontSizeSliderChangeCommit = (event: Event | React.SyntheticEvent<Element, Event>, value: number | number[]) => {
    if (typeof value === 'number') {
      handleElementUserFontFactorChange(el, textRole, value)
    }
  }

  const handleFontSizeSliderChange = (event: Event | React.SyntheticEvent<Element, Event>, value: number | number[]) => {
    if (typeof value === 'number') {
      setcurrentUserFontSize(value)
    }
  }

  const formatValueLabel = (value: number) => {
    return `${Math.round(value * 100)} %`
  }

  const effVisibility = (visibleState || !!alwaysVisible)

  return (
    <Box sx={{
      width: 1,
      height: 1,
      boxSizing: 'border-box',
      pt: effVisibility ? `${toolBarHeight}px` : undefined,
      opacity: effVisibility ? 1 : 0,
    }}>
      <Slate
        editor={editor}
        initialValue={richText}
        onChange={handleChange}
        key={JSON.stringify(richText)}
      >
        {effVisibility &&
          <Box sx={{ width: 1, mt: `${-toolBarHeight}px`, mb: 0, overflowX: "auto", scrollbarWidth: "thin" }}>
            <SlateToolbar toggleFontSlider={toggleFontSlider} />
          </Box>}
        <Divider sx={{ mb: 0 }} />
        <Box sx={{
          width: 1, height: 1,
          overflowY: "auto", scrollbarWidth: "thin", p: 1
        }}>
          <Editable
            style={{ height: '100%', outline: 'none', border: 'none' }}
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            placeholder="Enter some rich text…"
            spellCheck
            autoFocus={false}
            onKeyDown={event => {
              // if (event.key === 'Tab') {
              //   event.preventDefault()
              //   if (event.shiftKey) {
              //     unindentListItem(editor)
              //   } else {
              //     indentListItem(editor)
              //   }
              // }
              for (const hotkey in HOTKEYS) {
                if (isHotkey(hotkey, event)) {
                  event.preventDefault()
                  const mark = HOTKEYS[hotkey]
                  toggleMark(editor, mark)
                }
              }
            }}
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
        </Box>
      </Slate>

      {/* The text size slider  */}
      <NoDragPopover
        open={!!fontSliderAnchorEl}
        anchorEl={fontSliderAnchorEl}
        onClose={() => setFontSliderAnchorEl(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center', }}
        transformOrigin={{ vertical: 'top', horizontal: 'center', }}
        slotProps={{ paper: { style: { overflow: 'visible', height: '60px' } }, }}
      >
        <Box sx={{ display: 'flex', flexWrap: 'no-wrap', justifyContent: 'center', alignItems: 'center', p: 2 }}>
          <Typography variant='subtitle2' sx={{ width: 60 }}>{Math.round(currentUserFontSize * 100)} %</Typography>
          <Slider
            value={currentUserFontSize}
            min={0.25}
            max={3}
            step={0.05}
            valueLabelFormat={formatValueLabel}
            onChange={handleFontSizeSliderChange}
            onChangeCommitted={handleFontSizeSliderChangeCommit}
            valueLabelDisplay="auto"
            //marks={[{ value: 1, label: '100%' }]}
            sx={{ width: '200px', ml: 2 }}
          />
        </Box>
      </NoDragPopover>
    </Box>
  )
}

interface SlateToolbarProps {
  toggleFontSlider: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void
}

export const SlateToolbar: React.FC<SlateToolbarProps> = ({ toggleFontSlider }) => {
  return (
    <Box style={{ display: "flex", flexWrap: "nowrap", height: `${toolBarHeight}px` }}>
      <StyledToggleButtonGroup size="small" arial-label="text formatting">
        <Tooltip title="Bold">
          {MarkButton({ format: "bold", icon: <FormatBoldIcon fontSize='medium' /> })}
        </Tooltip>
        <Tooltip title="Italic">
          {MarkButton({ format: "italic", icon: <FormatItalicIcon fontSize='medium' />, })}
        </Tooltip>
        <Tooltip title="Underline">
          {MarkButton({ format: "underline", icon: <FormatUnderlinedIcon fontSize='medium' />, })}
        </Tooltip>
        <Tooltip title="Small caps">
          {MarkButton({ format: "smallCaps", icon: <Box sx={toolbarIconBoxSx}><Box sx={{ height: `${toolBarHeight}px`, width: `${toolBarHeight}px` }}><CgFormatUppercase style={{ height: `${toolBarHeight}px`, width: `${toolBarHeight}px` }} /></Box></Box>, })}
        </Tooltip>
      </StyledToggleButtonGroup>
      <Divider orientation="vertical" sx={{ ml: 0, mr: 0 }} flexItem />
      <StyledToggleButtonGroup size="small" arial-label="text formatting" exclusive>
        <Tooltip title="Align left">
          {BlockButton({ format: "left", icon: <FormatAlignLeftIcon fontSize='medium' /> })}
        </Tooltip>
        <Tooltip title="Align center">
          {BlockButton({ format: "center", icon: <FormatAlignCenterIcon fontSize='medium' /> })}
        </Tooltip>
        <Tooltip title="Align right">
          {BlockButton({ format: "right", icon: <FormatAlignRightIcon fontSize='medium' /> })}
        </Tooltip>
        <Tooltip title="Justify">
          {BlockButton({ format: "justify", icon: <FormatAlignJustifyIcon fontSize='medium' /> })}
        </Tooltip>
      </StyledToggleButtonGroup>
      <Divider orientation="vertical" sx={{ ml: 0, mr: 0 }} flexItem />
      <StyledToggleButtonGroup size="small" arial-label="text formatting" exclusive>
        <Tooltip title="Numbered list">
          {BlockButton({ format: "numbered-list", icon: <FormatListNumberedIcon fontSize='medium' /> })}
        </Tooltip>
        <Tooltip title="Bulleted list">
          {BlockButton({ format: "bulleted-list", icon: <FormatListBulletedIcon fontSize='medium' /> })}
        </Tooltip>
      </StyledToggleButtonGroup>
      <Divider orientation="vertical" sx={{ ml: 0, mr: 0 }} flexItem />
      <StyledToggleButtonGroup size="small" arial-label="text formatting">
        <Tooltip title="Font size">
          <ToggleButton onClick={(event) => toggleFontSlider(event)} size="small" value="" style={{ borderColor: 'transparent' }}>
            <BiFontSize style={{ width: '24px', height: '24px' }} />
          </ToggleButton>
        </Tooltip>
      </StyledToggleButtonGroup>
    </Box>
  )
}

const toggleBlock = (editor: Editor, format: Format) => {
  const isActive = isBlockActive(editor, format, TEXT_ALIGN_TYPES.includes(format as TextAlignType) ? 'align' : 'type')
  const isList = LIST_TYPES.includes(format as ListType)

  Transforms.unwrapNodes(editor, {
    match: n =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      LIST_TYPES.includes(n.type as ListType) &&
      !TEXT_ALIGN_TYPES.includes(format as TextAlignType),
    split: true,
  })
  let newProperties: Partial<SlateElement>
  if (TEXT_ALIGN_TYPES.includes(format as TextAlignType)) {
    newProperties = {
      align: isActive ? undefined : format as TextAlignType,
    }
  } else {
    newProperties = {
      type: isActive ? 'paragraph' : isList ? 'list-item' : format as BlockType,
    }
  }
  Transforms.setNodes<SlateElement>(editor, newProperties)

  if (!isActive && isList) {
    const block = { type: format as BlockType, children: [] }
    Transforms.wrapNodes(editor, block)
  }
}

const toggleMark = (editor: Editor, format: Format) => {
  const isActive = isMarkActive(editor, format)

  if (isActive) {
    Editor.removeMark(editor, format)
    Editor.addMark(editor, format, false)
  } else {
    Editor.addMark(editor, format, true)
  }
}

const isBlockActive = (editor: Editor, format: Format, blockType: 'align' | 'type' = 'type') => {
  const { selection } = editor
  if (!selection) return false

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: n =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        n[blockType] === format,
    })
  )

  return !!match
}

const isMarkActive = (editor: Editor, format: Format) => {
  const marks = Editor.marks(editor)
  return marks ? marks[format as TextStyleType] === true : false
}

const Element = ({ attributes, children, element }: RenderElementProps) => {
  const style = { textAlign: element.align, marginTop: 0, marginBottom: 8 }
  switch (element.type) {
    case 'bulleted-list':
      return (
        <ul style={style} {...attributes}>
          {children}
        </ul>
      )
    case 'list-item':
      return (
        <li style={style} {...attributes}>
          {children}
        </li>
      )
    case 'numbered-list':
      return (
        <ol style={style} {...attributes}>
          {children}
        </ol>
      )
    default:
      return (
        <p style={style} {...attributes}>
          {children}
        </p>
      )
  }
}

const Leaf = ({ attributes, children, leaf }: RenderLeafProps) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>
  }
  if (leaf.italic) {
    children = <em>{children}</em>
  }

  if (leaf.smallCaps) {
    children = <span style={{ fontVariant: 'small-caps' }}>{children}</span>
  }

  if (leaf.underline) {
    children = <u>{children}</u>
  }
  return <span {...attributes}>{children}</span>
}

const BlockButton = ({ icon, format }: ToolbarButtonInput) => {
  const editor = useSlate()
  return (
    <ToggleButton
      value={format}
      selected={isBlockActive(editor, format)}
      onMouseDown={(event) => {
        event.preventDefault()
        toggleBlock(editor, format)
      }}
    >
      {icon}
    </ToggleButton>
  )
}

const MarkButton = ({ format, icon }: ToolbarButtonInput) => {
  const editor = useSlate()
  return (
    <ToggleButton
      value={format}
      selected={isMarkActive(editor, format)}
      onMouseDown={(event) => {
        event.preventDefault()
        toggleMark(editor, format)
      }}
    >
      {icon}
    </ToggleButton>
  )
}

// Type guard to check if the node is a CustomElement
export function isCustomElement(node: SlateNode): node is CustomElement {
  return (node as CustomElement).type !== undefined && (node as CustomElement).children !== undefined
}

// Type guard to check if the node is a CustomText (text node without a type)
export function isCustomText(node: SlateNode): node is CustomText {
  return (node as CustomText).text !== undefined
}

const normalizeEditorContent = (editor: Editor): void => {
  // Helper function to normalize nodes recursively
  const normalizeNode = (node: SlateNode, path: any): void => {
    if (isCustomElement(node)) {
      const customElement = node as CustomElement

      // Ensure the top-level node is of valid type (bulleted-list, numbered-list, paragraph)
      if (path.length === 1) {
        if (customElement.type !== 'bulleted-list' && customElement.type !== 'numbered-list' && customElement.type !== 'paragraph') {
          // If the node is not one of the allowed top-level types, remove it
          Transforms.removeNodes(editor, { at: path })
        }
      }

      // Handle bulleted-list or numbered-list nodes
      if (customElement.type === 'bulleted-list' || customElement.type === 'numbered-list') {
        // If the list has no children, remove the list
        if (customElement.children.length === 0) {
          Transforms.removeNodes(editor, { at: path })
        }
        else { // loop the children
          customElement.children.forEach((child, index) => {
            if (isCustomElement(child)) {
              const customChildElement = child as CustomElement

              // Convert paragraph inside the list to list-item (preserving the text)
              if (customChildElement.type === 'paragraph') {
                // Change the type of the paragraph to list-item
                Transforms.setNodes(editor, { type: 'list-item' }, { at: [...path, index] })
                // Recursively normalize the new list-item object
                normalizeNode(customChildElement, [...path, index])
              } else {
                // Recursively normalize nested lists and list-items
                normalizeNode(customChildElement, [...path, index])
              }
            } else if (isCustomText(child)) {
              // If the child is CustomText (without a type), delete it
              Transforms.removeNodes(editor, { at: [...path, index] })
            }
          })

        }
      }

      // handle paragraphs
      if (customElement.type === 'paragraph') {
        // If the paragraph has no children, remove the paragraph
        if (customElement.children.length === 0) {
          Transforms.removeNodes(editor, { at: path })
        } else {// loop the children and delete all non-text nodes
          customElement.children.forEach((child, index) => {
            if (!isCustomText(child)) {
              Transforms.removeNodes(editor, { at: [...path, index] })
            }
          })
        }
      }

      // handle list-items
      if (customElement.type === 'list-item') {
        // If the list-item has no children, remove the list-item
        if (customElement.children.length === 0) {
          Transforms.removeNodes(editor, { at: path })
        } else {// loop the children and delete all non-text nodes
          customElement.children.forEach((child, index) => {
            if (!isCustomText(child)) {
              Transforms.removeNodes(editor, { at: [...path, index] })
            }
          })
        }
      }
      // handle text nodes
    } else if (isCustomText(node)) {
      // If the node is CustomText and it's on top level, it should be removed
      if (path.length === 1) {
        Transforms.removeNodes(editor, { at: path })
      }
      // if it's an unknown node, delete
    } else Transforms.removeNodes(editor, { at: path })
  }

  // Traverse the root children and normalize them if necessary
  editor.children.forEach((child, index) => normalizeNode(child, [index]))
}

//TODO: indentListItem and unindentListItem have to be reworked
// const indentListItem = (editor: Editor) => {
//   const { selection } = editor
//   if (!selection) return

//   // Find the current list item
//   const [listItemNode, listItemPath] = Array.from(
//     Editor.nodes(editor, {
//       match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'list-item',
//     })
//   )[0] || []

//   if (!listItemNode) return

//   // Find the parent node of the current list item
//   const [parentNode] = Editor.parent(editor, listItemPath)

//   // Ensure the parent node is a list
//   if (SlateElement.isElement(parentNode) && LIST_TYPES.includes(parentNode.type as ListType)) {
//     // Wrap the current list item in a new nested list
//     const newList = { type: parentNode.type, children: [] }

//     Transforms.wrapNodes(editor, newList, { at: listItemPath })
//   }
// }




// const unindentListItem = (editor: Editor) => {
//   const { selection } = editor
//   if (!selection) return

//   // Find the current list item
//   const [listItemNode, listItemPath] = Array.from(
//     Editor.nodes(editor, {
//       match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === "list-item",
//     })
//   )[0] || []

//   if (!listItemNode) return

//   // Find the parent node of the current list item
//   const [parentNode, parentPath] = Editor.parent(editor, listItemPath)

//   // Ensure the parent is a list
//   if (SlateElement.isElement(parentNode) && LIST_TYPES.includes(parentNode.type as ListType)) {
//     // Check the parent's parent (grandparent)
//     const [grandparentNode, grandparentPath] = Editor.parent(editor, parentPath)
//     let parentHasNoChildren = false

//     if (SlateElement.isElement(grandparentNode) && LIST_TYPES.includes(grandparentNode.type as ListType)) {
//       if (grandparentNode.type === parentNode.type) { // Same list type 
//         // Move the list-item out of its parent and into the grandparent
//         if (parentNode.children.length === 1) parentHasNoChildren = true // after moving the list item the parent will be empty
//         Transforms.moveNodes(editor, {
//           at: listItemPath,
//           to: [...grandparentPath, grandparentNode.children.length],
//         })
//       } else { // Case when the parent and grandparent have different list types
//         // Move the entire list (bulleted-list or other) to the same level as the parent
//         Transforms.moveNodes(editor, {
//           at: listItemPath,
//           to: [...grandparentPath, grandparentNode.children.length], // Place at the end of the grandparent's children
//         })
//       }
//     }

//     // Clean up empty lists: if the parent list is now empty, remove it
//     if (parentHasNoChildren) {
//       Transforms.removeNodes(editor, { at: parentPath })
//     }
//   }
// }




export default RichTextField