export const featureSpecs = [
    'Unlimited slide downloads',
    'Unlimited AI usage (excl. automatic bots & account sharing)',
    'AI slide & presentation creation from text input',
    'AI element manipulation',
    'Access to over 5 million icons',
    'Access to over 5 million high-quality images',
    'Automatic AI powered style & color assignment',
    'Beautiful iOS style blurry backgrounds',
    'Work directly in your masters & layouts',
    'Cancel at any time',
]