import React from 'react'
import SimpleCloseDialog from './SimpleCloseDialog'
import { profilePath, securePagesPath } from '../../lib/constants'
import { AmplifyUser } from '@aws-amplify/ui'
import { useNavigate } from 'react-router-dom'
import { useTheme } from '@mui/material'

type DownloadDeniedDialogProps = {
    currentUser: AmplifyUser | undefined,
    setSubscriptionHintOpen: (open: boolean) => void,
    subscriptionHintOpen: boolean
}

function DownloadDeniedDialog({ currentUser, setSubscriptionHintOpen, subscriptionHintOpen }: DownloadDeniedDialogProps) {
    const navigate = useNavigate()
    const theme = useTheme()

    // const handleWelcomeDownload = () => {
    //     const filename = 'Protoslides_welcome.pptx'
    //     const fileUrl = process.env.PUBLIC_URL + '/pptx/' + filename

    //     const link = document.createElement('a')
    //     link.href = fileUrl
    //     link.setAttribute('download', filename)
    //     document.body.appendChild(link)
    //     link.click()
    //     document.body.removeChild(link)
    //     window.gtag("event", "select_content", { content_type: "welcome_pptx_download" })
    // }

    // const handleCoffeeDownload = () => {
    //     const filename = 'Coffee.pptx'
    //     const fileUrl = process.env.PUBLIC_URL + '/pptx/' + filename

    //     const link = document.createElement('a')
    //     link.href = fileUrl
    //     link.setAttribute('download', filename)
    //     document.body.appendChild(link)
    //     link.click()
    //     document.body.removeChild(link)
    //     window.gtag("event", "select_content", { content_type: "coffee_pptx_download" })
    // }

    return (
        <SimpleCloseDialog handleClose={() => setSubscriptionHintOpen(false)} open={subscriptionHintOpen} title={"Subscription required"} width={"50vw"} buttonCaption={"OK"}>
            <>Thank you for evaluating Protoslides. <br /><br />A valid subscription is required to download the generated presentation files.<br />
                {currentUser === undefined ?
                    <>
                        <span onClick={() => { window.gtag("event", "select_content", { content_type: "sign_in_from_download_denied_dialog" }); navigate("/" + securePagesPath + '/' + profilePath) }} style={{ cursor: 'pointer', textDecoration: 'underline', color: theme.palette.primary.main }}>
                            Sign in
                        </span> {' '}to your account and choose your preferred option.</>
                    :
                    <>
                        Navigate to the{' '}
                        <span onClick={() => navigate("/" + securePagesPath + '/' + profilePath)} style={{ cursor: 'pointer', textDecoration: 'underline', color: theme.palette.primary.main }}>
                            profile page
                        </span> {' '}and choose your preferred option.</>}
                <br /><br />
                {/*To examine the quality of the generated slides, you can download the original preloaded presentations:<br />
                 <ul>
                    <li><span onClick={handleWelcomeDownload} style={{ cursor: 'pointer', textDecoration: 'underline', color: theme.palette.primary.main }}>Protoslides welcome presentation</span></li>
                    <li><span onClick={handleCoffeeDownload} style={{ cursor: 'pointer', textDecoration: 'underline', color: theme.palette.primary.main }}>Coffee presentation</span></li>
                </ul> */}

            </>
        </SimpleCloseDialog>
    )
}

export default DownloadDeniedDialog