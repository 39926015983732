import { useEffect, useState } from 'react'
import '@aws-amplify/ui-react/styles.css'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import PaletteIcon from '@mui/icons-material/Palette'
import SimpleCloseDialog from './helpers/SimpleCloseDialog'
import PresentationCreateDialog from './presentations/PresentationCreateDialog'
import Masters from './masters/Masters'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import Tooltip from '@mui/material/Tooltip'
import { useTheme } from '@mui/material'
import { AutoAwesome } from '@mui/icons-material'
import { getCurrentAuthenticatedUser, getSubBaseInfo } from '../lib/auth'
import PresentationCreateDialogAiAssist from './presentations/PresentationCreateDialogAiAssist'
import { promptingEnabledForAll } from '../lib/constants'
import { useLocation, useNavigate } from 'react-router-dom'


type GlobalActionsProps = {
  handleDeleteMaster: IdClickFunction, masters: Masters,
  createPresentationAiAssist: (title: string, masterId: string, userPrompt: string) => Promise<void>,
  handleNewMasterFile: (file: File) => void, createPresentation: (title: string, masterId: string) => void,
  loadingState: LoadingState, handleDefaultLayoutChange?: (masterId: string, defaultLayout: number) => void,
  handleMasterMainColChange: (masterId: string, primXmlKey: string | null, neuXmlKey: string | null) => void,
  handleNewMasterFromTemplate: NewMasterFromTemplateFunction
}

function GlobalActions({ handleDeleteMaster, masters, handleNewMasterFile, createPresentation, loadingState,
  handleDefaultLayoutChange, handleMasterMainColChange, createPresentationAiAssist, handleNewMasterFromTemplate }: GlobalActionsProps) {
  const theme = useTheme()
  const location = useLocation()
  const navigate = useNavigate()
  const openAIPresiCreationDialog = location.state?.openAIPresiCreationDialog || false

  // local state
  const [masterDialogOpen, setMasterDialogOpen] = useState(false)
  const [presentationCreateDialogOpen, setPresentationCreateDialogOpen] = useState(false)
  const [aiPromptingAvailable, setAiPromptingAvailable] = useState(true)
  const [createPresentationAiAssistDialogOpen, setCreatePresentationAiAssistDialogOpen] = useState(openAIPresiCreationDialog)

  useEffect(() => {
    async function verifyPromptingFeatureAccess() {
      const user = await getCurrentAuthenticatedUser()
      const promptingEnabled = (getSubBaseInfo(user)?.promptingEnabled ?? false) || promptingEnabledForAll

      setAiPromptingAvailable(promptingEnabled)
    }

    verifyPromptingFeatureAccess()
  }, [])

  return (
    <Box width={1}>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <IconButton onClick={(event) => { setPresentationCreateDialogOpen(true) }} sx={{}} size="medium">
          <Tooltip title="Create presentation">
            <AddCircleIcon fontSize="medium" sx={{ color: theme.palette.primary.main }} />
          </Tooltip>
        </IconButton>
        {aiPromptingAvailable &&
          <Tooltip title="Create presentation with AI">
            <IconButton onClick={(event) => setCreatePresentationAiAssistDialogOpen(true)} sx={{}} size="medium" className='create_presi_button'>
              <AutoAwesome fontSize="medium" sx={{ color: theme.palette.primary.main }} />
            </IconButton>
          </Tooltip>
        }
        <IconButton onClick={(event) => { setMasterDialogOpen(true) }} sx={{}} size="medium" className='manage_masters_button'>
          <Tooltip title="Manage Themes">
            <PaletteIcon fontSize="medium" sx={{ color: theme.palette.primary.main }} />
          </Tooltip>
        </IconButton>
        <SimpleCloseDialog handleClose={() => setMasterDialogOpen(false)} open={masterDialogOpen} title={"Manage Your Themes"} width={"50vw"} buttonCaption={"Close"}>
          <Masters handleMasterDelete={handleDeleteMaster} masters={masters} handleNewMasterFile={handleNewMasterFile} handleNewMasterFromTemplate={handleNewMasterFromTemplate}
            mastersInAnalysis={loadingState.MASTERANALYSIS} handleDefaultLayoutChange={handleDefaultLayoutChange} handleMasterMainColChange={handleMasterMainColChange} />
        </SimpleCloseDialog>
        <PresentationCreateDialog open={presentationCreateDialogOpen} handleClose={() => setPresentationCreateDialogOpen(false)} masters={masters} loadingState={loadingState}
          createPresentation={createPresentation} title={"Create new presentation"} width={"50vw"} closeButtonCaption={"Cancel"} secondaryButtonCaption={"Create Presentation"} />
        <PresentationCreateDialogAiAssist open={createPresentationAiAssistDialogOpen} handleClose={() => { setCreatePresentationAiAssistDialogOpen(false); navigate(location.pathname, { replace: true, state: {} }) }} masters={masters} loadingState={loadingState}
          createPresentationAiAssist={createPresentationAiAssist} title={"Create new presentation with Protoslides AI"} width={"70vw"} closeButtonCaption={"Cancel"} />
      </Box>
    </Box>
  )
}

export default GlobalActions