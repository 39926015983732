import { Typography, Box, CircularProgress } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { useTheme } from '@mui/material/styles'
import SubscriptionOption from './SubscriptionOption'
import { useAppSelector } from '../../store/hooks'
import { getSubscriptionOptions } from '../../store/subscriptionOptionSlice'

const SubscriptionOptions = () => {
    const theme = useTheme()
    const discount = undefined

    const allSubscriptionSpecs = useAppSelector(getSubscriptionOptions)
    var subscriptionSpecs: SubscriptionOptionSpec[] = []
    for (const key in allSubscriptionSpecs) {
        if (allSubscriptionSpecs[key].offerForSale) subscriptionSpecs.push(allSubscriptionSpecs[key])
    }

    const discountBoxStyle = {
        backgroundColor: theme.palette.secondary.main, borderRadius: '8px', transform: 'rotate(10deg)', display: 'flex',
        flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '60px', width: '350px', mt: -6, mr: -4, ml: -4
    }

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
            {subscriptionSpecs.length > 0 ?
                <Grid container spacing={2} sx={{ maxWidth: 1000, minWidth: '60vw' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'nowrap', width: 1 }}>
                        <Typography variant="h6" gutterBottom color={theme.palette.text.disabled} width={1} ml={1}>
                            Choose your plan:
                        </Typography>
                        {discount !== undefined && discount > 0 &&
                            <Box sx={discountBoxStyle}>
                                <Typography variant='subtitle1' color="text.primary" textAlign="center">
                                    <b>WINTER PROMOTION</b>
                                </Typography>
                                <Typography variant="body1" color="text.primary" textAlign="center">
                                    Until 31.12.2024
                                </Typography>
                            </Box>}
                    </Box>
                    {subscriptionSpecs.map((option, index) => (
                        <Grid xs={12} md={12 / subscriptionSpecs.length} key={index}>
                            <SubscriptionOption option={option} discount={discount} />
                        </Grid>
                    ))}
                </Grid>
                :
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: 1, height: 1, flexWrap: 'wrap' }}>
                    <Box sx={{ width: 1, display: 'flex', justifyContent: 'center', mt: 5, mb: 2 }}>
                        <CircularProgress />
                    </Box>
                    <Box sx={{ width: 1, display: 'flex', justifyContent: 'center' }}>
                        <Typography variant="subtitle2" color={theme.palette.text.disabled}>
                            Loading subscription options
                        </Typography>
                    </Box>
                </Box>
            }
        </Box >
    )
}

export default SubscriptionOptions
