import React, { useState } from 'react'
import { Tabs, Tab, Box, Snackbar, Typography, useTheme } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import UploadIcon from '@mui/icons-material/CloudUpload'
import ImageSearch from './ImageSearch'
import FileDropzone from './FileDropzone'
import { uploadImage } from '../../lib/slideHandling'

interface ImageInputTabsProps {
    handleNewImageSelection: (id: string, provider: ImageProvider, providerCustomValue: string | undefined) => void
}

const ImageInputTabs: React.FC<ImageInputTabsProps> = ({ handleNewImageSelection }) => {
    const theme = useTheme()
    const [value, setValue] = useState<number>(0)
    const [uploadingImage, setUploadingImage] = useState(false)

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue)
    }

    // handle new image file
    const handleNewImageFile = async (file: File) => {
        setUploadingImage(true)
        const imageId = await uploadImage(file)
        if (imageId) handleNewImageSelection(imageId, 'UPLOAD', undefined)
        setUploadingImage(false)
    }

    return (
        <Box sx={{ borderBottom: 1, borderColor: 'divider', width: "70vw", height: "70vh", overflow: 'hidden' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleTabChange} aria-label="image input tabs">
                    <Tab icon={<SearchIcon />} label="Search" />
                    <Tab icon={<UploadIcon />} label="Upload" />
                </Tabs>
            </Box>
            <Box sx={{ p: 3, height: "100%", position: 'relative' }}>
                {/* Render both components but control visibility */}
                <Box sx={{ display: value === 0 ? 'block' : 'none', height: '100%', overflow: 'auto' }}>
                    <ImageSearch height="60vh" handleImageClick={handleNewImageSelection} />
                </Box>
                <Box sx={{ display: value === 1 ? 'block' : 'none', height: '100%', overflow: 'auto' }}>
                    <Box  >
                        <FileDropzone handleFile={handleNewImageFile} accept={{ 'image/png': ['.png'], 'image/jpeg': ['.jpg', '.jpeg'] }}
                            text="Upload image" />
                        <Typography variant='subtitle2' sx={{ width: 1, textAlign: 'center', color: theme.palette.text.disabled, p: 4 }}>
                            By uploading you confirm that you have the necessary rights and permissions to upload this image, and that it does not violate any third-party rights, copyright, or local laws.
                        </Typography>
                    </Box>
                </Box>
            </Box>

            <Snackbar open={uploadingImage} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                message="Uploading image..." />
        </Box>
    )
}

export default ImageInputTabs
