import { createSlice } from '@reduxjs/toolkit'
import { RootState } from './store'

// Type definitions
const initialState: RenderOptions = { format: "jpeg", quality: 90, speed: 8, lossless: false }
type RenderOptionPayload = { payload: RenderOptions }

// slice definition
export const renderingOptionsSlice = createSlice({
  name: 'renderingOptions',
  initialState,
  reducers: {
    setRenderingOptions: (state, action: RenderOptionPayload) => {
      return action.payload
    },
  },
})

//actions
export const { setRenderingOptions } = renderingOptionsSlice.actions

// selectors
// TODO: Look into correct selector structure for multiple component usage of same selector (https://react-redux.js.org/api/hooks)
export const getRenderingOptions = (state: RootState) => state.renderingOptions
export const getRenderingFormat = (state: RootState) => state.renderingOptions.format
export const getRenderingQuality = (state: RootState) => state.renderingOptions.quality
export const getRenderingSpeed = (state: RootState) => state.renderingOptions.speed

export default renderingOptionsSlice.reducer
