import React, { useEffect, useState } from 'react'
import { Box, ClickAwayListener, IconButton, Tooltip } from '@mui/material'
import { Crop, ImageSearchOutlined } from '@mui/icons-material'
import InElementBox from './InElementBox'
import ImageCrop from './ImageCrop'
import NeutralCircleButton from './NeutralCircleButton'
import ImageInputTabs from './ImageInputTabs'
import SimpleCloseDialog from './SimpleCloseDialog'
import { NCButtonSize } from '../../lib/style'

interface ImageDisplayProps {
    el: LogicalElement
    relRndProps: BasicRelRndProps
    imageSettings: ImageSettings
    handleSettingsChange: (imageSettings: ImageSettings) => void
    onCropToggle?: (cropVisible: boolean) => void
}

const ImageDisplay: React.FC<ImageDisplayProps> = ({ el, relRndProps, imageSettings, handleSettingsChange, onCropToggle }) => {
    // State to control button visibility
    const [showButtons, setShowButtons] = useState(false)
    const [showCrop, setShowCrop] = useState(false)
    const [imageSearchPopoverAnchorEl, setImageSearchPopoverAnchorEl] = useState<EventTarget & HTMLElement | null>(null)

    const handleCropComplete = (relCrop: RelCrop) => {
        handleSettingsChange({ ...imageSettings, crop: relCrop })
    }

    const toggleCrop = () => {
        // check aspect ratios when activationg crop
        // If they don't match, the crop is adjusted
        // this happens when the user stretches the element  
        if (!showCrop) {
            if (imageSettings.box && imageSettings.crop && imageSettings.orgAspectRatio) {
                const targetAspectRatio = (imageSettings.box.width * relRndProps.parentSize.width) / (imageSettings.box.height * relRndProps.parentSize.height)
                const cropAspectRatio = imageSettings.crop.cropWidthRel / imageSettings.crop.cropHeightRel * imageSettings.orgAspectRatio
                const targetAspectRatioRounded = Math.round(targetAspectRatio * 100) / 100
                const cropAspectRatioRounded = Math.round(cropAspectRatio * 100) / 100
                if (cropAspectRatioRounded > targetAspectRatioRounded) {
                    handleSettingsChange({ ...imageSettings, crop: { ...imageSettings.crop, cropWidthRel: imageSettings.crop.cropWidthRel * targetAspectRatio / cropAspectRatio } })
                }
                if (cropAspectRatioRounded < targetAspectRatioRounded) {
                    handleSettingsChange({ ...imageSettings, crop: { ...imageSettings.crop, cropHeightRel: imageSettings.crop.cropHeightRel * cropAspectRatio / targetAspectRatio } })
                }
            }
        }
        setShowCrop(!showCrop)
    }

    const handleNewImageSelection = (id: string, provider: ImageProvider, providerCustomValue: string | undefined) => {
        handleSettingsChange({ id: id, provider: provider, providerCustomValue: providerCustomValue })
        setImageSearchPopoverAnchorEl(null)
    }

    useEffect(() => {
        if (onCropToggle) onCropToggle(showCrop)
    }, [showCrop, onCropToggle])

    return (
        <div>
            {imageSettings.box && (
                <InElementBox el={el} relRndProps={relRndProps} box={imageSettings.box}>
                    <ClickAwayListener onClickAway={() => setShowCrop(false)}>
                        <Box onMouseEnter={() => setShowButtons(true)} onMouseLeave={() => setShowButtons(false)}
                            onClick={() => setShowButtons(true)}
                            sx={{ position: 'relative', placeItems: 'center', width: 1, height: 1, cursor: 'auto' }}
                        >
                            {/* Overlay ImageCrop */}
                            {showCrop && imageSettings?.urls &&
                                <Box sx={{ position: 'absolute', top: 0, left: 0, width: 1, height: 1, opacity: 0.7 }}>
                                    <ImageCrop
                                        relCrop={imageSettings.crop || { cropHeightRel: 1, cropWidthRel: 1, cropOffsetXRel: 0, cropOffsetYRel: 0 }}
                                        imgPath={imageSettings.urls.regular}
                                        targetAspectRatio={(imageSettings.box.width * relRndProps.parentSize.width) / (imageSettings.box.height * relRndProps.parentSize.height)}
                                        handleCropComplete={handleCropComplete}
                                    />
                                </Box>}

                            {/* Overlay IconButtons */}
                            {showButtons && (
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: 1,
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                }}>
                                    <NeutralCircleButton size={NCButtonSize.standard.size}>
                                        <Tooltip title="Crop image">
                                            <IconButton onClick={toggleCrop} size={NCButtonSize.standard.iconButtonSize} >
                                                <Crop fontSize={NCButtonSize.standard.iconFontSize} />
                                            </IconButton>
                                        </Tooltip>
                                    </NeutralCircleButton>
                                    <NeutralCircleButton size={NCButtonSize.standard.size}>
                                        <Tooltip title="Change image">
                                            <IconButton onClick={(event) => setImageSearchPopoverAnchorEl(event.currentTarget)} size={NCButtonSize.standard.iconButtonSize} >
                                                <ImageSearchOutlined fontSize={NCButtonSize.standard.iconFontSize} />
                                            </IconButton>
                                        </Tooltip>
                                    </NeutralCircleButton>
                                </Box>
                            )}
                        </Box>
                    </ClickAwayListener>
                </InElementBox>
            )}
            <SimpleCloseDialog open={!!imageSearchPopoverAnchorEl} handleClose={() => setImageSearchPopoverAnchorEl(null)}
                title='Media' buttonCaption='Close'>
                <ImageInputTabs handleNewImageSelection={handleNewImageSelection} />
            </SimpleCloseDialog>
        </div>
    )
}

export default ImageDisplay
