import { useState } from 'react'
import Box from '@mui/material/Box'
import Layouts from './Layouts'
import SlideActions from './SlideActions'
import SimpleCloseDialog from '../helpers/SimpleCloseDialog'
import Grow from '@mui/material/Grow'
import { DragDropContext, Droppable, Draggable, DropResult } from "@hello-pangea/dnd"
import MiniSlide from '../helpers/MiniSlide'
import NeutralCircleButton from '../helpers/NeutralCircleButton'
import Typography from '@mui/material/Typography'
import { NCButtonSize } from '../../lib/style'


type SlideListProps = {
  handleSlideClick: IdClickFunction, slides: Slides, currentSlideId: string | undefined, currentPresentation: SinglePresentation | undefined,
  handleDeleteSlideClick: IdClickFunction, handleLayoutClick: IdClickFunction, currentMaster: SingleMaster | undefined,
  handleSlideDownload: (slideId: string | undefined, presentationId: string | undefined) => void,
  handlePresentationChange: PresentationChangeFunction,
  handleSlideCopy: IdClickFunction,
  handleSlidePaste?: () => void,
  handleSlideDuplicate: () => void
}

function SlideList({ handleSlideClick, slides, currentSlideId, currentPresentation, handleDeleteSlideClick, handleLayoutClick, currentMaster, handleSlideDownload, handlePresentationChange, handleSlideCopy, handleSlidePaste, handleSlideDuplicate }: SlideListProps) {

  // local state
  const [layoutDialogOpen, setLayoutDialogOpen] = useState(false)

  // shortcuts
  const currentSlideIdList = currentPresentation && currentPresentation.slideIds.filter(slideId => slideId in slides)

  // functions
  function onDragEnd(dropResult: DropResult) {
    if (!dropResult.destination) {
      return
    }

    if (currentPresentation && currentSlideIdList) {
      var newSlideIdList = [...currentSlideIdList]
      var slideIdToMove = newSlideIdList[dropResult.source.index]
      newSlideIdList.splice(dropResult.source.index, 1)
      newSlideIdList.splice(dropResult.destination.index, 0, slideIdToMove)

      const newPresentation: SinglePresentation = { ...currentPresentation, slideIds: newSlideIdList }
      handlePresentationChange(newPresentation, false)
    }
  }

  return (
    <Box sx={{ width: 1, display: 'flex', overflow: 'hidden', flexGrow: 1 }} >
      <SimpleCloseDialog handleClose={() => setLayoutDialogOpen(false)} open={layoutDialogOpen} title={"Select Layout for current slide"} width={"50vw"} buttonCaption={"Close"}>
        <Layouts handleLayoutClick={handleLayoutClick} currentMaster={currentMaster}
          currentLayoutId={(currentSlideId && slides[currentSlideId] && currentMaster?.logicalMaster.masterId) ? slides[currentSlideId].logicalSlide.layoutIdByMaster[currentMaster?.logicalMaster.masterId] : 0} />
      </SimpleCloseDialog>

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <Box {...provided.droppableProps} ref={provided.innerRef} sx={{ width: 1, overflow: 'auto', p: 1 }}>
              {currentPresentation && currentSlideIdList && currentSlideIdList.map((slideId: string, i: number) => (
                <Draggable key={slideId} draggableId={slideId} index={i}>
                  {(provided, snapshot) => (
                    <Box ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}
                      sx={{ width: 1, position: "relative", display: 'grid', gridTemplateColumns: '1fr', justifyContent: "flex-end", alignItems: 'flex-end' }} key={String(i)} >

                      {/* Slide Actions */}
                      <Grow in={slideId === currentSlideId} >
                        <Box sx={{ gridColumnStart: 2, gridRowStart: 2, zIndex: 1, pb: '13px', pr: '6px', width: '30px' }} className='slide_actions_button'>
                          <SlideActions currentSlideId={slideId} currentPresentation={currentPresentation} handleDeleteSlideClick={handleDeleteSlideClick} openLayout={() => setLayoutDialogOpen(true)}
                            handleSlideDownload={handleSlideDownload} handleSlideCopy={handleSlideCopy} handleSlidePaste={handleSlidePaste} handleSlideDuplicate={handleSlideDuplicate} />
                        </Box>
                      </Grow>

                      {/* Slide Number */}
                      <Box sx={{ gridColumnStart: 1, gridRowStart: 2, zIndex: 1, pb: '13px', pl: '6px', width: '30px', cursor: 'pointer' }}
                        onClick={() => handleSlideClick(slideId)}>
                        <NeutralCircleButton size={NCButtonSize.tiny.size}>
                          <Typography variant="caption">{i + 1}</Typography>
                        </NeutralCircleButton>
                      </Box>


                      {/* Slide Image */}
                      <Box sx={{ width: 1, zIndex: 0, mb: 1, gridColumnStart: 1, gridRowStart: 1, gridColumnEnd: 'span 2', gridRowEnd: 'span 2' }} className='slide_image'>
                        <MiniSlide
                          onClickFunction={handleSlideClick}
                          // title={slides[slideId].logicalSlide.title}
                          thisId={slides[slideId].logicalSlide.slideId}
                          selectedId={currentSlideId}
                          renderDetail={slides[slideId].renderDetail}
                        />
                      </Box>
                    </Box>
                  )}
                </Draggable>
              ))}
            </Box>
          )}
        </Droppable>
      </DragDropContext>
    </Box>
  )
}

export default SlideList

