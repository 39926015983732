export function toLongHex(hexcolor: string) {

  let longHex
  if (hexcolor.length === 4) {
    longHex = "#" + hexcolor.slice(1, 4).split('').map(function (hex) {
      return hex + hex
    }).join('')
  } else if (hexcolor.length === 3) {
    longHex = "#" + hexcolor.split('').map(function (hex) {
      return hex + hex
    }).join('')
  } else longHex = hexcolor

  return longHex
}

// Extract RGB components from a color string (supports hex and rgba)
export function getRGBComponents(color: string): [number, number, number] {
  if (color.startsWith('rgba') || color.startsWith('rgb')) {
    // Match rgba or rgb strings
    const match = color.match(/\d+(\.\d+)?/g)
    if (match) {
      const [r, g, b] = match.map(Number)
      return [r, g, b]
    }
  } else if (color.startsWith('#')) {
    // Convert hex to RGB
    const safeColor = toLongHex(color)
    const [r, g, b] = safeColor.match(/\w\w/g)!.map((c) => parseInt(c, 16))
    return [r, g, b]
  }
  throw new Error(`Invalid color format: ${color}`)
}

// Blend two colors together by an amount (0 to 1)
export function blendColors(colorA: string, colorB: string, amount: number): string {
  const [rA, gA, bA] = getRGBComponents(colorA)
  const [rB, gB, bB] = getRGBComponents(colorB)

  const r = Math.round(rA + (rB - rA) * amount).toString(16).padStart(2, '0')
  const g = Math.round(gA + (gB - gA) * amount).toString(16).padStart(2, '0')
  const b = Math.round(bA + (bB - bA) * amount).toString(16).padStart(2, '0')

  return `#${r}${g}${b}`
}

export function hexToRgba(hex: string, a: number) {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
  hex = hex.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b
  })

  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result ?
    "rgba(" + String(parseInt(result[1], 16)) + ","
    + String(parseInt(result[2], 16)) + ","
    + String(parseInt(result[3], 16)) + "," + String(a) + ")"
    : undefined
}

export function isValidHexColor(color: string, allowShorthand: boolean) {
  let hexColorPattern = /^#?([0-9a-fA-F]{6})$/
  if (allowShorthand) {
    // Regex to match 3 or 6 digit hex color, with or without leading '#'
    hexColorPattern = /^#?([0-9a-fA-F]{3}){1,2}$/
  }

  return hexColorPattern.test(color)
}