import { numeration1Presets } from './numeration1/presets'
import { textbox1Presets } from './textbox1/presets'
import { table1Presets } from './table1/presets'
import { icongrid1Presets } from './icongrid1/presets'
import { timeline1Presets } from './timeline1/presets'
import TopicIconTopWith3BoxesIcon from './icons/TopicIconTopWith3BoxesSvgIcon'
import NumerationSvgIcon from './icons/NumerationSvgIcon'
import TextboxIcon from './icons/TextBoxSvgIcon'
import TableIcon from './icons/TableSvgIcon'
import IconGridIcon from './icons/IconGridSvgIcon'
import IconIcon from './icons/IconSvgIcon'
import ProgressBarIcon from './icons/ProgressBarSvgIcon'
import HarveyBallIcon from './icons/HarveyBallSvgIcon'
import SituComplResIcon from './icons/SituComplResSvgIcon'
import TimelineIcon from './icons/TimelineSvgIcon'
import TopicIconLeftWith3BoxesIcon from './icons/TopicIconLeftWith3BoxesSvgIcon'
import ImageIcon from '@mui/icons-material/Image'
import CropDinIcon from '@mui/icons-material/CropDin'
import { Notes, Title } from '@mui/icons-material'
import { image1Presets } from './image1/presets'
import { frame1Presets } from './frame1/presets'

export const numeration1InitItemWordCount = 6

export const defaultIcon = { provider: 'NP', providerId: '698181', 'thumbnailUrl': 'https://static.thenounproject.com/png/698181-200.png', 'id': 'NP_698181' }
export const textBoxDefaultIconSettings: TextBoxIconSettings = { iconVisible: true, iconBox: { iconSource: defaultIcon }, iconPosition: 'TOPRIGHT', iconSize: 4 }
export const tableCellDefaultIconSettings: TableCellIconSettings = { visible: false, iconSource: defaultIcon, position: 'CENTERCENTER', size: 2 }

export const elementPresets: Record<ElementType, Record<string, ElementPreset>> = {
  'TITLE': {},
  'NUMERATION1': numeration1Presets,
  'TABLE1': table1Presets,
  'TEXTBOX1': textbox1Presets,
  'TIMELINE1': timeline1Presets,
  'ICONGRID1': icongrid1Presets,
  'IMAGE1': image1Presets,
  'FRAME1': frame1Presets,
}

export const elementPresetsLists: Record<ElementType, ElementPreset[]> = transform(elementPresets)

export const elementDefinitions: ElementDefinition[] = [
  {
    icon: <NumerationSvgIcon htmlColor={'inherit'} style={{ fontSize: "inherit" }} />,
    tooltip: 'Numbered list',
    title: 'Numbered List',
    preset: elementPresets.NUMERATION1.FRENCH_PRESS_5,
    fontSizeCorrection: 1.7,
    zoomFactor: 2
  },
  {
    icon: <TextboxIcon htmlColor={'inherit'} style={{ fontSize: "inherit" }} />,
    tooltip: 'Textbox with optional icons',
    title: 'Textbox',
    preset: elementPresets.TEXTBOX1.DEATH_STAR,
    fontSizeCorrection: 1.6,
    zoomFactor: 2
  },
  {
    icon: <ImageIcon htmlColor={'inherit'} style={{ fontSize: "inherit" }} />,
    tooltip: 'Image',
    title: 'Image',
    preset: elementPresets.IMAGE1.IMAGE,
    fontSizeCorrection: 1.,
    zoomFactor: 1.5
  },
  {
    icon: <TableIcon htmlColor={'inherit'} style={{ fontSize: "inherit" }} />,
    tooltip: 'Multifunctional table with icons & infographics',
    title: 'Multifunctional Table',
    preset: elementPresets.TABLE1.PROJECT_OVERVIEW_7x6,
    fontSizeCorrection: 1.7,
    zoomFactor: 3
  },
  {
    icon: <TimelineIcon style={{ fontSize: "inherit" }} />,
    tooltip: 'Timeline with Milestones',
    title: 'Timeline',
    preset: elementPresets.TIMELINE1.TIMELINE1_5,
    fontSizeCorrection: 1.7,
    zoomFactor: 3
  },
  {
    icon: <SituComplResIcon style={{ fontSize: "inherit" }} />,
    tooltip: 'Boxes with dependencies/flow',
    title: 'Boxes With Arrows',
    preset: elementPresets.TABLE1.SITUATION_COMPLICATION_RESOLUTION,
    fontSizeCorrection: 1.55,
    zoomFactor: 3.5
  },
  {
    icon: <TopicIconTopWith3BoxesIcon style={{ fontSize: "inherit" }} />,
    tooltip: 'Topic icon at top with detail boxes',
    title: 'Icon w/ Detail Boxes',
    preset: elementPresets.TABLE1.TOPIC_ICON_TOP_3,
    fontSizeCorrection: 1.8,
    zoomFactor: 3.5
  },
  {
    icon: <TopicIconLeftWith3BoxesIcon style={{ fontSize: "inherit" }} />,
    tooltip: 'Topic icon left with detail boxes',
    title: 'Icon w/ Detail Boxes',
    preset: elementPresets.TABLE1.TOPIC_ICON_LEFT_3,
    fontSizeCorrection: 1.6,
    zoomFactor: 3.5
  },
  {
    icon: <IconIcon style={{ fontSize: "inherit" }} />,
    tooltip: 'Icon with optional subtext',
    title: 'Icon',
    preset: elementPresets.ICONGRID1.SINGLE,
    fontSizeCorrection: 1.5,
    zoomFactor: 2
  },
  {
    icon: <IconGridIcon style={{ fontSize: "inherit" }} />,
    tooltip: 'Icon grid with optional subtext',
    title: 'Icon Grid',
    preset: elementPresets.ICONGRID1.GRID_2x2,
    fontSizeCorrection: 1.8,
    zoomFactor: 3.5
  },
  {
    icon: <HarveyBallIcon style={{ fontSize: "inherit" }} />,
    tooltip: 'Harvey Ball with optional subtext',
    title: 'Harvey Ball',
    preset: elementPresets.TABLE1.HARVEY_BALL_W_TEXT_1x1,
    fontSizeCorrection: 1.5,
    zoomFactor: 2
  },
  {
    icon: <ProgressBarIcon style={{ fontSize: "inherit" }} />,
    tooltip: 'Progress bar with optional subtext',
    title: 'Progress Bar',
    preset: elementPresets.TABLE1.PROGRESS_BAR_W_TEXT_1x1,
    fontSizeCorrection: 1.5,
    zoomFactor: 2
  },
  {
    icon: <Notes style={{ fontSize: "inherit" }} />,
    tooltip: 'Just simple text',
    title: 'Text',
    preset: elementPresets.TABLE1.TEXT_1x1,
    fontSizeCorrection: 1.,
    zoomFactor: 1
  },
  {
    icon: <Title style={{ fontSize: "inherit" }} />,
    tooltip: 'A simple text title',
    title: 'Title',
    preset: elementPresets.TABLE1.TITLE_1x1,
    fontSizeCorrection: 0.8,
    zoomFactor: 1
  },
  {
    icon: <CropDinIcon htmlColor={'inherit'} style={{ fontSize: "inherit" }} />,
    tooltip: 'Frame',
    title: 'Frame',
    preset: elementPresets.FRAME1.FRAME,
    fontSizeCorrection: 1.6,
    zoomFactor: 2
  },
]


type PresetsByElTypeType = Record<ElementType, Record<string, ElementPreset>>
type PresetsListsByElTypeType = Record<ElementType, ElementPreset[]>
function transform(input: PresetsByElTypeType): PresetsListsByElTypeType {
  const transformed: PresetsListsByElTypeType = {} as PresetsListsByElTypeType

  for (const enumKey in input) {
    if (Object.prototype.hasOwnProperty.call(input, enumKey)) {
      transformed[enumKey as ElementType] = Object.values(input[enumKey as ElementType])
    }
  }

  return transformed
}