import { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import PaletteIcon from '@mui/icons-material/Palette'
import SimpleCloseDialog from '../helpers/SimpleCloseDialog'
import Masters from '../masters/Masters'
import StyleSelection from './StyleSelection'
import Divider from '@mui/material/Divider'
import MenuItem from '@mui/material/MenuItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import DownloadIcon from '@mui/icons-material/Download'
import ShapeLineIcon from '@mui/icons-material/ShapeLine'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import Tooltip from '@mui/material/Tooltip'
import ContextMenu from '../helpers/ContextMenu'
import PresentationTypography from './PresentationTypography'
import TextFormatIcon from '@mui/icons-material/TextFormat'
import { getFontSizeValues, fontSizeToFactor } from '../../lib/font'
import { hasValidSubscription, getCurrentAuthenticatedUser, getSubBaseInfo } from '../../lib/auth'
import _ from 'lodash'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import DownloadDeniedDialog from '../helpers/DownloadDeniedDialog'
import { AmplifyUser } from '@aws-amplify/ui'
import { LinearProgress, TextField, Typography, useTheme } from '@mui/material'
import { AutoAwesome, ContentCopy } from '@mui/icons-material'
import ProcessingMessages from '../helpers/ProcessingMessages'
import { promptingEnabledForAll } from '../../lib/constants'

type PresentationActionProps = {
  handleAddSlideClick: Function,
  handleMasterClick: IdClickFunction, masters: Masters,
  handleDeletePresentationClick: Function,
  currentMaster: SingleMaster | undefined,
  currentPresentation: SinglePresentation | undefined,
  handlePresentationDownload: (presentationId: string | undefined) => void,
  handlePresentationChange: PresentationChangeFunction,
  loadingState: LoadingState,
  setPresentationPublishedFromCommon?: (published: boolean) => void
  handlePresentationDuplicate: () => void,
  handleCreateSlidePromptProcessing: (userPrompt: string) => Promise<void>
}
function PresentationActions({ handleAddSlideClick, handleMasterClick, masters, handleDeletePresentationClick, currentMaster, handleCreateSlidePromptProcessing,
  handlePresentationDownload, currentPresentation, handlePresentationChange, loadingState, setPresentationPublishedFromCommon, handlePresentationDuplicate }: Readonly<PresentationActionProps>) {
  const theme = useTheme()

  // local state
  const [masterDialogOpen, setMasterDialogOpen] = useState(false)
  const [aiPromptingAvailable, setAiPromptingAvailable] = useState(true)
  const [createSlideAiAssistDialogOpen, setCreateSlideAiAssistDialogOpen] = useState(false)
  const [aiPromptValue, setAiPromptValue] = useState('')
  const [createSlideAiAssistProcessing, setCreateSlideAiAssistProcessing] = useState(false)
  const [subscriptionHintOpen, setSubscriptionHintOpen] = useState(false)
  const [styleDialogOpen, setStyleDialogOpen] = useState(false)
  const [typographyDialogOpen, setTypographyDialogOpen] = useState(false)
  const [currentUser, setCurrentUser] = useState<AmplifyUser | undefined>(undefined)
  const currentMasterId = currentMaster ? currentMaster.logicalMaster.masterId : undefined
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null)
  const menuOpen = Boolean(menuAnchorEl)

  const fontSizeValues = getFontSizeValues(currentMaster, currentPresentation)

  useEffect(() => {
    async function verifyPromptingFeatureAccess() {
      const user = await getCurrentAuthenticatedUser()
      const promptingEnabled = (getSubBaseInfo(user)?.promptingEnabled ?? false) || promptingEnabledForAll

      setAiPromptingAvailable(promptingEnabled)
    }

    verifyPromptingFeatureAccess()
  }, [])

  //functions
  const handlePromptInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAiPromptValue(event.target.value)
  }

  const handleCreateSlideAIPromptClick = async () => {
    setCreateSlideAiAssistProcessing(true)
    await handleCreateSlidePromptProcessing(aiPromptValue)
    setCreateSlideAiAssistProcessing(false)
    setCreateSlideAiAssistDialogOpen(false)
  }

  const handleOptionsClick = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchorEl(event.currentTarget)
  }
  const handleOptionsClose = () => {
    setMenuAnchorEl(null)
  }

  // handle presentation style change
  const handlePresentationBasicStyleChange: BasicStyleChangeFunction = (newStyle) => {
    if (currentPresentation && !_.isEqual(currentPresentation.basicStyle, newStyle) && newStyle !== null) {
      const newPresentation = { ...currentPresentation, basicStyle: newStyle }
      handlePresentationChange(newPresentation, true)
    }
  }

  // handle presentation Text mode change
  const handlePresentationFontSizeModeChange: FontSizeModeChangeFunction = (newFontSizeMode) => {
    if (currentPresentation) {
      const newPresentation = { ...currentPresentation, fontSizeMode: newFontSizeMode }
      handlePresentationChange(newPresentation, true)
    }
  }

  // handle presentation body base font size change
  const handlePresentationBaseFontSizeChange: NumberVoidFunction = (fontSizePt) => {
    const newFontSizeFactor = fontSizeToFactor(currentMaster?.logicalMaster.slideHeight, fontSizePt)
    if (currentPresentation && currentMaster && newFontSizeFactor) {
      const newPresentation = { ...currentPresentation, elementBodyFontFactor: newFontSizeFactor }
      handlePresentationChange(newPresentation, true)
    }
  }

  const onDownload = async () => {
    const newCurrentUser = await getCurrentAuthenticatedUser()
    setCurrentUser(newCurrentUser)
    if (hasValidSubscription(newCurrentUser)) {
      handlePresentationDownload(currentPresentation?.presentationId)
      window.gtag("event", "select_content", { content_type: "presentation_download_granted" })
    }
    else {
      setSubscriptionHintOpen(true)
      window.gtag("event", "select_content", { content_type: "presentation_download_denied" })
    }
  }

  return (
    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: 1, flexWrap: "wrap" }}>

      {/* Menu */}
      <ContextMenu transformOrigin={{ horizontal: 'left', vertical: 'top' }} anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        handleClose={handleOptionsClose} menuAnchorEl={menuAnchorEl} menuOpen={menuOpen} pointerPosition='left'
      >
        <MenuItem onClick={(event) => { setMasterDialogOpen(true) }}>
          <ListItemIcon >
            <PaletteIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Theme</ListItemText>
          {/* <Typography variant="body2" color="text.secondary" pl={2}>
            ⌘M
          </Typography> */}
        </MenuItem>
        <MenuItem onClick={(event) => { setStyleDialogOpen(true) }}>
          <ListItemIcon>
            <ShapeLineIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Style</ListItemText>
          {/* <Typography variant="body2" color="text.secondary" pl={2}>
            ⌘S
          </Typography> */}
        </MenuItem>
        <MenuItem onClick={(event) => { setTypographyDialogOpen(true) }}>
          <ListItemIcon>
            <TextFormatIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Typography</ListItemText>
          {/* <Typography variant="body2" color="text.secondary" pl={2}>
            ⌘T
          </Typography> */}
        </MenuItem>
        <MenuItem onClick={onDownload}>
          <ListItemIcon>
            <DownloadIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Download</ListItemText>
          {/* <Typography variant="body2" color="text.secondary" pl={2}>
            ⌘D
          </Typography> */}
        </MenuItem>
        {setPresentationPublishedFromCommon && <Divider />}
        {setPresentationPublishedFromCommon &&
          <MenuItem onClick={(event) => setPresentationPublishedFromCommon(!currentPresentation?.publishedFromCommon)}>
            <ListItemIcon >
              {!!currentPresentation?.publishedFromCommon ?
                <CheckCircleOutlineIcon fontSize="small" color="warning" />
                :
                <RadioButtonUncheckedIcon fontSize="small" color="warning" />}
            </ListItemIcon>
            {!!currentPresentation?.publishedFromCommon ?
              <ListItemText>Unpublish from common</ListItemText>
              :
              <ListItemText>Publish from common (!)</ListItemText>
            }
          </MenuItem>
        }
        <Divider />
        <MenuItem onClick={(event) => { handlePresentationDuplicate() }}>
          <ListItemIcon>
            <ContentCopy fontSize="small" />
          </ListItemIcon>
          <ListItemText>Duplicate</ListItemText>
          {/* <Typography variant="body2" color="text.secondary" pl={2}>
            ⌘T
          </Typography> */}
        </MenuItem>
        <Divider />
        <MenuItem onClick={(event) => handleDeletePresentationClick()}>
          <ListItemIcon >
            <DeleteForeverIcon fontSize="small" color="error" />
          </ListItemIcon>
          <ListItemText>Delete Presentation</ListItemText>
        </MenuItem>
      </ContextMenu>


      {/* Buttons */}
      <Tooltip title="Add slide">
        <IconButton onClick={(event) => { handleAddSlideClick() }} sx={{}} size="medium" >
          <AddCircleIcon fontSize="medium" sx={{ color: theme.palette.primary.main }} />
        </IconButton>
      </Tooltip>
      {aiPromptingAvailable &&
        <Tooltip title="Create slide with AI">
          <IconButton onClick={(event) => setCreateSlideAiAssistDialogOpen(true)} sx={{}} size="medium" >
            <AutoAwesome fontSize="medium" sx={{ color: theme.palette.primary.main }} className='create_slide_button' />
          </IconButton>
        </Tooltip>
      }
      <Tooltip title="Presentation options">
        <IconButton onClick={handleOptionsClick} sx={{}} size="medium" className='presentation_actions_button'>
          <MoreVertIcon fontSize="medium" />
        </IconButton>
      </Tooltip>

      {/* Dialogs */}
      <SimpleCloseDialog handleClose={() => setStyleDialogOpen(false)} open={styleDialogOpen} title={"Select style for current presentation"} width={"400px"} buttonCaption={"Close"}>
        <StyleSelection handleStyleChange={handlePresentationBasicStyleChange} currentPresentationStyle={currentPresentation?.basicStyle} />
      </SimpleCloseDialog>
      <SimpleCloseDialog handleClose={() => setMasterDialogOpen(false)} open={masterDialogOpen} title={"Select theme for current presentation"} width={"50vw"} buttonCaption={"Close"}>
        <Masters handleMasterClick={handleMasterClick} masters={masters} currentMasterId={currentMasterId} mastersInAnalysis={loadingState.MASTERANALYSIS} />
      </SimpleCloseDialog>
      <SimpleCloseDialog handleClose={() => setTypographyDialogOpen(false)} open={typographyDialogOpen} title={"Presentation text settings"} width={"50vw"} buttonCaption={"Close"}>
        <PresentationTypography handleFontSizeModeChange={handlePresentationFontSizeModeChange} currentFontSizeMode={currentPresentation?.fontSizeMode}
          handlePresentationBaseFontSizeChange={handlePresentationBaseFontSizeChange} currentFontSizePt={fontSizeValues.currentFontSizePt}
          maxFontSizePt={fontSizeValues.maxFontSizePt} minFontSizePt={fontSizeValues.minFontSizePt} />
      </SimpleCloseDialog>
      <DownloadDeniedDialog currentUser={currentUser} setSubscriptionHintOpen={setSubscriptionHintOpen} subscriptionHintOpen={subscriptionHintOpen} />

      {/* AI Slide Creation dialog  */}
      <SimpleCloseDialog handleClose={() => setCreateSlideAiAssistDialogOpen(false)} open={createSlideAiAssistDialogOpen} title={"Create slide with Protoslides AI"} width={"50vw"} buttonCaption={"Close"}>
        <Box sx={{ display: "flex", alignItems: "center", width: 1, flexWrap: 'wrap', justifyContent: 'center', pt: 1 }}>
          <TextField multiline fullWidth value={aiPromptValue} onChange={handlePromptInput}
            placeholder='Create a slide about ...' sx={{ flexGrow: 1, width: 1 }}
            disabled={createSlideAiAssistProcessing} maxRows={10} label="Slide content" />
          <Tooltip title="Create Slide">
            <IconButton
              onClick={() => (handleCreateSlideAIPromptClick())}
              color="primary" aria-label="send" sx={{ mt: 2 }} disabled={createSlideAiAssistProcessing}
            >
              <AutoAwesome sx={{ fontSize: '60px' }} />
            </IconButton>
          </Tooltip>
          {!createSlideAiAssistProcessing &&
            <Typography variant="overline" color="textSecondary" sx={{ width: 1, textAlign: 'center', mt: 0 }}>
              Create Slide
            </Typography>
          }
        </Box>
        <Box sx={{ width: 1, mt: 2 }}></Box>
        {createSlideAiAssistProcessing && <Box sx={{ width: 1, mt: 4, mb: 2, display: 'flex', justifyContent: 'center', flexWrap: 'wrap', textAlign: 'center' }}>
          <ProcessingMessages
            messages={['Collecting content...', 'Creating slide...', 'Generating slide structure...', 'Adding individual elements...', 'Prepare rendering...', 'Finalizing.', 'Finalizing..', 'Finalizing...', 'Finalizing....', 'Finalizing.....']}
            interval={5000} // Change every 5 seconds
            typographyVariant="h6"
            typographyColor="textSecondary"
          />
          <LinearProgress sx={{ width: 1, mt: 2 }} />
        </Box>}
      </SimpleCloseDialog>
    </Box>
  )
}

export default PresentationActions

