
export const image1Presets: Record<string, ElementPreset> = {
    IMAGE:
    {
        displayName: 'Image',
        type: 'IMAGE1',
        "box": {
            "height": 0.50,
            "left": 0.080,
            "top": 0.28,
            "width": 0.34
        },
        "content": {
        }

    }
}