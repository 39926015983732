import React from 'react'
import { Box, Typography, useTheme } from '@mui/material'
import { hexToRgba } from '../../lib/colors'

interface BackgroundProps {
    showLogo: boolean
}

const Background: React.FC<BackgroundProps> = ({ showLogo }) => {
    const theme = useTheme()

    return (
        <Box sx={{ position: 'absolute', width: '100vw', height: '100vh', zIndex: -10, display: 'grid', gridTemplateColumns: '1fr', overflow: 'hidden' }}>
            {/* Background with Typography */}
            {showLogo && (
                <Box
                    sx={{
                        width: 1,
                        height: 1,
                        zIndex: -2,
                        gridColumnStart: 1,
                        gridRowStart: 1,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Typography
                        variant="h1"
                        component="div"
                        sx={{
                            backgroundColor: theme.palette.primary.main,
                            borderRadius: '40px',
                            borderTopRightRadius: '0px',
                            borderBottomLeftRadius: '0px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontStyle: 'italic',
                            opacity: 0.3,
                            color: theme.palette.background.default,
                            fontWeight: 1000,
                            mt: 0,
                            pl: 2,
                            pr: 3.2,
                            pb: 1.2,
                            pt: 1.2,
                        }}
                    >
                        P
                    </Typography>
                </Box>
            )}

            {/* Radial Gradient Background */}
            <Box
                sx={{
                    gridColumnStart: 1,
                    gridRowStart: 1,
                    width: '110%',
                    zIndex: -1,
                    position: 'absolute',
                    left: '-5%',
                    top: 0,
                    height: '100%',
                    backgroundImage: `radial-gradient(${hexToRgba(theme.palette.primary.main, 0.2)}, transparent 75%)`,
                }}
            ></Box>
        </Box>
    )
}

export default Background
