import { FC, useEffect } from 'react'
import { useState } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import Masters from '../masters/Masters'
import { Box } from '@mui/system'
import TextField from '@mui/material/TextField'
import { IconButton, LinearProgress, Paper, Step, StepLabel, Stepper, Tooltip, Typography } from '@mui/material'
import ProcessingMessages from '../helpers/ProcessingMessages'
import { useLocation } from 'react-router-dom'
import { AutoAwesome } from '@mui/icons-material'
import MiniSlide from '../helpers/MiniSlide'

interface DialogProps {
  masters: Masters, handleClose: () => void,
  createPresentationAiAssist: (title: string, masterId: string, userPrompt: string) => Promise<void>,
  open: boolean, title: string, width: string | null, closeButtonCaption: string,
  loadingState: LoadingState
}

const minHeight = '370px'

const PresentationCreateDialog: FC<DialogProps> = ({ masters, handleClose, createPresentationAiAssist, open, title, width, closeButtonCaption, loadingState }) => {

  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

  const location = useLocation()
  const promptFromLanding = location.state?.promptFromLanding || ""

  // local state
  const [masterId, setMasterId] = useState<string | undefined>(undefined)
  const [presentationTitle, setPresentationTitle] = useState("")
  const [createPresentationAiAssistProcessing, setCreatePresentationAiAssistProcessing] = useState(false)
  const [aiPromptValue, setAiPromptValue] = useState(promptFromLanding)

  // state
  const [activeStep, setActiveStep] = useState(0)

  const steps = ['Step 1: Describe content', 'Step 2: Select theme', 'Step 3: Create presentation']

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      setActiveStep(0)
    }
    else setActiveStep((prevActiveStep) => prevActiveStep + 1)
    if (presentationTitle === '') setPresentationTitle('My Presentation')
  }

  const handleBack = () => {
    if (activeStep > 0) setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  // Master preselection hook
  useEffect(() => {
    setMasterId(Object.keys(masters)[0])
  }, [masters])

  //functions
  const handlePromptInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAiPromptValue(event.target.value)
  }

  // handle master select click
  const handleMasterSelect = (masterId: string | undefined) => {
    if (masterId) setMasterId(masterId)
  }

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPresentationTitle(event.target.value)
  }

  const handleCreatePresentationAIPromptClick = async () => {
    var title = presentationTitle
    if (title === "") title = "New Presentation"
    if (masterId) {
      setCreatePresentationAiAssistProcessing(true)
      await createPresentationAiAssist(title, masterId, aiPromptValue)
      setCreatePresentationAiAssistProcessing(false)
      setActiveStep(0)
      handleClose()
    }
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={() => { setActiveStep(0); handleClose() }}
      aria-labelledby="responsive-dialog-title"
      maxWidth="xl"
    >
      <DialogTitle id="responsive-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent>

        <Box sx={{ width: { md: (width || 1) }, m: 'auto', pt: 2 }}>

          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label, index) => (
              <Step key={index}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>

          <Box sx={{ width: { xs: 1, sm: 1, md: 0.9, lg: 0.85, xl: 0.85 }, height: 1, pt: 4, display: 'flex', flexWrap: 'wrap', alignContent: 'space-between', alignItems: 'stretch', m: 'auto' }}>
            {activeStep === 0 && (
              <Box sx={{ width: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: minHeight, flexWrap: 'wrap', alignContent: 'center' }}>
                <Box sx={{ width: 1, display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                  <TextField sx={{ mb: 4 }} fullWidth label="Presentation title" variant="standard" placeholder='My Presentation'
                    onChange={handleTitleChange} value={presentationTitle} autoFocus disabled={createPresentationAiAssistProcessing} />
                </Box>
                <Box sx={{ width: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <TextField multiline fullWidth value={aiPromptValue} onChange={handlePromptInput}
                    placeholder='Create a presentation with 4 slides about ...' sx={{ flexGrow: 1 }}
                    helperText={aiPromptValue === '' ? "Define presentation content" : undefined}
                    disabled={createPresentationAiAssistProcessing} maxRows={10} label="Presentation content" />
                </Box>
              </Box>
            )}
            {activeStep === 1 &&
              <Box sx={{ width: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap', minHeight: minHeight }}>
                <Box sx={{ width: 1, maxHeight: 300, overflowY: 'auto', display: 'flex', justifyContent: 'center' }} >
                  <Masters handleMasterClick={handleMasterSelect} masters={masters} currentMasterId={masterId} mastersInAnalysis={loadingState.MASTERANALYSIS} />
                </Box>
              </Box>
            }
            {activeStep === 2 &&
              <Box sx={{ width: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap', minHeight: minHeight }}>
                <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-around', alignItems: 'center', flexWrap: 'wrap' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', mr: 4 }}>
                    <Paper elevation={0}
                      sx={{
                        height: '150px',
                        padding: "8px",
                        borderRadius: "4px",
                        backgroundColor: 'transparent',
                        mb: 3,
                        ml: 3,
                        aspectRatio: (masterId && masterId in masters) ? masters[masterId].logicalMaster.aspectRatio : undefined
                      }}
                    >
                      <Typography variant="h6" color="textSecondary" sx={{ mb: 1, display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: 1, lineHeight: '1.4', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                        {presentationTitle}
                      </Typography>
                      <Typography variant="body1" color="textSecondary" sx={{ display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: 5, lineHeight: '1.4', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'pre-wrap' }}>
                        {aiPromptValue}
                      </Typography>
                    </Paper>
                  </Box>
                  <Box sx={{ height: '150px', mb: 3, display: 'flex', alignItems: 'center', pointerEvents: 'none', aspectRatio: (masterId && masterId in masters) ? masters[masterId].logicalMaster.aspectRatio : undefined }}>
                    <MiniSlide
                      renderDetail={(masterId && masterId in masters && masters[masterId].logicalMaster.defaultLayout !== undefined && masters[masterId].logicalMaster.defaultLayout < masters[masterId].layoutRenderDetail.length) ?
                        masters[masterId].layoutRenderDetail[masters[masterId].logicalMaster.defaultLayout] : (masterId ? masters[masterId].layoutRenderDetail[0] : undefined)}
                    />
                  </Box>
                </Box>

                <Tooltip title="Create Presentation">
                  <IconButton
                    onClick={() => (handleCreatePresentationAIPromptClick())}
                    color="primary" aria-label="send" sx={{ mt: 2 }} disabled={createPresentationAiAssistProcessing || masterId === undefined}
                  >
                    <AutoAwesome sx={{ fontSize: '60px' }} />
                  </IconButton>
                </Tooltip>
                {!createPresentationAiAssistProcessing &&
                  <Typography variant="overline" color="textSecondary" sx={{ width: 1, textAlign: 'center', mt: -2 }}>
                    Create Presentation
                  </Typography>
                }
                {createPresentationAiAssistProcessing && <Box sx={{ width: 1, mt: 4, mb: 2, display: 'flex', justifyContent: 'center', flexWrap: 'wrap', textAlign: 'center' }}>
                  <ProcessingMessages
                    messages={['Writing storyline...', 'Creating Presentation...', 'Generating slide structures...', 'Adding individual elements...', 'Prepare rendering...', 'Finalizing.', 'Finalizing..', 'Finalizing...', 'Finalizing....', 'Finalizing.....']}
                    interval={5000} // Change every 5 seconds
                    typographyVariant="h6"
                    typographyColor="textSecondary"
                  />
                  <LinearProgress sx={{ width: 1, mt: 2 }} />
                </Box>}
              </Box>
            }

          </Box>
          <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between', mt: 4 }}>
            <Button disabled={activeStep === 0 || createPresentationAiAssistProcessing} onClick={handleBack} variant="outlined">
              Back
            </Button>
            <Button
              variant="contained"
              onClick={handleNext}
              disabled={(activeStep === steps.length - 1) || aiPromptValue === ''}
              sx={{ display: activeStep === steps.length - 1 ? 'none' : 'block' }}
            >
              {'Next'}
            </Button>
          </Box>
        </Box>

      </DialogContent>
      <DialogActions>
        <Button onClick={() => { setActiveStep(0); handleClose() }} autoFocus>
          {closeButtonCaption}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default PresentationCreateDialog