import Box from '@mui/material/Box'
import PresentationList from '../presentations/PresentationList'
import SlideList from '../slides/SlideList'
import GlobalActions from '../GlobalActions'
import PresentationActions from '../presentations/PresentationActions'
import { useAppSelector, useAppDispatch } from '../../store/hooks'
import {
  getPresentationPanelVisible, getSlidePanelVisible, setCurrentPresentation, setCurrentSlide
  , setSlidePanelVisible, getCurrentPresentationId, getCurrentSlideId, setPresentationPanelVisible
} from '../../store/presentationSelectionSlice'
import { getPresentations, getSinglePresentation } from '../../store/presentationsSlice'
import { getSingleMaster, getMasters } from '../../store/mastersSlice'
import { getSlides, setSlideLayout } from '../../store/slidesSlice'
import Fade from '@mui/material/Fade'
import { ArrowUpward } from '@mui/icons-material'
import IconButton from '@mui/material/IconButton'
import {
  createSlide, deleteSlide, updatePresentation, deleteMaster, createPresentation, deletePresentation, downloadSlideFile,
  downloadPresentationFile, addMasterFromFileAsync, loadMasters, patchMaster, restoreSlide, restorePresentation, duplicatePresentation,
  createSlideAiAssist,
  createPresentationAiAssist,
  addMasterFromTemplateAsync
} from '../../lib/slideHandling'
import { getRenderingOptions } from '../../store/renderingOptionsSlice'
import _ from 'lodash'
import { useEffect, useRef, useState } from 'react'
import { cleanMasterAnalysisLoadingStates, getLoadingState } from '../../store/loadingStateSlice'
import { useHistContext } from '../helpers/UseHistory'
import { getClipboard, setClipboard } from '../../store/clipboardSlice'
import { noHyphenUuid } from '../../lib/init'
import EditableText from '../helpers/EditableText'
import { ClickAwayListener, Snackbar, Tooltip } from '@mui/material'
import SlidePanelTutorial from '../userguide/SlidePanelTutorial'
import { useHotkeys, useHotkeysContext } from 'react-hotkeys-hook'
import { globalHotKeyScope } from '../../lib/constants'

const overflowHiddenFlexGrow = { display: 'flex', flexGrow: 1, overflow: 'hidden' }

type PresentationSelectionPanelProps = {
  userIsCommonWriter: boolean
}

function PresentationSelectionPanel({ userIsCommonWriter }: Readonly<PresentationSelectionPanelProps>) {
  const { disableScope, enableScope } = useHotkeysContext()
  const presiPanelKeyboardScope = 'presentation_selection_panel'
  // state access  
  const dispatch = useAppDispatch()
  const { histActions } = useHistContext()
  const clipboard = useAppSelector(getClipboard)
  const presentationPanelVisible = useAppSelector(getPresentationPanelVisible)
  const slidePanelVisible = useAppSelector(getSlidePanelVisible)
  const presentations = useAppSelector(getPresentations)
  const presentationsRef = useRef(presentations)
  presentationsRef.current = presentations
  const slides = useAppSelector(getSlides)
  const currentPresentationId = useAppSelector(getCurrentPresentationId)
  const currentSlideId = useAppSelector(getCurrentSlideId)
  const currentPresentation = useAppSelector((state) => getSinglePresentation(state, { presentationId: currentPresentationId }))
  const currentMaster = useAppSelector((state) => getSingleMaster(state, { masterId: currentPresentation?.masterId }))
  const masters = useAppSelector(getMasters)
  const renderingOptions = useAppSelector(getRenderingOptions)
  const loadingState = useAppSelector(getLoadingState)
  const masterArray = (Object.values(masters) as Array<SingleMaster>)
  const masterIds = masterArray.map(obj => obj.logicalMaster.masterId)

  // local state
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState("")

  // Handle click on the MinPresentation component
  const handlePresiClick = (presentationId: string | undefined) => {
    if (presentationId) {
      histActions.set({
        do: () => {
          dispatch(setSlidePanelVisible())
          dispatch(setCurrentPresentation(presentationId))
          if (presentationId in presentationsRef.current && presentationsRef.current[presentationId].slideIds.length > 0) dispatch(setCurrentSlide(presentationsRef.current[presentationId].slideIds[0]))
        },
        undo: () => {
          dispatch(setPresentationPanelVisible())
          dispatch(setCurrentPresentation(currentPresentationId))
          dispatch(setCurrentSlide(currentSlideId))
        },
        stepTitle: 'Select presentation'
      })
      window.gtag("event", "select_content", { content_type: "select_presentation" })
    }
  }

  // Handle click on the Slide Add button
  const handleAddSlideClick = async () => {
    const newSlideId = await createSlide(dispatch, currentPresentationId, slides, renderingOptions)
    // set the new slide as active
    histActions.set({
      do: () => dispatch(setCurrentSlide(newSlideId)),
      undo: () => dispatch(setCurrentSlide(currentSlideId)),
      stepTitle: 'Select slide'
    })
    window.gtag("event", "select_content", { content_type: "create_slide" })
  }

  // Handle click on the slide Delete button
  const handleDeleteSlideClick = () => {
    if (currentPresentation) {
      histActions.set({
        do: () => deleteSlide(dispatch, currentPresentation, currentSlideId, slides, renderingOptions),
        undo: () => {
          restoreSlide(dispatch, currentPresentation, currentSlideId, slides, renderingOptions, currentPresentation.slideIds.findIndex((element) => element === currentSlideId))
          dispatch(setCurrentSlide(currentSlideId))
        },
        stepTitle: 'Delete slide'
      })
    }
  }

  // Handle click on the MiniSlide 
  const handleSlideClick = (slideId: string | undefined) => {
    if (slideId) {
      histActions.set({
        do: () => dispatch(setCurrentSlide(slideId)),
        undo: () => dispatch(setCurrentSlide(currentSlideId)),
        stepTitle: 'Select slide'
      })
      window.gtag("event", "select_content", { content_type: "select_slide" })
    }
  }

  // Handle click on the layout
  const handleLayoutClick = (layoutId: string | undefined) => {
    const currentMasterId = currentMaster?.logicalMaster.masterId
    if (layoutId !== undefined && currentSlideId && currentMasterId) {
      histActions.set({
        do: () => dispatch(setSlideLayout({ masterId: currentMasterId, layoutId: Number(layoutId), slideId: currentSlideId })),
        undo: () => dispatch(setSlideLayout({ masterId: currentMasterId, layoutId: slides[currentSlideId].logicalSlide.layoutIdByMaster[currentMasterId], slideId: currentSlideId })),
        stepTitle: 'Change slide layout'
      })
    }
  }

  // Handle click on master
  const handleMasterClick = (masterId: string | undefined) => {
    if (masterId && masterId !== currentPresentation?.masterId && currentPresentation) {
      const newPresentation = { ...currentPresentation, masterId: masterId }
      histActions.set({
        do: () => updatePresentation(dispatch, newPresentation, true, slides, renderingOptions),
        undo: () => updatePresentation(dispatch, currentPresentation, true, slides, renderingOptions),
        stepTitle: 'Change slide theme'
      })
    }
  }

  // handle new master file
  const handleNewMasterFile = (file: File) => {
    addMasterFromFileAsync(dispatch, file)
    window.gtag("event", "select_content", { content_type: "create_master" })
    setSnackbarMessage("Analyzing your file & creating your theme. This might take a moment...")
    setSnackbarOpen(true)
  }

  // handle new master from template
  const handleNewMasterFromTemplate: NewMasterFromTemplateFunction = (masterName, masterTemplateType, primaryColorHex) => {
    addMasterFromTemplateAsync(dispatch, masterName, masterTemplateType, primaryColorHex)
    window.gtag("event", "select_content", { content_type: "create_master_from_template" })
    setSnackbarMessage("Creating your theme. This might take a moment...")
    setSnackbarOpen(true)
  }

  // handle master deletion
  const handleDeleteMaster = (masterId: string | undefined) => {
    histActions.set({
      do: () => deleteMaster(dispatch, masterId),
      undo: () => patchMaster(dispatch, masterId, { deleted: false }),
      stepTitle: 'Delete theme'
    })
  }

  // handle master default layout change
  const handleDefaultLayoutChange = (masterId: string, defaultLayout: number) => {
    histActions.set({
      do: () => patchMaster(dispatch, masterId, { defaultLayout: defaultLayout }),
      undo: () => patchMaster(dispatch, masterId, { defaultLayout: masters[masterId].logicalMaster.defaultLayout }),
      stepTitle: 'Change theme default background'
    })
  }

  // handle master main col change
  const handleMasterMainColChange = (masterId: string, primXmlKey: string | null, neuXmlKey: string | null) => {
    const currentMaster = masters[masterId].logicalMaster
    histActions.set({
      do: () => patchMaster(dispatch, masterId, { primXmlKey: primXmlKey, neuXmlKey: neuXmlKey }),
      undo: () => patchMaster(dispatch, masterId, { primXmlKey: currentMaster.primXmlKey, neuXmlKey: currentMaster.neuXmlKey }),
      stepTitle: 'Change main theme colors'
    })
  }

  // handle presentation deletion
  const handleDeletePresentationClick = () => {
    histActions.set({
      do: () => {
        deletePresentation(dispatch, currentPresentationId)
        dispatch(setPresentationPanelVisible())
      },
      undo: () => {
        restorePresentation(dispatch, currentPresentationId, slides, renderingOptions)
        dispatch(setSlidePanelVisible())
        dispatch(setCurrentPresentation(currentPresentationId))
      },
      stepTitle: 'Delete presentation'
    })
  }

  // Handle click on back button
  const handleBackButton = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    histActions.set({
      do: () => { dispatch(setPresentationPanelVisible()); dispatch(setCurrentSlide(undefined)) },
      undo: () => { dispatch(setSlidePanelVisible()); dispatch(setCurrentSlide(currentSlideId)) },
      stepTitle: 'Go to presentation list'
    })
  }

  // handle new presentation
  const handlePresentationCreate = async (title: string, masterId: string) => {
    createPresentation(dispatch, title, masterId, slides, renderingOptions)
    window.gtag("event", "select_content", { content_type: "create_presentation" })
  }

  // handle duplicate presentation
  const handlePresentationDuplicate = () => {
    if (currentPresentation) {
      duplicatePresentation(dispatch, currentPresentation, slides, renderingOptions, histActions, currentSlideId)
    }
  }

  // handle presentation update
  const handlePresentationChange: PresentationChangeFunction = (newPresentation, rerenderAllSlides) => {
    if (!_.isEqual(currentPresentation, newPresentation) && currentPresentation) {
      histActions.set({
        do: () => updatePresentation(dispatch, newPresentation, rerenderAllSlides, slides, renderingOptions),
        undo: () => updatePresentation(dispatch, currentPresentation, rerenderAllSlides, slides, renderingOptions),
        stepTitle: 'Update presentation'
      })
    }
  }

  // handle presentation title change
  const handlePresentationTitleChange = (newText: string) => {
    if (!_.isEqual(currentPresentation?.title, newText) && currentPresentation) {
      histActions.set({
        do: () => updatePresentation(dispatch, { ...currentPresentation, title: newText }, false, slides, renderingOptions),
        undo: () => updatePresentation(dispatch, currentPresentation, false, slides, renderingOptions),
        stepTitle: 'Change presentation title'
      })
    }
  }

  // handle presentation common publish
  const setPresentationPublishedFromCommon: (published: boolean) => void = (published) => {
    if (currentPresentation?.publishedFromCommon !== published && currentPresentation) {
      histActions.set({
        do: () => updatePresentation(dispatch, { ...currentPresentation, publishedFromCommon: published }, false, null, renderingOptions),
        undo: () => updatePresentation(dispatch, { ...currentPresentation }, false, null, renderingOptions),
        stepTitle: published ? 'Publish from common' : 'Unpublish from common'
      })

    }
  }

  const handleSlideCopy: IdClickFunction = (logicalSlideid) => {
    if (logicalSlideid) {
      histActions.set({
        do: () => dispatch(setClipboard({ logicalSlide: slides[logicalSlideid].logicalSlide })),
        undo: () => dispatch(setClipboard({ ...clipboard })),
        stepTitle: 'Copy slide'
      })
    }
  }

  const handleSlidePaste = async () => {
    if (clipboard.logicalSlide && currentSlideId && currentPresentation && currentPresentationId) {
      const position = currentPresentation?.slideIds.indexOf(currentSlideId) + 1
      const newSlideId = await createSlide(dispatch, currentPresentationId, slides, renderingOptions, { ...clipboard.logicalSlide, presentationId: currentPresentationId, slideId: noHyphenUuid() }, position)
      // set the new slide as active
      histActions.set({
        do: () => dispatch(setCurrentSlide(newSlideId)),
        undo: () => dispatch(setCurrentSlide(currentSlideId)),
        stepTitle: 'Select slide'
      })
    }
  }

  const handleNextSlide = async () => {
    if (currentSlideId && currentPresentation) {
      const thisPosition = currentPresentation?.slideIds.indexOf(currentSlideId)
      if (thisPosition >= 0) {
        const nextPosition = currentPresentation?.slideIds.length > thisPosition + 1 ? thisPosition + 1 : 0
        // set new slide
        histActions.set({
          do: () => dispatch(setCurrentSlide(currentPresentation?.slideIds[nextPosition])),
          undo: () => dispatch(setCurrentSlide(currentPresentation?.slideIds[thisPosition])),
          stepTitle: 'Select slide'
        })
      }
    }
  }

  const handlePrevSlide = async () => {
    if (currentSlideId && currentPresentation) {
      const thisPosition = currentPresentation?.slideIds.indexOf(currentSlideId)
      if (thisPosition >= 0) {
        const prevPosition = thisPosition - 1 >= 0 ? thisPosition - 1 : currentPresentation?.slideIds.length - 1
        // set new slide
        histActions.set({
          do: () => dispatch(setCurrentSlide(currentPresentation?.slideIds[prevPosition])),
          undo: () => dispatch(setCurrentSlide(currentPresentation?.slideIds[thisPosition])),
          stepTitle: 'Select slide'
        })
      }
    }
  }

  // key press
  useHotkeys('mod+c', () => handleSlideCopy(currentSlideId), { scopes: presiPanelKeyboardScope }, [currentSlideId, dispatch, clipboard, slides, histActions])
  useHotkeys('mod+x', () => { handleSlideCopy(currentSlideId); handleDeleteSlideClick() }, { scopes: presiPanelKeyboardScope }, [currentSlideId, dispatch, clipboard, slides, histActions, currentPresentation, renderingOptions])
  useHotkeys('mod+v', () => handleSlidePaste(), { scopes: globalHotKeyScope }, [dispatch, slides, clipboard, currentSlideId, currentPresentation, currentPresentationId, renderingOptions, histActions])
  useHotkeys('right, down', () => handleNextSlide(), { scopes: globalHotKeyScope }, [currentSlideId, currentPresentation, dispatch, histActions])
  useHotkeys('left, up', () => handlePrevSlide(), { scopes: globalHotKeyScope }, [currentSlideId, currentPresentation, dispatch, histActions])

  const handleSlideDuplicate = async () => {
    if (currentSlideId && currentPresentation && currentPresentationId) {
      const position = currentPresentation?.slideIds.indexOf(currentSlideId) + 1
      const newSlideId = await createSlide(dispatch, currentPresentationId, slides, renderingOptions, { ...slides[currentSlideId].logicalSlide, presentationId: currentPresentationId, slideId: noHyphenUuid() }, position)
      // set the new slide as active
      histActions.set({
        do: () => dispatch(setCurrentSlide(newSlideId)),
        undo: () => dispatch(setCurrentSlide(currentSlideId)),
        stepTitle: 'Select slide'
      })
    }
  }

  // Handle slide download 
  const handleSlideDownload = async (slideId: string | undefined, presentationId: string | undefined) => {
    if (slideId && presentationId) {
      downloadSlideFile(presentationId, slideId)
    }
  }

  // Handle presentation download 
  const handlePresentationDownload = async (presentationId: string | undefined) => {
    if (presentationId) {
      downloadPresentationFile(presentationId)
    }
  }

  // handle create slide ai assist
  const handleCreateSlidePromptProcessing = async (userPrompt: string) => {
    if (currentPresentationId) {
      const newLogiSlide = await createSlideAiAssist(dispatch, currentPresentationId, userPrompt, slides, renderingOptions, undefined)
      window.gtag("event", "select_content", { content_type: "create_slide_ai_assist" })
      histActions.set({
        do: () => dispatch(setCurrentSlide(newLogiSlide?.slideId)),
        undo: () => dispatch(setCurrentSlide(currentSlideId)),
        stepTitle: 'Select slide'
      })
    }
    return
  }

  // handle create presentation ai assist
  const handlePresentationCreateAiAssist = async (title: string, masterId: string, userPrompt: string) => {
    const logiSlideList = await createPresentationAiAssist(dispatch, title, masterId, userPrompt, slides, renderingOptions)
    window.gtag("event", "select_content", { content_type: "create_presentation_ai_assist" })
    if (logiSlideList && logiSlideList.length > 0) handlePresiClick(logiSlideList[0]?.presentationId)
    return
  }

  // keep current ref of masters in analysis
  const loadingStateRef = useRef(loadingState)
  const masterIdsRef = useRef(masterIds)
  useEffect(() => {
    loadingStateRef.current = loadingState
    masterIdsRef.current = masterIds
  }, [loadingState, masterIds])

  // handle masters in analysis every 10 seconds
  useEffect(() => {
    const handleMastersInAnalysis = async (dispatch: any) => {
      if (loadingStateRef.current.MASTERANALYSIS.length > 0) {
        await loadMasters(dispatch)
        dispatch(cleanMasterAnalysisLoadingStates({ masterIdsToRemove: masterIdsRef.current }))
      }
    }

    const intervalId = setInterval(() => { handleMastersInAnalysis(dispatch) }, 10000)
    return () => {
      clearInterval(intervalId)
    }
  }, [])// eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ClickAwayListener onClickAway={() => { disableScope(presiPanelKeyboardScope) }}>
      <Box sx={{ width: 1, display: 'grid', overflow: 'hidden', gridTemplateColumns: '1fr' }} className={'presi_panel'} onClick={() => { enableScope(presiPanelKeyboardScope) }}>

        <Fade in={slidePanelVisible} style={{ transitionDelay: slidePanelVisible ? '200ms' : '0ms', gridColumnStart: 1, gridRowStart: 1 }}>
          <Box sx={{ ...overflowHiddenFlexGrow, flexDirection: 'column' }} >
            <Box sx={{ width: 1, display: "flex", justifyContent: "flex-start", alignItems: "center", flexWrap: "no-wrap", height: "50px", flexShrink: 0 }}>
              <Tooltip title={"Back to presentations"}>
                <IconButton onClick={handleBackButton} size="medium" className='back_to_presi_panel_button'>
                  <ArrowUpward />
                </IconButton>
              </Tooltip>
              <Tooltip title={(currentPresentation) ? currentPresentation.title + " (click to change title)" : "(click to change title)"}>
                <Box>
                  <EditableText variant="subtitle2" onBlur={handlePresentationTitleChange} text={(currentPresentation) ? currentPresentation.title : ""}
                    sx={{ display: "-webkit-box", width: 1, overflow: "hidden", WebkitLineClamp: 1, WebkitBoxOrient: "vertical" }} inputFontSize='0.9em' />
                </Box>
              </Tooltip>
            </Box>
            <Box sx={{ width: 1, height: "40px", flexShrink: 0 }}>
              <PresentationActions handleAddSlideClick={handleAddSlideClick} handleDeletePresentationClick={handleDeletePresentationClick}
                currentMaster={currentMaster} masters={masters} handleMasterClick={handleMasterClick} handlePresentationDownload={handlePresentationDownload}
                currentPresentation={currentPresentation} handlePresentationChange={handlePresentationChange} loadingState={loadingState}
                setPresentationPublishedFromCommon={userIsCommonWriter ? setPresentationPublishedFromCommon : undefined} handlePresentationDuplicate={handlePresentationDuplicate}
                handleCreateSlidePromptProcessing={handleCreateSlidePromptProcessing} />
            </Box>
            <Box sx={{ ...overflowHiddenFlexGrow }} >
              <SlideList handleSlideClick={handleSlideClick} slides={slides} currentSlideId={currentSlideId} currentPresentation={currentPresentation}
                handleDeleteSlideClick={handleDeleteSlideClick} currentMaster={currentMaster} handleLayoutClick={handleLayoutClick}
                handleSlideDownload={handleSlideDownload} handlePresentationChange={handlePresentationChange} handleSlideCopy={handleSlideCopy}
                handleSlidePaste={clipboard.logicalSlide ? handleSlidePaste : undefined} handleSlideDuplicate={handleSlideDuplicate} />
            </Box>
            {slidePanelVisible && <SlidePanelTutorial />}
          </Box>
        </Fade>

        <Fade in={presentationPanelVisible} style={{ transitionDelay: presentationPanelVisible ? '200ms' : '0ms', gridColumnStart: 1, gridRowStart: 1 }}>
          <Box sx={{ ...overflowHiddenFlexGrow, flexDirection: 'column', alignItems: 'flex-start' }}>
            {/* <Box sx={{ width: 1, height: "50px", display: "flex", justifyContent: "center", alignItems: "center", justifyItems: "center", flexWrap: "no-wrap", flexShrink: 0 }}>
            <Typography variant="subtitle1">Presentations</Typography>
          </Box> */}
            <Box sx={{ width: 1, height: "40px", flexShrink: 0, mt: 1, pl: 1, pr: 1 }}>
              <GlobalActions handleDeleteMaster={handleDeleteMaster} handleNewMasterFile={handleNewMasterFile} masters={masters} handleMasterMainColChange={handleMasterMainColChange}
                createPresentation={handlePresentationCreate} createPresentationAiAssist={handlePresentationCreateAiAssist} loadingState={loadingState} handleDefaultLayoutChange={handleDefaultLayoutChange}
                handleNewMasterFromTemplate={handleNewMasterFromTemplate} />
            </Box>
            <Box sx={{ ...overflowHiddenFlexGrow }}>
              <PresentationList handlePresiClick={handlePresiClick} presentations={presentations} slides={slides} currentPresentationId={currentPresentationId} />
            </Box>
          </Box>
        </Fade>

        <Snackbar open={snackbarOpen} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          message={snackbarMessage} autoHideDuration={10000} onClose={() => setSnackbarOpen(false)} />

      </Box>
    </ClickAwayListener>
  )
}

export default PresentationSelectionPanel