import { framePaddingNarrow } from "../../../lib/constants"

export const frame1Presets: Record<string, ElementPreset> = {
    FRAME:
    {
        displayName: 'Frame',
        framePadding: framePaddingNarrow,
        type: 'FRAME1',
        "box": {
            "height": 0.50,
            "left": 0.080,
            "top": 0.28,
            "width": 0.34
        },
        "content": {
        }

    }
}