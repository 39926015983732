import React, { useState, useEffect } from "react"
import Typography from "@mui/material/Typography"

interface ProcessingMessagesProps {
    messages: string[] // List of messages to display
    interval?: number // Time interval in milliseconds, defaults to 3000ms
    typographyVariant?: "body1" | "body2" | "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "subtitle1" | "subtitle2"
    typographyColor?: "primary" | "secondary" | "error" | "info" | "success" | "warning" | "textPrimary" | "textSecondary" | "inherit"
}

const ProcessingMessages: React.FC<ProcessingMessagesProps> = ({
    messages,
    interval = 3000,
    typographyVariant = "body1",
    typographyColor = "textPrimary",
}) => {
    const [currentMessageIndex, setCurrentMessageIndex] = useState(0)

    useEffect(() => {
        if (currentMessageIndex >= messages.length - 1) return // Stop if the last message is reached

        const timer = setInterval(() => {
            setCurrentMessageIndex((prevIndex) => prevIndex + 1)
        }, interval)

        return () => clearInterval(timer) // Clean up on component unmount
    }, [currentMessageIndex, messages.length, interval])

    return (
        <Typography variant={typographyVariant} color={typographyColor} sx={{ width: 1 }} align="inherit">
            {messages[currentMessageIndex]}
        </Typography>
    )
}

export default ProcessingMessages