import { v4 as uuidv4 } from 'uuid'
import { paragraphFromText } from './text'


export const logicalSlideChangeTypeDelays: LogicalSlideChangeTypeDelays = {
  "ELEMENT_DRAG": 50,
  "ELEMENT_RESIZE": 50,
  "ELEMENT_CONTENT_TYPING": 500,
  "NEW_ELEMENT": 0,
  "REMOVED_ELEMENT": 0,
  "APPEARANCE_CHANGE": 0,
  "IMMEDIATE_ELEMENT_CONTENT_TYPING": 0,
  "AUTO_ALIGN": 0,
  "USER_FONT_SIZE_CHANGE": 50,
  "USER_PROMPT_CHANGE": 0,
}

export const initLogicalSlide = (presentationId: string): LogicalSlideInit => {
  return {
    presentationId: presentationId
  }
}

export const titleInitRichText = paragraphFromText('Title...')

export const initTitleElement = (slideId: string): LogicalElement => {
  return {
    slideId: slideId,
    childElements: {},
    box: { left: 0, top: 0, width: 0, height: 0 },
    elementType: 'TITLE',
    elementId: noHyphenUuid(),
    title: { richTextBox: { richText: titleInitRichText } }
  }
}

export const initSinglePresentation = (title: string, masterId: string): SinglePresentationInit => {
  return {
    title: title,
    masterId: masterId,
    basicStyle: basicStyleOptions[defaultBasicStyleIndex]
  }
}

export const initRenderDetail = (): SlideRenderDetail => {
  return {
    slideImgB64: undefined, version: undefined, aspectRatio: undefined, renderFunction: undefined, renderFormat: undefined, presignedUrl: undefined
  }
}

export const ItemTypes = {
  ELEMENT: 'element',
}

export const initPresentationSlideSelection = { slidePanelVisible: false, presentationPanelVisible: true, currentPresentationId: undefined, currentSlideId: undefined }

export const noHyphenUuid = (): string => { return uuidv4().replaceAll("-", "") }


export const defaultBasicStyleIndex = 1
export const basicStyleOptions: BasicStyle[] = [
  {
    scheme: 1,
    displayName: "Free",
    title: { scheme: 1, opacity: 1, lineWidth: 0, shapeType: "SHARP", lineStyle: "NONE", fillStyle: "NONE", bold: true, italic: false, smallCaps: true, underline: false, vertTextAnchor: "MIDDLE", horiTextAlign: "LEFT", fontSizeFactor: 1.2, fontColorMode: 'SOFT', shadowStyle: 'NONE', iconShape: 'CIRCLE' },
    header1: { scheme: 1, opacity: 1, lineWidth: 0, shapeType: "SHARP", lineStyle: "NONE", fillStyle: "NONE", bold: true, italic: false, smallCaps: false, underline: false, vertTextAnchor: "MIDDLE", horiTextAlign: "CENTER", fontSizeFactor: 1.4, fontColorMode: 'NORMAL', shadowStyle: 'NONE', iconShape: 'CIRCLE' },
    header2: { scheme: 1, opacity: 1, lineWidth: 0, shapeType: "SHARP", lineStyle: "NONE", fillStyle: "NONE", bold: true, italic: false, smallCaps: false, underline: false, vertTextAnchor: "MIDDLE", horiTextAlign: "CENTER", fontSizeFactor: 1.4, fontColorMode: 'NORMAL', shadowStyle: 'NONE', iconShape: 'CIRCLE' },
    body1: { scheme: 1, opacity: 1, lineWidth: 0, shapeType: "SHARP", lineStyle: "NONE", fillStyle: "NONE", bold: false, italic: false, smallCaps: false, underline: false, vertTextAnchor: "MIDDLE", horiTextAlign: "CENTER", fontSizeFactor: 1., fontColorMode: 'NORMAL', shadowStyle: 'NONE', iconShape: 'CIRCLE' },
    frame: { scheme: 1, opacity: 0, lineWidth: 0, shapeType: "ROUNDED", lineStyle: "NONE", fillStyle: "BGBLUR2AUTO", bold: false, italic: false, smallCaps: false, underline: false, vertTextAnchor: "MIDDLE", horiTextAlign: "CENTER", fontSizeFactor: 1., fontColorMode: 'NORMAL', shadowStyle: 'NONE', iconShape: 'CIRCLE' }
  },
  {
    scheme: 1,
    displayName: "Rounded (half)",
    title: { scheme: 1, opacity: 1, lineWidth: 0, shapeType: "ROUNDED", lineStyle: "NONE", fillStyle: "NONE", bold: true, italic: false, smallCaps: true, underline: false, vertTextAnchor: "BOTTOM", horiTextAlign: "LEFT", fontSizeFactor: 1.2, fontColorMode: 'SOFT', shadowStyle: 'NONE', iconShape: 'ROUNDED' },
    header1: { scheme: 1, opacity: 1, lineWidth: 0, shapeType: "ROUNDED", lineStyle: "NONE", fillStyle: "SOLID", bold: true, italic: false, smallCaps: false, underline: false, vertTextAnchor: "MIDDLE", horiTextAlign: "CENTER", fontSizeFactor: 1.2, fontColorMode: 'NORMAL', shadowStyle: 'NONE', iconShape: 'ROUNDED' },
    header2: { scheme: 1, opacity: 1, lineWidth: 0, shapeType: "ROUNDED", lineStyle: "NONE", fillStyle: "SOLID", bold: true, italic: false, smallCaps: false, underline: false, vertTextAnchor: "MIDDLE", horiTextAlign: "CENTER", fontSizeFactor: 1.2, fontColorMode: 'NORMAL', shadowStyle: 'NONE', iconShape: 'ROUNDED' },
    body1: { scheme: 1, opacity: 1, lineWidth: 0, shapeType: "ROUNDED", lineStyle: "NONE", fillStyle: "NONE", bold: false, italic: false, smallCaps: false, underline: false, vertTextAnchor: "MIDDLE", horiTextAlign: "CENTER", fontSizeFactor: 1., fontColorMode: 'NORMAL', shadowStyle: 'NONE', iconShape: 'ROUNDED' },
    frame: { scheme: 1, opacity: 0, lineWidth: 0, shapeType: "ROUNDED", lineStyle: "NONE", fillStyle: "BGBLUR2AUTO", bold: false, italic: false, smallCaps: false, underline: false, vertTextAnchor: "MIDDLE", horiTextAlign: "CENTER", fontSizeFactor: 1., fontColorMode: 'NORMAL', shadowStyle: 'NONE', iconShape: 'ROUNDED' }
  },
  {
    scheme: 1,
    displayName: "Rounded (full)",
    title: { scheme: 1, opacity: 1, lineWidth: 0, shapeType: "ROUNDED", lineStyle: "NONE", fillStyle: "NONE", bold: true, italic: false, smallCaps: true, underline: false, vertTextAnchor: "BOTTOM", horiTextAlign: "LEFT", fontSizeFactor: 1.2, fontColorMode: 'SOFT', shadowStyle: 'NONE', iconShape: 'ROUNDED' },
    header1: { scheme: 1, opacity: 1, lineWidth: 0, shapeType: "ROUNDED", lineStyle: "NONE", fillStyle: "SOLID", bold: true, italic: false, smallCaps: false, underline: false, vertTextAnchor: "MIDDLE", horiTextAlign: "CENTER", fontSizeFactor: 1.2, fontColorMode: 'NORMAL', shadowStyle: 'NONE', iconShape: 'ROUNDED' },
    header2: { scheme: 1, opacity: 1, lineWidth: 0, shapeType: "ROUNDED", lineStyle: "NONE", fillStyle: "SOLID", bold: true, italic: false, smallCaps: false, underline: false, vertTextAnchor: "MIDDLE", horiTextAlign: "CENTER", fontSizeFactor: 1.2, fontColorMode: 'NORMAL', shadowStyle: 'NONE', iconShape: 'ROUNDED' },
    body1: { scheme: 1, opacity: 1, lineWidth: 0, shapeType: "ROUNDED", lineStyle: "NONE", fillStyle: "SOLID", bold: false, italic: false, smallCaps: false, underline: false, vertTextAnchor: "MIDDLE", horiTextAlign: "CENTER", fontSizeFactor: 1., fontColorMode: 'NORMAL', shadowStyle: 'NONE', iconShape: 'ROUNDED' },
    frame: { scheme: 1, opacity: 0, lineWidth: 0, shapeType: "ROUNDED", lineStyle: "NONE", fillStyle: "BGBLUR2AUTO", bold: false, italic: false, smallCaps: false, underline: false, vertTextAnchor: "MIDDLE", horiTextAlign: "CENTER", fontSizeFactor: 1., fontColorMode: 'NORMAL', shadowStyle: 'NONE', iconShape: 'ROUNDED' }
  },
  {
    scheme: 1,
    displayName: "Rounded (line)",
    title: { scheme: 1, opacity: 1, lineWidth: 0.0027777, shapeType: "ROUNDED", lineStyle: "NONE", fillStyle: "NONE", bold: true, italic: false, smallCaps: true, underline: false, vertTextAnchor: "BOTTOM", horiTextAlign: "LEFT", fontSizeFactor: 1.2, fontColorMode: 'SOFT', shadowStyle: 'NONE', iconShape: 'ROUNDED' },
    header1: { scheme: 1, opacity: 1, lineWidth: 0.0027777, shapeType: "ROUNDED", lineStyle: "NONE", fillStyle: "SOLID", bold: true, italic: false, smallCaps: false, underline: false, vertTextAnchor: "MIDDLE", horiTextAlign: "CENTER", fontSizeFactor: 1.2, fontColorMode: 'NORMAL', shadowStyle: 'NONE', iconShape: 'ROUNDED' },
    header2: { scheme: 1, opacity: 1, lineWidth: 0.0027777, shapeType: "ROUNDED", lineStyle: "NONE", fillStyle: "SOLID", bold: true, italic: false, smallCaps: false, underline: false, vertTextAnchor: "MIDDLE", horiTextAlign: "CENTER", fontSizeFactor: 1.2, fontColorMode: 'NORMAL', shadowStyle: 'NONE', iconShape: 'ROUNDED' },
    body1: { scheme: 1, opacity: 1, lineWidth: 0.0027777, shapeType: "ROUNDED", lineStyle: "SOLID", fillStyle: "NONE", bold: false, italic: false, smallCaps: false, underline: false, vertTextAnchor: "MIDDLE", horiTextAlign: "CENTER", fontSizeFactor: 1., fontColorMode: 'NORMAL', shadowStyle: 'NONE', iconShape: 'ROUNDED' },
    frame: { scheme: 1, opacity: 0, lineWidth: 0, shapeType: "ROUNDED", lineStyle: "NONE", fillStyle: "BGBLUR2AUTO", bold: false, italic: false, smallCaps: false, underline: false, vertTextAnchor: "MIDDLE", horiTextAlign: "CENTER", fontSizeFactor: 1., fontColorMode: 'NORMAL', shadowStyle: 'NONE', iconShape: 'ROUNDED' }
  },
  {
    scheme: 1,
    displayName: "Sharp (half)",
    title: { scheme: 1, opacity: 1, lineWidth: 0, shapeType: "SHARP", lineStyle: "NONE", fillStyle: "NONE", bold: true, italic: false, smallCaps: true, underline: false, vertTextAnchor: "BOTTOM", horiTextAlign: "LEFT", fontSizeFactor: 1.2, fontColorMode: 'SOFT', shadowStyle: 'NONE', iconShape: 'CIRCLE' },
    header1: { scheme: 1, opacity: 1, lineWidth: 0, shapeType: "SHARP", lineStyle: "NONE", fillStyle: "SOLID", bold: true, italic: false, smallCaps: false, underline: false, vertTextAnchor: "MIDDLE", horiTextAlign: "CENTER", fontSizeFactor: 1.2, fontColorMode: 'NORMAL', shadowStyle: 'NONE', iconShape: 'CIRCLE' },
    header2: { scheme: 1, opacity: 1, lineWidth: 0, shapeType: "SHARP", lineStyle: "NONE", fillStyle: "SOLID", bold: true, italic: false, smallCaps: false, underline: false, vertTextAnchor: "MIDDLE", horiTextAlign: "CENTER", fontSizeFactor: 1.2, fontColorMode: 'NORMAL', shadowStyle: 'NONE', iconShape: 'CIRCLE' },
    body1: { scheme: 1, opacity: 1, lineWidth: 0, shapeType: "SHARP", lineStyle: "NONE", fillStyle: "NONE", bold: false, italic: false, smallCaps: false, underline: false, vertTextAnchor: "MIDDLE", horiTextAlign: "CENTER", fontSizeFactor: 1., fontColorMode: 'NORMAL', shadowStyle: 'NONE', iconShape: 'CIRCLE' },
    frame: { scheme: 1, opacity: 0, lineWidth: 0, shapeType: "SHARP", lineStyle: "NONE", fillStyle: "BGBLUR2AUTO", bold: false, italic: false, smallCaps: false, underline: false, vertTextAnchor: "MIDDLE", horiTextAlign: "CENTER", fontSizeFactor: 1., fontColorMode: 'NORMAL', shadowStyle: 'NONE', iconShape: 'CIRCLE' }
  },
  {
    scheme: 1,
    displayName: "Sharp (full)",
    title: { scheme: 1, opacity: 1, lineWidth: 0, shapeType: "SHARP", lineStyle: "NONE", fillStyle: "NONE", bold: true, italic: false, smallCaps: true, underline: false, vertTextAnchor: "BOTTOM", horiTextAlign: "LEFT", fontSizeFactor: 1.2, fontColorMode: 'SOFT', shadowStyle: 'NONE', iconShape: 'CIRCLE' },
    header1: { scheme: 1, opacity: 1, lineWidth: 0, shapeType: "SHARP", lineStyle: "NONE", fillStyle: "SOLID", bold: true, italic: false, smallCaps: false, underline: false, vertTextAnchor: "MIDDLE", horiTextAlign: "CENTER", fontSizeFactor: 1.2, fontColorMode: 'NORMAL', shadowStyle: 'NONE', iconShape: 'CIRCLE' },
    header2: { scheme: 1, opacity: 1, lineWidth: 0, shapeType: "SHARP", lineStyle: "NONE", fillStyle: "SOLID", bold: true, italic: false, smallCaps: false, underline: false, vertTextAnchor: "MIDDLE", horiTextAlign: "CENTER", fontSizeFactor: 1.2, fontColorMode: 'NORMAL', shadowStyle: 'NONE', iconShape: 'CIRCLE' },
    body1: { scheme: 1, opacity: 1, lineWidth: 0, shapeType: "SHARP", lineStyle: "NONE", fillStyle: "SOLID", bold: false, italic: false, smallCaps: false, underline: false, vertTextAnchor: "MIDDLE", horiTextAlign: "CENTER", fontSizeFactor: 1., fontColorMode: 'NORMAL', shadowStyle: 'NONE', iconShape: 'CIRCLE' },
    frame: { scheme: 1, opacity: 0, lineWidth: 0, shapeType: "SHARP", lineStyle: "NONE", fillStyle: "BGBLUR2AUTO", bold: false, italic: false, smallCaps: false, underline: false, vertTextAnchor: "MIDDLE", horiTextAlign: "CENTER", fontSizeFactor: 1., fontColorMode: 'NORMAL', shadowStyle: 'NONE', iconShape: 'CIRCLE' }
  },
  {
    scheme: 1,
    displayName: "Sharp (line)",
    title: { scheme: 1, opacity: 1, lineWidth: 0.0027777, shapeType: "SHARP", lineStyle: "NONE", fillStyle: "NONE", bold: true, italic: false, smallCaps: true, underline: false, vertTextAnchor: "BOTTOM", horiTextAlign: "LEFT", fontSizeFactor: 1.2, fontColorMode: 'SOFT', shadowStyle: 'NONE', iconShape: 'CIRCLE' },
    header1: { scheme: 1, opacity: 1, lineWidth: 0.0027777, shapeType: "SHARP", lineStyle: "NONE", fillStyle: "SOLID", bold: true, italic: false, smallCaps: false, underline: false, vertTextAnchor: "MIDDLE", horiTextAlign: "CENTER", fontSizeFactor: 1.2, fontColorMode: 'NORMAL', shadowStyle: 'NONE', iconShape: 'CIRCLE' },
    header2: { scheme: 1, opacity: 1, lineWidth: 0.0027777, shapeType: "SHARP", lineStyle: "NONE", fillStyle: "SOLID", bold: true, italic: false, smallCaps: false, underline: false, vertTextAnchor: "MIDDLE", horiTextAlign: "CENTER", fontSizeFactor: 1.2, fontColorMode: 'NORMAL', shadowStyle: 'NONE', iconShape: 'CIRCLE' },
    body1: { scheme: 1, opacity: 1, lineWidth: 0.0027777, shapeType: "SHARP", lineStyle: "SOLID", fillStyle: "NONE", bold: false, italic: false, smallCaps: false, underline: false, vertTextAnchor: "MIDDLE", horiTextAlign: "CENTER", fontSizeFactor: 1., fontColorMode: 'NORMAL', shadowStyle: 'NONE', iconShape: 'CIRCLE' },
    frame: { scheme: 1, opacity: 0, lineWidth: 0, shapeType: "SHARP", lineStyle: "NONE", fillStyle: "BGBLUR2AUTO", bold: false, italic: false, smallCaps: false, underline: false, vertTextAnchor: "MIDDLE", horiTextAlign: "CENTER", fontSizeFactor: 1., fontColorMode: 'NORMAL', shadowStyle: 'NONE', iconShape: 'CIRCLE' }
  },
]