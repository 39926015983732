import { useCallback } from 'react'
import { useDropzone, Accept } from 'react-dropzone'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'
import UploadIcon from '@mui/icons-material/CloudUpload'

type DropzoneProps = { accept: Accept, handleFile: (file: File) => void, text: string }

function FileDropzone({ accept, handleFile, text }: Readonly<DropzoneProps>) {

  const theme = useTheme()
  const dropZoneStyle = {
    flex: 1,
    width: 1,
    height: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    textAlign: 'center',
    padding: '15px',
    borderWidth: 2,
    borderColor: theme.palette.text.disabled,
    borderStyle: 'dashed',
    cursor: 'pointer',
    opacity: '0.8',
    transition: 'all 0.3s ease-in-out', // Smooth transition for the hover effect
    '&:hover': {
      borderColor: theme.palette.primary.main, // Change border color on hover
      backgroundColor: theme.palette.action.hover, // Add a background color on hover
      opacity: 1, // Increase opacity on hover
    }
  }

  const onDrop = useCallback((acceptedFiles: File[]) => {
    acceptedFiles.forEach((file: File) => {
      handleFile(file)
    })

  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: accept, maxFiles: 1 })

  return (
    <Paper sx={dropZoneStyle} elevation={0}  {...getRootProps()}>
      <input {...getInputProps()} />
      <Typography variant='h6' color='text.disabled' sx={{ width: 1, display: 'flex', justifyContent: 'center' }}>
        {text}
      </Typography>
      <Box sx={{ width: 1, justifyContent: 'center', display: 'flex' }}>
        <UploadIcon fontSize="large" sx={{ color: theme.palette.text.disabled }} />
      </Box >
    </Paper>
  )
}

export default FileDropzone